import { Content, Tile, Button, Grid, Column, ClickableTile, } from 'carbon-components-react';
import { IbmWatsonxOrchestrate, ArrowRight, Analytics, IbmCloudBareMetalServer, HardwareSecurityModule, LoadBalancerVpc, Db2DataSharingGroup, IbmCloudVpc, IbmOpenEnterpriseLanguages, Template, IbmCloudAppId, CustomerService, UserCertification, AugmentedReality, WatsonHealthImageAvailabilityLocal, IbmEventAutomation, VlanIbm, DataVis_1 } from '@carbon/icons-react';
import { Image } from '../../Image';
import "./style.scss"
import { useNavigate, Link } from 'react-router-dom';

const courseCard = [
    {
        "title1": "Omnichannel",
        "title2": "Reimagine channels and bridging the gap across these.",
        "icon": <IbmWatsonxOrchestrate size={'50'} className="my-custom-class" aria-label="Add" />
    },
    {
        "title1": "Customer Experience",
        "title2": "Deliver elevated experiences online and in-store.",
        "icon": <CustomerService size={'48'} className="my-custom-class" role='img' focusable="false" strokeWidth="5" />
    },
    {
        "title1": "Personalization",
        "title2": "Drive customer value using personaliztion.",
        "icon": <UserCertification size={'50'} className="my-custom-class" />
    },
    {
        "title1": "AR/VR",
        "title2": "Offer unique shopping experiences using AR/VR",
        "icon": <AugmentedReality size={'50'} className="my-custom-class" />
    },
    {
        "title1": "Hyperlocal",
        "title2": "Localize retail offerings to cater to the specific preferences.",
        "icon": <WatsonHealthImageAvailabilityLocal size={'50'} className="my-custom-class" />
    },
    {
        "title1": "Automation",
        "title2": "Automate checkout, inventory management, and customer service",
        "icon": <IbmEventAutomation size={'50'} className="my-custom-class" />
    },
    {
        "title1": "AI",
        "title2": "Leverge AI to keep up with changing consumer behaviors, market trends and competitive dynamics.",
        "icon": <DataVis_1 size={'50'} className="my-custom-class" />
    },
    {
        "title1": "Analytics",
        "title2": "Unleash advanced analytics on rich data to turn customer insights into retail excellence.",
        "icon": <Analytics size={'50'} className="my-custom-class" />
    }
]

const researchData = [
    {
        "title": "Crafting Unique Shopping Journeys",
        "description": "The Power of Retail Personalization",
        "reDirect": "Read the blog",
        "linkHref": "/blog/crafting-unique-shopping-journeys-the-power-of-retail-personalization",
        blogImage: require("../../asset/blog_3.jpeg")
    },
    {
        "title": "Navigating the New Retail Landscape",
        "description": "The Rise of Omnichannel Mastery",
        "reDirect": "Read the blog",
        "linkHref": "/blog/navigating-the-new-retail-landscape-the-rise-of-mmnichannel-mastery",
        blogImage: require("../../asset/blog_4.jpeg")
    },
    {
        "title": "Navigating Retail's New Reality",
        "description": "Reinventing Customer Engagement Post-Pandemic",
        "reDirect": "Read the blog",
        "linkHref": "/blog/navigating-retails-new-reality-reinventing-customer-engagement-post-pandemic",
        blogImage: require("../../asset/blog_5.jpeg")
    },
    {
        "title": "Revolutionizing Retail with Data Analytics",
        "description": "A New Era of Customer Insight and Excellence",
        "reDirect": "Read the blog",
        "linkHref": "/blog/revolutionizing-retail-with-data-analytics-a-new-era-of-customer-insight-and-excellence",
        blogImage: require("../../asset/blog_6.jpeg")
    }
]

const courseName = [
    {
        "title": "IBM Security QRadar Suite",
        "description": "Modernized threat detection and response solution"
    },
    {
        "title": "AWS Monitoring",
        "description": "Get enhanced AWS visibility with Instana"
    },
    {
        "title": "Buy now",
        "description": "Save 50% on subscription to Cognos Analytics"
    },
    {
        "title": "Buy now",
        "description": "Save 10% on SPSS Statistics subscription"
    },
]
const Home = () => {
    const navigate = useNavigate()
    const handleArrowRightClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
      };
    

    return (
        <div className='main_page'>
            <Grid className='home_page_grid'>
                <Column lg={14} md={8} sm={4} >
                    <Grid>
                        <Column lg={6} md={8} sm={4} >
                            <div className='main_banner_con'>
                                <h1>Cutting edge transformative tech for organized retail</h1>
                                <div className='main_banner_con_tour'>
                                    <p style={{ fontSize: "20px" }}>Advanced technology products to innovate and enhance the organized retail sector.</p>
                                    <Button onClick={() => { navigate("/emporia"); handleArrowRightClick(); }} renderIcon={ArrowRight}>Proximety Emporia</Button>
                                </div>
                            </div>
                        </Column>
                        <Column lg={8} md={8} sm={4} >
                            <div className='main_page_img'>
                                <img src={Image?.home_banner} alt="" />
                            </div>
                        </Column>
                    </Grid>
                </Column>
                <Column lg={2} md={8} sm={4} style={{ marginTop: "40px" }}>
                    <div className='right_compont'>
                        <p className='right_heder'>Read the blog</p>
                        <p style={{ color: "#0f62fe", fontWeight: 400, cursor: "pointer" }} onClick={() => { navigate("/blog/revolutionizing-retail-embracing-a-new-era-of-technology-integration") }} >Revolutionizing Retail: Embracing a New Era of Technology Integration</p>
                        <Link style={{ marginTop: "20px", marginBottom: "20px", display: "block" }} renderIcon={() => <Template size={'19'} />} />
                    </div>
                    <div className='right_compont' style={{ marginTop: "50px" }}>
                        <p className='right_heder'>Read the blog</p>
                        <p style={{ color: "#0f62fe", fontWeight: 400, cursor: "pointer" }} size='lg' onClick={() => { navigate("/blog/the-transforming-landscape-of-malls-navigating-retail-trends-in-2024"); }}  > The Transforming Landscape of Malls: Navigating Retail Trends in 2024</p>
                        <Link style={{ marginTop: "20px", marginBottom: "20px", display: "block" }} renderIcon={() => <Template size={'19'} />} />
                    </div>
                </Column>
            </Grid>


            <Grid className='browser_con_main_grid'>
                <Column lg={7} md={8} sm={4} >
                    <p className='browser_title'>Our Solutions</p>
                </Column>
                <Column lg={9} md={8} sm={4} className='browser_con'>
                    <p>From our flagship products for shopping malls to next-generation omni channel, data and cloud solutions,
                        find the answer to your business challenge.</p>

                </Column>
            </Grid>
            <Grid className='browser_card_main'>
                {
                    courseCard?.map((item) => {
                        return (
                            <Column lg={4} md={4} sm={4} style={{ margin: "0px", padding: "0px" }}>
                                <ClickableTile id="clickable-tile-1" className="course_card">
                                    <div style={{ fontWeight: 400 }}>
                                        {item?.icon}
                                    </div>
                                    <div className='browser_card_con' >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", }}>
                                            <p className='title_1'>{item?.title1}</p>
                                            <p className='title_1_mobile'>{item?.title1}</p>
                                            <p className='title_2_mobile'>{item?.title2}</p>
                                        </div>
                                        <p className='title_2'>{item?.title2}</p>
                                    </div>
                                </ClickableTile>
                            </Column>
                        )
                    })
                }
            </Grid>
            <Grid style={{}}>
                <Column lg={8} md={8} sm={4} style={{ margin: "0px", width: "100%" }} className='power_ai_con'>
                    <p>What's new</p>
                    <p className='power_heading'>Proximety Emporia</p>
                    <p className='power_heading_con'>Emporia is a state-of-the-art turnkey platform for shopping malls to go omnichannel. It is a comprehensive solution offering personalized shopping journeys, advanced marketing tools, and insightful analytics – all crafted to elevate the modern shopping mall experience to new heights. Built keeping in mind mall shoppers, tenants and mall management, at the centre of Emporia is a revolutionary mobile app for mall shoppers - 'The Everything App'.</p>
                </Column>
                <Column lg={8} md={8} sm={4} style={{ margin: "0px", padding: "0px", }}>
                    <div className='what_new_img' >
                        <img src={Image?.emporia_image2} alt="Your Alt Text" className="image-cover web-image" />
                        <img src={Image?.emporia_image2_mobile} alt="Your Alt Text" className="image-cover mobile-image" />
                        <div onClick={() => { navigate("/emporia"); handleArrowRightClick(); }} className='what_read_report' style={{ cursor: "pointer" }} >
                            <p >Learn more about Emporia</p>
                            <p style={{ color: "#0f62fe" }} >{<ArrowRight size={'25'} />}</p>
                        </div>
                    </div>
                </Column>
            </Grid>
            <Grid style={{ padding: "60px 10px" }}>
                <Column lg={8} md={8} sm={4}>
                    <p className='meet_our'>Meet our team</p>
                </Column>
                <Column lg={8} md={8} sm={4}>
                    <p className='meet_our_con'>
                        Uniting a variety of perspectives through cutting-edge technology, we collaboratively ideate and swiftly implement breakthrough innovations for impactful results.</p>
                    <p style={{ alignItems: "center", marginTop: "25px", fontSize: "16px", color: "#0f62fe", cursor: "pointer" }} onClick={() => { navigate("/our-team"); handleArrowRightClick(); }}>View our team <ArrowRight style={{ marginLeft: "5px" }} size={'20'} /></p>
                </Column>
            </Grid>
            <Grid >
                <Column lg={16} md={8} sm={4} >
                    <div>
                        <img className="image-cover web-image" src={Image?.staff_img} style={{ width: "100%" }} />
                        <img  className="image-cover mobile-image" src={Image?.staff_img2} style={{ width: "100%" }} />
                    </div>
                </Column>
            </Grid>
            <Grid style={{ padding: "64px 16px" }}>
                <Column lg={8} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
                    <p className='meet_our'>About Proximety</p>
                </Column>
                <Column lg={8} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
                    <p className='meet_our_con'>Pioneering retail evolution through cutting-edge solutions, Proximety's most vital asset is its dedicated team. Discover the prowess of our talented teams shaping the future of organized retail.</p>
                    <p onClick={() => { navigate("/who-we-are"); handleArrowRightClick(); }} style={{ alignItems: "center", marginTop: "25px", fontSize: "16px", color: "#0f62fe", cursor: "pointer" }} >About Proximety <ArrowRight style={{ marginLeft: "5px" }} size={'20'} /></p>
                </Column>
            </Grid>
            <Grid style={{ padding: "36px 16px 96px 16px" }} className='artifical_card'>
                {
                    researchData?.map((item, index) => {
                        return (
                            <Column lg={4} md={8} sm={4} style={{ margin: "0px", padding: "0px", }} className='artifical_card_inside' >
                                <ClickableTile onClick={() => { navigate(item.linkHref); handleArrowRightClick(); }} id="clickable-tile-1" className='home_card'>
                                    <img src={item.blogImage} alt="" />
                                    <div style={{ padding: "10px" }}>
                                        <p style={{ color: "#525252" }}>{item?.title}</p>
                                        <p className='research_des'>{item?.description}</p>
                                        <p style={{ alignItems: "center", marginTop: "50px", fontSize: "16px", color: "#0f62fe", cursor: "pointer" }} >{item?.reDirect} <ArrowRight style={{ marginLeft: "5px" }} size={'20'} /></p>
                                    </div>
                                </ClickableTile>
                            </Column>
                        )
                    })}
            </Grid>
        </div >
    )
}
export default Home