// import { ArrowRight } from '@carbon/icons-react';
// import { Link } from 'carbon-components-react';
// import React, { useState } from 'react'
// import { Image } from '../../Image'

// const MyCarousel = () => {
//     const [activeItem, setActiveItem] = useState(0);

//   const handleSlide = (index) => {
//     setActiveItem(index);
//   };

//     return (
//         <div className='about_carousel'>
//             <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" onSlide={(index) => handleSlide(index)}>
//                 <div class="carousel-inner">
//                     <div class="carousel-item active">
//                         <img src={Image.featured_banner1} class="d-block w-100" alt="..." />
//                         <div className='text_box'>
//                         <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>Revolutionizing Retail: Embracing a New Era of Technology Integration</p>
//                       <Link size='lg' href="/blog/revolutionizing-retail-embracing-a-new-era-of-technology-integration" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
//                         </div>
//                     </div>
//                     <div class="carousel-item">
//                         <img src={Image.featured_banner2} class="d-block w-100" alt="..." />
//                         <div className='text_box'>
//                         <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>The Transforming Landscape of Malls: Navigating Retail Trends in 2024</p>
//                       <Link size='lg' href="/blog/the-transforming-landscape-of-malls-navigating-retail-trends-in-2024" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
//                         </div>
//                     </div>
//                     <div class="carousel-item">
//                         <img src={Image.featured_banner3} class="d-block w-100" alt="..." />
//                         <div className='text_box'>
//                         <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>Navigating the New Retail Landscape: The Rise of Omnichannel Mastery</p>
//                       <Link size='lg' href="/blog/navigating-the-new-retail-landscape-the-rise-of-mmnichannel-mastery" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
//                         </div>
//                     </div>
//                     <div class="carousel-item">
//                         <img src={Image.featured_banner4} class="d-block w-100" alt="..." />
//                         <div className='text_box'>
//                         <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>Revolutionizing Retail with Data Analytics: A New Era of Customer Insight and Excellence</p>
//                       <Link size='lg' href="/blog/revolutionizing-retail-with-data-analytics-a-new-era-of-customer-insight-and-excellence"  style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
//                         </div>
//                     </div>
//                 </div>
//                 <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
//                     <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//                     <span class="visually-hidden">Previous</span>
//                 </button>
//                 <div className='item_number'>{activeItem + 1} / 4</div>
//                 <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
//                     <span class="carousel-control-next-icon" aria-hidden="true"></span>
//                     <span class="visually-hidden">Next</span>
//                 </button>
//             </div>
//         </div>
//     )
// }

// export default MyCarousel


import { ArrowRight } from '@carbon/icons-react';
import { Link } from 'carbon-components-react';
import React, { useState } from 'react';
import { Image } from '../../Image';

const MyCarousel = () => {
  const [activeItem, setActiveItem] = useState(0);

  const handleSlide = (index) => {
    setActiveItem(index);
  };

  const handleNext = () => {
    const nextItem = (activeItem + 1) % 4; // Ensure the count stays within the range
    setActiveItem(nextItem);
  };

  const handlePrev = () => {
    const prevItem = (activeItem - 1 + 4) % 4; // Ensure the count stays within the range
    setActiveItem(prevItem);
  };

  return (
    <div className='about_carousel'>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" onSlide={(index) => handleSlide(index)}>
        <div className="carousel-inner">
          <div className={`carousel-item ${activeItem === 0 ? 'active' : ''}`}>
            <img src={Image.featured_banner1} className="d-block w-100" alt="..." />
            <div className='text_box'>
              <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>Revolutionizing Retail: Embracing a New Era of Technology Integration</p>
              <Link size='lg' href="/blog/revolutionizing-retail-embracing-a-new-era-of-technology-integration" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
            </div>
          </div>
          <div className={`carousel-item ${activeItem === 1 ? 'active' : ''}`}>
            <img src={Image.featured_banner2} className="d-block w-100" alt="..." />
            <div className='text_box'>
              <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>The Transforming Landscape of Malls: Navigating Retail Trends in 2024</p>
              <Link size='lg' href="/blog/the-transforming-landscape-of-malls-navigating-retail-trends-in-2024" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
            </div>
          </div>
          <div className={`carousel-item ${activeItem === 2 ? 'active' : ''}`}>
            <img src={Image.featured_banner3} className="d-block w-100" alt="..." />
            <div className='text_box'>
              <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>Navigating the New Retail Landscape: The Rise of Omnichannel Mastery</p>
              <Link size='lg' href="/blog/navigating-the-new-retail-landscape-the-rise-of-mmnichannel-mastery" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
            </div>
          </div>
          <div className={`carousel-item ${activeItem === 3 ? 'active' : ''}`}>
            <img src={Image.featured_banner4} className="d-block w-100" alt="..." />
            <div className='text_box'>
              <p style={{ fontSize: "22px", marginTop: "10px", height: "150px", textDecoration: "none" }}>Revolutionizing Retail with Data Analytics: A New Era of Customer Insight and Excellence</p>
              <Link size='lg' href="/blog/revolutionizing-retail-with-data-analytics-a-new-era-of-customer-insight-and-excellence" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" onClick={handlePrev}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <div className='item_number'>{activeItem + 1} / 4</div>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" onClick={handleNext}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default MyCarousel;
