import React, { useState } from 'react';
import {
  Content,
  Grid,
  Column,
  Button,
  GridSettings,
  Heading,
  Section
} from 'carbon-components-react';
import { Search, Notification, Switcher as SwitcherIcon, Printer, Wifi, Email, DocumentPdf } from '@carbon/icons-react';
import "./style.scss";
import { Image } from '../../../Image';

const Blog1 = () => {
  const content = {
    section: {
      level: 1,
      heading: "Revolutionizing Retail: Embracing a New Era of Technology Integration",
      date: "Jan 27, 2023",
      bannerImage: Image?.featured_banner1,
    },
    mainGrid: {
      author: "",
      paragraphs1: "In the dynamic world of retail, the integration of technology has become a pivotal aspect of business evolution, especially for traditional brick-and-mortar retailers. The landscape of retail is undergoing a significant transformation, driven by the need for technological solutions that align with business objectives and enhance operational efficiency.",
      paragraphs2: "One of the primary challenges faced by conventional retailers is the siloed structure of IT departments. This segregation often results in a disconnect between IT and other business units, hindering effective collaboration and innovation. Unlike their digitally native counterparts, many traditional retailers still view IT as a subordinate, administrative function, leading to a lack of attractive opportunities for digital talents and a struggle to develop necessary internal technological competencies.",
      paragraphs3: "The technological deficiencies stemming from this siloed approach can pose existential threats to retailers, particularly in an era where digital disruptors like Amazon are continuously innovating. Recognizing this, leading retailers such as Walmart and Tesco have significantly increased their technology investments. However, simply increasing investment is not sufficient. Retailers must also focus on selecting the right digital models and continuously refining them through a test-and-learn approach.",
      paragraphs4: "To build a modern technology organization, retailers can draw inspiration from digital pioneers like Amazon, where joint responsibility for commercial processes and technology development is standard. This can be achieved through several strategic steps:",
      subheadings: [
        "Empower Small, Agile Product Teams: ",
        "Establish Tech Chapters: ",
        "Business-Led Product Ownership: ",
        "Define Clear KPIs: ",
        "Engage Top Management Sponsors: ",
        "Modernize the Tech Stack: "
      ],
      paragraphs5: "Transition from large IT departments to smaller, more agile teams dedicated to specific business processes. These teams should work closely with business counterparts, enhancing effectiveness and driving independent development measured against concrete business results.",
      paragraphs6: "Replace traditional IT structures with 'tech chapters' that focus on the professional development of employees in product teams and the recruitment of new tech specialists. These chapters should concentrate on methodology and technology, fostering a collaborative environment for innovation.",
      paragraphs7: "Assign product ownership roles within business divisions, ensuring that product teams are led with a business mindset and are directly involved in developing technological solutions to improve company performance.",
      paragraphs8: "Implement specific KPIs for each team, aligning their objectives with the company's overall goals and ensuring a shared commitment to business success.",
      paragraphs9: "Involve executive board members as sponsors for each business division, facilitating early resolution of cross-divisional conflicts and emphasizing the importance of technology in strategic decision-making.",
      paragraphs10: "Undertake a comprehensive review and update of the company's tech stack, moving towards cloud platforms, SaaS solutions, and a microservices architecture to enhance scalability and agility.",
      paragraphs11: "Implementing these changes can be a monumental task, requiring a shift in organizational structure, learning new ways of working, and a strong commitment from top management. However, the transition from a traditional IT department to a modern technology organization is crucial for retailers to thrive in the digital age, turning challenges into opportunities for genuine business success."
    }
  };

  const [isSideNavExpanded, setSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <>
      <Content className='get_ready_cases'>
        <Section level={1}>
          <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
            {content.section.heading}
          </Heading>
          <p style={{ marginTop: 18 }}>{content.section.date}</p>
          <img src={Image?.featured_banner1} style={{ height: "100%", width: "100%", marginTop: 20 }} />
        </Section>
        <Grid fullWidth className='main_grid' >
          <Column lg={11} md={8} sm={4} className="first_grid">
            <GridSettings subgrid={false}>
              <Grid>
                <Column lg={1} md={1} sm={4} className="first_grid  first_a_grid">
                  {/* Social media icons and other buttons */}
                  {/* <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fin.newsroom.ibm.com%252Findex.php%253Fs%253D34137%2526item%253D101&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "_blank")}
                  ></div> */}
                  {/* <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com/sharing/share-offsite/?url=https://proximety.io/", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#161616", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com/intent/tweet?url=https%3A%2F%2Fin.newsroom.ibm.com%2Findex.php%3Fs%3D34137%26item%3D1011", "_blank")}></div> */}
                  {/* <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://in.newsroom.ibm.com/getting-ready-for-new-era-of-data-protection?adoper=219449_2_LS2&printable", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => (window.open("https://in.newsroom.ibm.com/index.php?s=20324&rsspage=34137", "_blank"))}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div> */}
                </Column>
                <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                  {/* Content Section */}
                  <span style={{ fontSize: "16px", fontWeight: "600", fontStyle: "italic" }}>{content.mainGrid.author}</span>
                  <p style={{ marginTop: "20px" }}>
                    {content.mainGrid.paragraphs1}
                  </p>
                  {/* ... (rest of the code) */}
                  <p>
                    {content.mainGrid.paragraphs3}
                  </p>
                  <p>
                    {content?.mainGrid?.paragraphs4}
                  </p>
                  <ul>
                    {content.mainGrid.subheadings.map((subheading, index) => (
                      <li key={index}>
                        <p><span style={{ fontWeight: "700" }}>{subheading}</span>{content.mainGrid[`paragraphs${index + 5}`]}</p>
                      </li>
                    ))}
                  </ul>
                  {/* ... (rest of the code) */}
                  {/* <img src={Image?.get_ready_secound} style={{ width: "100%" }} /> */}
                  {/* ... (rest of the code) */}
                </Column>
              </Grid>
            </GridSettings>
          </Column>
          <Column lg={5} md={4} sm={4} className="second_grid_one">
            {/* Subscribe button */}
            {/* <Button kind="tertiary"
              onClick={() => window.open("https://in.newsroom.ibm.com/subscribe", "_blank")}
            >
              Subscribe to email
            </Button> */}
          </Column>
        </Grid>
      </Content>
    </>
  );
};

export default Blog1;
