import React, { useState } from 'react';
import {
  Content,
  Grid,
  Column,
  Button,
  GridSettings,
  Heading,
  Section
} from 'carbon-components-react';
import { Printer, Email, Wifi, DocumentPdf } from '@carbon/icons-react';
import "./style.scss";
import { Image } from '../../../Image';

const Blog3 = () => {
  const content = {
    section: {
      level: 1,
      heading: "Crafting Unique Shopping Journeys: The Power of Retail Personalization",
      date: "Jan 27, 2023",
      bannerImage: Image?.featured_banner7,
    },
    mainGrid: {
      paragraphs: [
        "In today's retail environment, traditional strategies like unique product assortments and competitive pricing are losing their edge due to the ease with which competitors can replicate them. However, creating a unique customer experience through personalization remains a viable path to differentiation. This approach involves crafting individualized experiences for customers, a strategy that is challenging for competitors to emulate and can lead to a sustainable competitive edge.",
        "Elevating Customer Experiences through Personalization",
        "Contemporary consumers, influenced by online retail giants such as Amazon, have come to expect and desire shopping experiences tailored to their personal preferences. This trend has evolved from simple targeted offers to a comprehensive approach encompassing the entire customer journey, with multiple personalized touchpoints. The most effective personalized experiences involve engaging customers in a dialogue and using data to create interactions that are unique to each individual.",
        "The Benefits of Personalization in Retail",
        "For retailers, adopting personalization is not just a strategy to stand out, but a necessity to thrive in a competitive market. When implemented effectively, personalization can significantly enhance a retailer's performance. For grocery retailers, this can mean a 1 to 2 percent increase in total sales, driven by heightened loyalty and increased spending from existing customers. Successful personalization also leads to more engaged customers, higher sales conversion rates, and improved employee engagement. Retailers who excel in customer experience often see returns three times higher than those with lower customer satisfaction scores.",
        "Success Stories in Retail Personalization",
        "Various retailers across different sectors have successfully implemented personalization at scale. Amazon has been a pioneer in this area, using advanced analytics to create highly personalized shopping experiences. European grocery chains have also achieved success by leveraging loyalty data from their physical stores to offer personalized experiences. These examples demonstrate the effectiveness of personalization in enhancing customer loyalty and driving sales.",
        "Overcoming Challenges in Personalization",
        "Despite the clear advantages, personalization in retail is not without its challenges. Retailers often struggle with data management, analytics expertise, organizational alignment, and finding the right technology solutions. These challenges are compounded in hybrid retail models that combine online and physical stores. However, overcoming these obstacles is crucial for retailers to create personalized experiences that resonate with customers and drive business success.",
        "The Path to Effective Personalization",
        "To achieve effective personalization, retailers should start by identifying high-impact use cases relevant to consumers but not overly complex to execute. Building a skilled cross-functional team is essential to develop an integrated database for these use cases. The team should focus on making data highly available and targeted, considering the needs of future programs. This database should be iteratively developed through testing and learning. Retailers should also create a cross-functional team to experiment and learn from these trials. Analytics and technology professionals are critical to the program, especially when scaling up. Finding the right external partner to help develop the personalization program can accelerate progress towards achieving a more personalized customer experience, increased customer loyalty, and improved financial performance.",
        
        "Conclusion",
        "Personalization in retail represents a significant opportunity for retailers to differentiate themselves in a crowded market. By thoughtfully implementing personalized experiences, retailers can enhance customer loyalty, stand out from competitors, and see substantial improvements in both their top and bottom lines."
      ]
    }
  };

  const [isSideNavExpanded, setSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <>
      <Content className='get_ready_cases'>
        <Section level={1}>
          <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
            {content.section.heading}
          </Heading>
          <p style={{ marginTop: 18 }}>{content.section.date}</p>
          <img src={Image?.featured_banner7} style={{ height: "100%", width: "100%", marginTop: 20 }} />
        </Section>
        <Grid fullWidth className='main_grid' >
          <Column lg={11} md={8} sm={4} className="first_grid">
            <GridSettings subgrid={false}>
              <Grid>
                <Column lg={1} md={1} sm={4} className="first_grid first_a_grid">
                  {/* Social media icons and other buttons */}
                  {/* <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#161616", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com", "_blank")}></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.example.com/print", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.example.com/wifi", "_blank")}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div> */}
                </Column>
                <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                  {/* Content Section */}
                  {content.mainGrid.paragraphs.map((paragraph, index) => (
                    <p key={index} style={{ marginTop: index === 0 ? "20px" : "10px", fontWeight: index % 2 === 1 ? "bold" : "normal" }}>
                      {paragraph}
                    </p>
                  ))}
                </Column>
              </Grid>
            </GridSettings>
          </Column>
          <Column lg={5} md={4} sm={4} className="second_grid_one">
            {/* Subscribe button */}
            {/* <Button kind="tertiary"
              onClick={() => window.open("https://www.example.com/subscribe", "_blank")}
            >
              Subscribe to email
            </Button> */}
          </Column>
        </Grid>
      </Content>
    </>
  );
};

export default Blog3;
