import React, { useState } from 'react'
import { Content, Button, Grid, Column } from 'carbon-components-react';
import { Search, Notification, Switcher as SwitcherIcon, Printer, Wifi, Email, DocumentPdf } from '@carbon/icons-react';
import { action } from '@storybook/addon-actions';
import { GridSettings, Heading, Section } from '@carbon/react';
import "./style.scss"
import { Image } from '../../Image';


const Blog1 = () => {
  const content = {
    section: {
      level: 1,
      heading: "Getting Ready for a New Era of Data Protection: What Your Business Needs to Know",
      date: "Oct 17, 2023",
      bannerImage: Image?.get_ready_banner,
    },
    mainGrid: {
      author: "Tushar Haralkar",
      paragraphs1: "Today, as businesses navigate the disruptions posed by digital transformation, data has become the biggest asset for organizations. However, owing to rampant hybrid cloud adoption, the creation of siloed databases, dependency on the cloud for storage and personal devices, the risks of sensitive data being exposed are also growing multifold.",
      paragraphs2: "Further, the Government has introduced the Digital Personal Data Protection Act 2023 (DPDP Act), which marks the dawn of a new era of data protection in India. ",
      paragraphs22: "Data privacy and security",
      paragraphs3: "Considering the rapid pace of change in the Indian landscape, you need to reevaluate your data protection mechanisms, ensure the compliance requirements are met and deploy necessary measures to minimize the impact of a data breach.",
      paragraphs4: "A simple and effective solution to the above challenges is for your business to ",
      paragraphs5: "build a robust data-centric cybersecurity program.",
      paragraphs6: "The question is, how can you build this program",
      paragraphs7: "Understanding the data lifecycle and defining data protection processes and controls",
      paragraphs8: "In today’s digital-first world, your business is collecting data from customers daily. As your customers interact with your products and services through their smart devices, huge volumes of data are being created every minute of the day. This data maybe stored on the cloud or on-premises, and then used and analyzed via technologies such as AI for critical decision-making. Therefore, it is important for your business to understand the lifecycle of such data and how it evolves over the course of its journey, to be able to implement robust data privacy and data security mechanisms.",
      paragraphs9: "Keeping the DPDP Act 2023 in mind, the below chart is a representation of the aspects you need to take into consideration when progressing through each of these stages.",
    },
    secondColumn: {
      subscribeButton: {
        onClick: "https://in.newsroom.ibm.com/subscribe",
      },
    },
    dataCloud: {
      heading: "Data in the Cloud",
      paragraphs1: "According to a recent report from Ernst & Young, about 80% of Indian businesses are implementing cloud strategies for modernizing the technology stack and infusing intelligence into business applications. With the growing dependency on multiple clouds, enterprises are struggling to protect their structured and unstructured data that are multiplying across public clouds, data warehouses, and popular SaaS apps. This has also made the average company’s digital footprint and attack surface larger, increasing the possibility of breach of sensitive data."
    },
    tackling: {
      heading: "Tackling Shadow Data",
      paragraphs1: "As more data is being collected, businesses are being increasingly blinded by such data. They don’t know what data is being collected and where such data is stored, which makes protecting such data a huge challenge. In fact, a report from Gartner revealed that globally businesses are drowning in data – only 15% of data collected is viewed as business-critical data. About 33% is viewed as Redundant, Obsolete and Trivial Data (ROT Data) and the remaining becomes dark data, which only adds to a business’ risk exposure.",
      paragraphs2: "We must acknowledge the new reality is that data is everywhere, and security is not."
    },
    immediate: {
      heading: "Immediate Steps Your Business can Take",
      paragraphs1: "Here are 5 quick steps that your business can consider to build a robust data security framework and protect data across the cloud."
    },
    discover: {
      heading: "Discover:",
      paragraphs1: "Uncover and categorize your sensitive data throughout both on-premises and cloud-based data repositories. This is a crucial first step toward defining proper security policies for different data depending on its criticality and compliance requirements."
    },
    protect: {
      heading: "Protect:",
      paragraphs1: "Safeguard sensitive data by implementing policies and actively monitoring data activities."
    },
    analyze: {
      heading: "Analyze:",
      paragraphs1: "Utilize advanced analytics and assessments to thoroughly analyze and fortify defenses against risks and vulnerabilities."
    },
    respond: {
      heading: "Respond:",
      paragraphs1: "Take immediate action against threats and dispatch actionable alerts to security operations systems in real-time."
    },
    comply: {
      heading: "Comply:",
      paragraphs1: "Adhere to data privacy and security regulations while streamlining the process of auditing and reporting."
    },
    paragraphs1: "IBM believes that the future of security is an",
    bold: "open, connected platform approach",
    paragraphs2: "- leveraging open standards, AI and automation to connect security tools and data across the hybrid cloud.",
    towards: "Towards this,",
    IBM: "IBM Security Guardium",
    modern: "is a modern, scalable data security platform that is ready to meet the demands of today’s progressing environments. It helps discover where sensitive data lives, encrypt and monitor what’s important and reduce your risk, protect sensitive and regulated data across multiple cloud environments, and respond in real-time to threats, while managing compliance obligations.",
    further: "Further, businesses can use Comprehensive Cloud Security Posture Management (CSPM) to apply cloud security best practices to complex cloud environments. CSPM can visualize asset inventories, network interconnections and access pathways to important data. CSPM also enables risk visualization, incident response and DevOps integration. It can map risks to compliance standards and best practices.",
    indian: "The Indian cybersecurity ecosystem is at a tipping point. With emphasis being placed on personal data protection like never before, it is imperative for organizations to understand their data lifecycle and ensure there are clearly defined processes and control mechanisms in place when collecting, analysing, storing or sharing such data on-prem or on the cloud. Businesses must adopt robust data security programs that can help them discover and protect their data, proactively analyse and respond to potential threats, and ensure compliance with emerging regulations. Time is of the essence and your business needs to act now.",
    understand: "To understand how you can build a robust data security program with IBM’s solutions, visit our website to book a free consultation with our team of experts.",
    tushar: "Tushar Haralkar is Principal Technical Sales Leader - Security Software, IBM India and South Asia.",
    subscribe: "Subscribe to email"
  }

  const [isSideNavExpanded, setSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
  };


  return (
    <>
      <Content className='get_ready_cases'>
        <Section level={1}>
          <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
            {content.section.heading}
          </Heading>
          <p style={{ marginTop: 18 }}>{content.section.date}</p>
          <img src={Image?.get_ready_banner} style={{ height: "100%", width: "100%", marginTop: 20 }} />
        </Section>
        <Grid fullWidth className='main_grid' >
          <Column lg={11} md={8} sm={4} className="first_grid">
            <GridSettings subgrid={false}>
              <Grid>
                <Column lg={1} md={1} sm={4} className="first_grid  first_a_grid">
                  <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fin.newsroom.ibm.com%252Findex.php%253Fs%253D34137%2526item%253D101&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fin.newsroom.ibm.com%2Findex.php%3Fs%3D34137%26item%3D101", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#161616", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com/intent/tweet?url=https%3A%2F%2Fin.newsroom.ibm.com%2Findex.php%3Fs%3D34137%26item%3D1011", "_blank")}></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://in.newsroom.ibm.com/getting-ready-for-new-era-of-data-protection?adoper=219449_2_LS2&printable", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => (window.open("https://in.newsroom.ibm.com/index.php?s=20324&rsspage=34137", "_blank"))}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div>
                </Column>
                <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                  <span style={{ fontSize: "16px", fontWeight: "600", fontStyle: "italic" }}>{content.mainGrid.author}</span>
                  <p style={{ marginTop: "20px" }}>
                    {content.mainGrid.paragraphs1}
                  </p>
                  <p>
                    {content.mainGrid.paragraphs2} <a style={{ color: "#0062fe", cursor: "pointer" }}>{content.mainGrid.paragraphs22}</a>
                  </p>
                  <p>
                    {content.mainGrid.paragraphs3}
                  </p>
                  <p>
                    {content?.mainGrid?.paragraphs4}<span style={{ fontWeight: "600" }}>{content.mainGrid.paragraphs5}</span> {content.mainGrid.paragraphs6}
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}> {content.mainGrid.paragraphs7}</span>
                  </p>
                  <p>
                    {content.mainGrid.paragraphs8}
                  </p>
                  <p>
                    {content.mainGrid.paragraphs9}
                  </p>
                  <img src={Image?.get_ready_secound} style={{ width: "100%" }} />
                  <p><span style={{ fontWeight: "600" }}> {content?.dataCloud?.heading}</span></p>
                  <p>{content?.dataCloud?.paragraphs1}</p>
                  <p><span style={{ fontWeight: "600" }}> {content?.tackling?.heading}</span></p>
                  <p>{content?.tackling?.paragraphs1}</p>
                  <p>{content?.tackling?.paragraphs2}</p>
                  <p><span style={{ fontWeight: "600" }}> {content?.immediate?.heading}</span></p>
                  <p>{content?.immediate?.paragraphs1}</p>
                  <img src={Image?.get_ready_thourd} style={{ width: "100%" }} />
                  <ul>
                    <li>
                      <p><span style={{ fontWeight: "700" }}>{content?.discover?.heading}</span>{content?.discover?.paragraphs1}</p>
                    </li>
                    <li>
                      <p><span style={{ fontWeight: "700" }}>{content?.protect?.heading}</span>{content?.protect?.paragraphs1}</p>
                    </li>
                    <li>
                      <p><span style={{ fontWeight: "700" }}>{content?.analyze?.heading}</span>{content?.analyze?.paragraphs1}</p>
                    </li>
                    <li>
                      <p><span style={{ fontWeight: "700" }}>{content?.respond?.heading}</span>{content?.respond?.paragraphs1}</p>
                    </li>
                    <li>
                      <p><span style={{ fontWeight: "700" }}>{content?.comply?.heading}</span>{content?.comply?.paragraphs1}</p>
                    </li>
                  </ul>
                  <p>{content?.paragraphs1} <span style={{ fontWeight: "600" }}>{content?.bold}</span> {content?.paragraphs2}</p>
                  <p>
                    {content?.towards} <a style={{ color: "#0062fe", cursor: "pointer" }}>{content?.IBM}</a> {content?.modern}
                  </p>
                  <p>{content?.further}</p>
                  <p>{content?.indian}</p>
                  <p className='getredy_prfole'>
                    {content?.understand}
                  </p>

                  <div style={{ display: "flex", flexWrap: "wrap", paddingBottom: "60px" }}>
                    <div className='btn_card'>
                      <img src={Image?.get_ready_user} style={{ width: "160px" }} />
                      <div style={{ paddingLeft: "15px", paddingTop: "20px" }}>
                        <p className=''>
                          {content?.tushar}
                        </p>
                        <Button className='btn2'>{content?.subscribe}</Button>

                      </div>
                    </div>
                    <div style={{ display: "flex", justifyItems: "center", flexDirection: "column", alignItems: "center" }}>
                      <h5 style={{ fontSize: "18px", fontWeight: "600", marginTop: "16px" }}>Blog Categories</h5>
                      <Button kind="tertiary" size="md" style={{ padding: "7px 18px", display: "block", marginTop: "10px" }}>
                        Secure
                      </Button>
                    </div>
                  </div>
                </Column>
              </Grid>
            </GridSettings>
          </Column>
          <Column lg={5} md={4} sm={4} className="second_grid_one">
            <Button kind="tertiary"
              onClick={() => window.open("https://in.newsroom.ibm.com/subscribe", "_blank")}
            >
              Subscribe to email
            </Button>
          </Column>
        </Grid>
      </Content >
    </>
  )
}

export default Blog1
