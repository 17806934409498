import { Content, Grid, Column, Link, Accordion, AccordionItem, Button } from 'carbon-components-react';
import { Image } from '../../Image';
import { LogoLinkedin, LogoTwitter, Add, Subtract } from '@carbon/icons-react';
import "./style.scss"
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


// const profileNews = [
//   { title: "Forbes: What is Marketing?" },
//   { title: " Edge:  “Our innovations are guided by our foundational principles of trust and ethics,” says IBM exec" },
//   { title: "The Relevance Report 2024: Welcome to AI" },
// ]

const mainGrid ={
  name : "Shysav Murali",
  role:"QA Specialist",
  nameRole : "Shysav is a QA Specialist at Proximety",
  description1:"Shysav is a dedicated and highly skilled Quality Assurance Specialist currently employed at Proximety. With a wealth of experience in the field, Shysav has proven his expertise in ensuring top-notch software quality and maintaining strict standards throughout his career.",
  description2:"Before joining Proximety, he worked as a Quality Assurance Engineer at Infosys for over two years. During this time, he excelled in various areas of software testing, including manual testing, API testing, and test case preparation. His meticulous attention to detail and systematic approach helped him deliver thorough and accurate testing results, ensuring the reliability and stability of the software systems he worked on.",
  description3:"Shysav excels in Agile methodology, emphasizing adaptability and collaboration. His extensive knowledge allows him to seamlessly integrate with development teams and ensuretimely delivery of high-quality software solutions. He is also highly skilled in automation testing using Selenium and Java. He consistently updates his skills to stay current with the latest tools and techniques, resulting in reduced manual efforts and increased test coverage and accuracy.",
  description4:" Recognizing Proximety's commitment to excellence, he is dedicated to ensuring that the company's software meets the highest possible standards. Shysav combines his exceptional technical abilities with excellent teamwork and communication skills. He collaborates closely with developers, product managers, and other stakeholders, ensuring effective communication and a shared understanding of goals and requirements. This collaborative approach facilitates a smooth and efficient QA process, resulting in successful software releases and customer satisfaction.",
  // description5:"A graduate of  of TKM College of Engineering- Kollam, Gautham earned a Bachelor of Technology degree in Electrical & Electronics Engineering.",
  // description6:"Jonathan is an avid music and Wisconsin sports fan. He lives in Westchester County, NY and in his personal time, you can find him spending time with his wife, their two kids and dog."

}

const Shysav = () => {

  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  const [isShow, setIsShow] = useState(false)

  return (
    <Content className='profile_main'>
      <Grid>
        <Column lg={4} md={4} sm={4}>
          <div className='bio_img'>
            <img src={Image?.shysav} />
          </div>
        </Column>
        <Column lg={12} md={8} sm={4} >
          <p style={{ fontSize: "40px", color: "#161616", fontWeight: 300 }}>{mainGrid.name}</p>
          <p style={{ padding: "18px 0px", color: "#161616", fontSize: "24px", fontWeight: 300 }}>{mainGrid.role}</p>
          <div style={{ paddingBottom: "15px" }}>
          <LogoLinkedin style={{ cursor: "pointer" }} size={'40'} onClick={() => window.open("https://www.linkedin.com/in/shysav-murali-a32874190", "_blanck")} />                      
            {/* <LogoTwitter size={'40'} style={{ marginLeft: "10px" }} /> */}
          </div>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.nameRole}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description1}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description2}</p>

          {isShow ?
            <div>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description3}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description4}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description5}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description6}</p>
            </div>
            : null}
          <Link onClick={() => setIsShow(!isShow)} style={{ color: "#0f62fe", fontWeight: 700, fontSize: "18px", cursor: "pointer" }} renderIcon={() => isShow ? <Subtract size={'25'} /> : <Add size={'25'} />}>{isShow ? "Read less" : "Read more"}</Link>
          {/* <Accordion style={{ marginTop: "30px" }} size='lg' className='accordion_main'>
            <AccordionItem title="News" open
            >
              <Grid >
                {
                  profileNews?.map((u, index) => {
                    return (
                      <Column key={index} lg={4} md={8} sm={4} className='accordion_column'>
                        <Link className='bio_accordion_con'>
                          {u?.title}
                        </Link>
                      </Column>
                    )
                  })
                }
              </Grid>
            </AccordionItem>
          </Accordion> */}
          <Grid style={{ marginTop: "30px" }}>
            <Column lg={12} md={8} sm={4}>
              <Button onClick={() => { navigate("/our-team"); handleArrowRightClick(); }} style={{ marginBottom: "48px" }}>
                Back to Leadership
              </Button>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

export default Shysav