import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
// Example import for Carbon Components v10
import { Button } from 'carbon-components-react';
import Layout from './components/Layout';
// import "./Styles/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Account from "./pages/account";
import GetReady from "./pages/get-ready";
import Home from "./pages/home";
import styled from 'styled-components';
import Profile from "./pages/profile";
import { ContactUs } from "./pages/contact-us";
import OurTeam from "./pages/our-team";
import Insights from "./pages/insights";
import WhatWeDo from "./pages/what-we-do";
import WhoWeAre from "./pages/who-we-are";
import Blog1 from "./pages/blog/blog-1";
import Blog2 from "./pages/blog/blog-2";
import Blog3 from "./pages/blog/blog-3";
import Blog4 from "./pages/blog/blog-4";
import Blog5 from "./pages/blog/blog-5";
import Blog6 from "./pages/blog/blog-6";
import Emporia from "./pages/emporia";
import HiliteMall from "./pages/hilite-mall";
import GauthamKrishna from "./pages/gautham-krishna";
import VarshaSusan from "./pages/varsha-susan";
import Sunderjith from "./pages/sunderjith-k";
import Bhumika from "./pages/Bhumika";
import Anasooya from "./pages/Anasooya";
import Shysav from "./pages/shysav";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Basith from "./pages/basith";
import GoogleAnalytics from './googleanalytics';
import { DownloadBrochure } from "./pages/download";

const StyledComponent = styled.div`
  font-family: 'Helvetica', sans-serif;
  /* other styles */
`;


function App() {
  return (
    <>    <ToastContainer />
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/emporia" element={<Emporia />} />
            <Route path="/account" element={<Account />} />
            <Route path="/get-ready" element={<GetReady />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/customer-stories/hilite-mall" element={<HiliteMall />} />
            <Route path="/what-we-do" element={<WhatWeDo />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/download" element={<DownloadBrochure />} />
            <Route path="/who-we-are" element={<WhoWeAre />} />

            <Route path="/sunderjith-k" element={<Sunderjith />} />
            <Route path="/gautham-krishna" element={<GauthamKrishna />} />
            <Route path="/varsha-susan" element={<VarshaSusan />} />
            <Route path="/bhumika" element={<Bhumika />} />
            <Route path="/anasooya" element={<Anasooya />} />
            <Route path="/shysav" element={<Shysav />} />
            <Route path="/basith" element={<Basith />} />


            <Route path="/blog/revolutionizing-retail-embracing-a-new-era-of-technology-integration" element={<Blog1 />} />
            <Route path="/blog/the-transforming-landscape-of-malls-navigating-retail-trends-in-2024" element={<Blog2 />} />
            <Route path="/blog/crafting-unique-shopping-journeys-the-power-of-retail-personalization" element={<Blog3 />} />
            <Route path="/blog/navigating-the-new-retail-landscape-the-rise-of-mmnichannel-mastery" element={<Blog4 />} />
            <Route path="/blog/navigating-retails-new-reality-reinventing-customer-engagement-post-pandemic" element={<Blog5 />} />
            <Route path="/blog/revolutionizing-retail-with-data-analytics-a-new-era-of-customer-insight-and-excellence" element={<Blog6 />} />

          </Routes>
          <GoogleAnalytics />
        </Layout>
      </BrowserRouter>

    </>
  );
}

export default App;
