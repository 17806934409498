import React, { useState } from 'react'
import { Content, Header, HeaderContainer, HeaderMenuButton, HeaderName, HeaderNavigation, Button, Grid, Column, } from 'carbon-components-react';
import { Search, Notification, Switcher as SwitcherIcon, Printer, Wifi, Email, DocumentPdf } from '@carbon/icons-react';
import { action } from '@storybook/addon-actions';
import { GridSettings, Heading, Section } from '@carbon/react';
import "./style.scss"
import { Image } from '../../Image';
import { useNavigate } from 'react-router-dom';

const data = [
    {
        "name": "Gautham Krishna B",
        img: Image.bio_img,
        "role": "Chief Operating Officer, Proximety",
        "profileLink": "/gautham-krishna"
    },
    {
        "name": "Bhumika Sangani",
        img: Image.leader3,
        "role": "Head of Mobile Development ",
        "profileLink": "/Bhumika"
    },
    {
        "name": "Mohammed Basith",
        img: Image.basith,
        "role": "Head of Design",
        "profileLink": "/basith"
    },
    {
        "name": "Varsha Susan Renju",
        img: Image.varsha,
        "role": "Head of Customer Development",
        "profileLink": "/varsha-susan"
    },
    {
        "name": "Shysav Murali",
        img: Image.shysav,
        "role": "QA Specialist",
        "profileLink": "/shysav"
    },
    {
        "name": "Anasooya T",
        img: Image.anasooya,
        "role": "Strategic Communication Specialist ",
        "profileLink": "/anasooya"
    },
   
]
const OurTeam = () => {

    const navigate = useNavigate()

    const handleArrowRightClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page

    };

    const [isSideNavExpanded, setSideNavExpanded] = useState(false);

    const onClickSideNavExpand = () => {
        setSideNavExpanded(!isSideNavExpanded);
        
    };

    return (
        <>
            <div className='executive_con mt-3'>
                <Content className='get_ready_cases'>
                    <Section level={1} className='' >
                        <Heading style={{ fontWeight: 300, fontSize: "36px", }}>
                            Our team
                        </Heading>
                    </Section>
                </Content>
                <Section style={{ backgroundColor: "#f2f4f8", padding: "64px 0px", }}>
                    <div className='executive_data'>
                        <Grid fullWidth className='' >
                            <Column lg={8} md={4} sm={4} className="">
                                <h2 style={{ fontWeight: "300" }}>Sunderjith K</h2>
                                <p>Chief Executive Officer, Proximety</p>
                            </Column>
                            <Column lg={4} md={4} sm={4} className="">
                                <div onClick={() => { navigate("/sunderjith-k"); handleArrowRightClick(); }} className='main_box'>
                                    <div style={{ height: "360px" }}>
                                        <img src={Image.sunder} style={{ width: "97%", height: "100%", overflow: "hidden"}} />
                                    </div>
                                    <div style={{ width: "97%" }} className='box_content'>
                                        <h4>Executive Biography</h4>
                                    </div>
                                </div>
                            </Column>
                        </Grid>
                        <Grid fullWidth className='' >
                            <Column lg={4} md={8} sm={4} className="" style={{ marginTop: "20px" }}>
                                <h3 style={{ fontWeight: "300" }}>Proximety Leadership</h3>
                                <p>Executive biographies and media assets</p>
                            </Column>
                            {data?.map((item) => {
                                return (
                                    <Column lg={4} md={4} sm={4} className="" style={{ marginTop: "20px" }}>
                                        <div onClick={() => { navigate(item.profileLink); handleArrowRightClick(); }} className='main_box'>
                                            <div style={{ height: "360px" }}>
                                                <img src={item.img} style={{ width: "97%", height: "100%", overflow: "hidden" }} />
                                            </div>
                                            <div style={{ width: "97%" }} className='box_content'>
                                                <h4 >{item?.name}</h4>
                                                <p>{item?.role}</p>
                                            </div>
                                        </div>
                                    </Column>
                                )
                            })}
                        </Grid>
                    </div>
                </Section>
            </div>
        </>
    )
}

export default OurTeam