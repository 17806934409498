// Import necessary dependencies and components
import React, { useEffect, useState } from 'react';
import { Content, Header, HeaderContainer, HeaderMenuButton, HeaderName, HeaderNavigation, HeaderMenu, HeaderMenuItem, HeaderGlobalBar, HeaderGlobalAction, HeaderSideNavItems, SkipToContent, SideNav, SideNavItems } from 'carbon-components-react';
import { Search, Notification, Switcher as SwitcherIcon } from '@carbon/icons-react';
import { action } from '@storybook/addon-actions';
import { HeaderMobile, HeaderWeb } from './HeaderSupport';
import { Image } from '../Image';
import { useNavigate } from 'react-router-dom';
const data = {
  'What we do': [{ label: "Overview", link: "/what-we-do", gridId: "list-item-1" }, { label: "Retail transformation", link: "/what-we-do", gridId: "list-item-2" }, { label: "Capabilities", link: "/what-we-do", gridId: "list-item-3" }, { label: "Products", link: "/what-we-do", gridId: "list-item-4" }, { label: "FAQ", link: "/what-we-do", gridId: "list-item-5" }, { label: "Contact us", link: "/what-we-do", gridId: "list-item-6" }],
  'Who we are': [{ label: "Overview", link: "/who-we-are", gridId: "list-item-1" }, { label: "Our growth", link: "/who-we-are", gridId: "list-item-2" }, { label: "Our team", link: "/our-team" }, { label: "More info", link: "/who-we-are", gridId: "list-item-3" }, { label: "Contact us", link: "/who-we-are", gridId: "list-item-4" }],
  'Emporia': [{ label: "Overview", link: "/emporia", gridId: "list-item-1" }, { label: "Benefits", link: "/emporia", gridId: "list-item-2" }, { label: "Products", link: "/emporia", gridId: "list-item-3" }, { label: "Services", link: "/emporia", gridId: "list-item-4" }, { label: "How we empower", link: "/emporia", gridId: "list-item-5" }, { label: "Case study", link: "/emporia", gridId: "list-item-6" }, { label: "User stories", link: "/emporia", gridId: "list-item-7" }, { label: "Contact us", link: "/emporia", gridId: "list-item-8" }],
  'Insights': [{ label: "Overview", link: "/insights", gridId: "list-item-4" }, { label: "Global studies", link: "/get-ready", gridId: "list-item-4" }, { label: "Topics", link: "/account", gridId: "list-item-4" }],
}
const MyHeaderComponent = () => {
  const navigate = useNavigate()
  const [isSideNavExpanded, setSideNavExpanded] = useState(false);
  const [subHeaderOpen, setSubHeaderOpen] = useState("");
  const [subHeaderOpen2, setSubHeaderOpen2] = useState("");
  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
    setSubHeaderOpen("")
    onClickSubHeaderOpen2("")
    window.scrollTo(0, 0)
  };
  const onClickSubHeaderOpen = (item) => {
    setSubHeaderOpen(item);
  };

  const onClickSubHeaderOpen2 = (item) => {
    window.scrollTo(0, 0)
    if (subHeaderOpen2 === item) {
      setSubHeaderOpen2("")
    } else {
      setSubHeaderOpen2(item);
    }
  };
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  useEffect(() => {
    // Update body scroll based on the state
    if (subHeaderOpen || subHeaderOpen2) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // Clean up on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [subHeaderOpen, subHeaderOpen2]);

  const logoImages = {
    default: Image?.Proximety_Logo,
    hover: Image?.Proximety_Logo_Hover // This is the image with the changed color
  };

  const [logoSrc, setLogoSrc] = useState(logoImages.default);

  return (
    <>
      <Header aria-label="IBM Platform Name" className='header_main'>
        <SkipToContent />
        <HeaderMenuButton aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'} onClick={onClickSideNavExpand} isActive={isSideNavExpanded} aria-expanded={isSideNavExpanded} />
        {/* <HeaderName href="/" prefix="IBM" style={{wi}}/> */}
        {/* <div className='main_header_div'> */}
        {/* <img onClick={() => navigate('/')} src={Image?.Proximety_Logo} alt="Logo" width={120} height={30} style={{ marginRight: "32px", marginLeft: "20px" }} /> */}

        <img
          onClick={() => {navigate('/'); handleArrowRightClick()}}
          src={logoSrc}
          alt="Logo"
          width={120}
          height={30}
          style={{ marginRight: "32px", marginLeft: "20px" }}
          onMouseEnter={() => setLogoSrc(logoImages.hover)}
          onMouseLeave={() => setLogoSrc(logoImages.default)}
        />

        <HeaderNavigation className='heder_navigation' aria-label="IBM [Platform]">
          <HeaderMenu aria-label="Link 4" menuLinkName="What we do" onClick={() => navigate('/what-we-do')} onMouseEnter={() => onClickSubHeaderOpen('What we do')} onMouseLeave={() => onClickSubHeaderOpen("")} />
          {/* <HeaderMenuItem aria-label="Link 7" style={{ cursor: "pointer" }} onClick={() => { navigate("/what-we-do"); handleArrowRightClick(); }} >What we do</HeaderMenuItem> */}
          <HeaderMenu aria-label="Link 4" menuLinkName="Who we are" onClick={() => navigate('/who-we-are')} onMouseEnter={() => onClickSubHeaderOpen('Who we are')} onMouseLeave={() => onClickSubHeaderOpen("")} />

          {/* <HeaderMenu aria-label="Link 5" menuLinkName="Who we are" onClick={() => navigate('/who-we-are')} onMouseEnter={() => onClickSubHeaderOpen("Who we are")} onMouseLeave={() => onClickSubHeaderOpen("")} /> */}
          {/* <HeaderMenuItem aria-label="Link 7" style={{ cursor: "pointer" }} onClick={() => { navigate("/who-we-are"); handleArrowRightClick(); }} >Who we are</HeaderMenuItem> */}
          {/* <HeaderMenuItem aria-label="Link 7" style={{ cursor: "pointer" }} onClick={() => { navigate("/emporia"); handleArrowRightClick(); }} >Emporia</HeaderMenuItem> */}
          <HeaderMenu aria-label="Link 4" menuLinkName="Emporia" onClick={() => navigate('/emporia')} onMouseEnter={() => onClickSubHeaderOpen('Emporia')} onMouseLeave={() => onClickSubHeaderOpen("")} />

          {/* <HeaderMenu aria-label="Link 6" menuLinkName="Insights" onClick={() => navigate('/insights ')} onMouseEnter={() => onClickSubHeaderOpen("Insights")} onMouseLeave={() => onClickSubHeaderOpen("")} /> */}
          <HeaderMenuItem aria-label="Link 7" style={{ cursor: "pointer" }} onClick={() => { navigate("/insights"); handleArrowRightClick(); }} >Insights</HeaderMenuItem>

          <HeaderMenuItem aria-label="Link 7" style={{ cursor: "pointer" }} onClick={() => { navigate("/contact-us"); handleArrowRightClick(); }}>Contact us</HeaderMenuItem>
        </HeaderNavigation>
        {/* </div> */}
        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false} onSideNavBlur={subHeaderOpen2 ? () => setSideNavExpanded(true) : () => onClickSideNavExpand()}>
          <SideNavItems>
            <HeaderSideNavItems className='heder_navigation'>
              <HeaderMenu aria-label="Link 4" menuLinkName="What we do" onClick={() => onClickSubHeaderOpen2("What we do")} />
              {/* <HeaderMenuItem aria-label="Link 7" onClick={() => { navigate('/what-we-do'); handleArrowRightClick(); onClickSideNavExpand(); }} >What we do</HeaderMenuItem> */}

              {/* <HeaderMenu aria-label="Link 5" menuLinkName="Who we are" onClick={() => onClickSubHeaderOpen2("Who we are")} /> */}
              {/* <HeaderMenuItem aria-label="Link 7" onClick={() => { navigate('/who-we-are'); handleArrowRightClick(); onClickSideNavExpand(); }} >Who we are</HeaderMenuItem> */}
              {/* <HeaderMenuItem aria-label="Link 7" onClick={() => { navigate('/emporia'); handleArrowRightClick(); onClickSideNavExpand(); }} >Emporia</HeaderMenuItem> */}
              <HeaderMenu aria-label="Link 4" menuLinkName="Who we are" onClick={() => onClickSubHeaderOpen2("Who we are")} />

              <HeaderMenu aria-label="Link 4" menuLinkName="Emporia" onClick={() => onClickSubHeaderOpen2("Emporia")} />

              {/* <HeaderMenu aria-label="Link 6" menuLinkName="Insights" onClick={() => onClickSubHeaderOpen2("Insights")} /> */}
              <HeaderMenuItem aria-label="Link 7" onClick={() => { navigate('/insights'); handleArrowRightClick(); onClickSideNavExpand(); }} >Insights</HeaderMenuItem>

              <HeaderMenuItem aria-label="Link 7" onClick={() => { navigate('/contact-us '); handleArrowRightClick(); onClickSideNavExpand(); }} >Contact us</HeaderMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
      </Header>
      {subHeaderOpen && <HeaderWeb data={data?.[subHeaderOpen]} onClickSubHeaderOpen={onClickSubHeaderOpen} title={subHeaderOpen} />}
      {subHeaderOpen2 && <HeaderMobile data={data?.[subHeaderOpen2]} title={subHeaderOpen2} link={subHeaderOpen2 === "What we do" ? '/what-we-do' : subHeaderOpen2 === "Who we are" ? '/who-we-are' : '/insights'} closeMenu={() => { setSubHeaderOpen2(""); onClickSideNavExpand() }} backClick={() => { setSubHeaderOpen2("") }} />}
    </>
  );
};
export default MyHeaderComponent;