import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content, Tile, Button, Grid, Column, Link, Tag, ClickableTile, OverflowMenu, Accordion, OverflowMenuItem, AccordionItem, Tab, Tabs, TabList, TabPanel, TabPanels, Dropdown } from 'carbon-components-react';
import { Calendar, ArrowRight, Product, Partnership, IbmCloudResiliency, ChartLine, IbmCloud, IbmWatsonxOrchestrate, CustomerService, ShoppingCartArrowUp, AnalyticsReference, Store } from '@carbon/icons-react';
import "./style.scss"
import { Image } from '../../Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ScrollSpy from '../../components/common/ScrollSpy';
import { scroller } from 'react-scroll';
const data = [
  {
    name: "Overview",
    link: "list-item-1"
  },
  {
    name: "Benefits",
    link: "list-item-2"
  },
  {
    name: "Products",
    link: "list-item-3"
  },
  {
    name: "Key features",
    link: "list-item-4"
  },
  {
    name: "User stories",
    link: "list-item-5"
  },
  {
    name: "How we empower",
    link: "list-item-6"
  },
  {
    name: "Case study",
    link: "list-item-7"
  },
  {
    name: "Next steps",
    link: "list-item-8"
  },
]

const ProductconstData = [
  {
    // lable: "Digital labor",
    title: "Everything App: iOS and Android mall apps for customers",
    discription: "This consumer-facing app, with its wide range of features and great UI, is the ultimate tool for enhancing customer engagement and satisfaction.",
    "image": Image?.everything_app,
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
  {
    // lable: "IT Automation",
    title: "Partner Application for restaurants",
    discription: "Tailored for mall restaurants, this app handles menus, orders, discounts and sales analytics.",
    "image": Image?.partner_app,
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
  {
    // lable: "Digital labor",
    title: "Admin Console for mall management",
    discription: "A comprehensive tool for mall administrators, overseeing content management, event organization, ad campaigns and analytics, serving as a central hub for platform management.",
    "image": Image?.admin_console,
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
  {

    title: "Emporia Shop App for Tenants",
    discription: "A portal for the mall's tenants to seamlessly push promotions, engage shoppers via RMN, and elevate their brand presence.",
    "image": Image?.shop_app,
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
  // {
  //   lable: "AI and data platform",
  //   title: "watsonx.ai",
  //   discription: "Scale AI workloads, for all your data, anywhere.",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   lable: "AI and data platform",
  //   title: "watsonx.data",
  //   discription: "Enable responsible, transparent and explainable data and AI workflows",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   lable: "AI and data platform",
  //   title: " watsonx.governance",
  //   discription: "Enable responsible, transparent and explainable data and AI workflows.",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   lable: " IT automation",
  //   title: "IBM Turbonomic®",
  //   discription: "Respond more quickly—even proactively—to performance slowdowns and outages in your tech stack with end-to-end visibility and context.",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   lable: "IT automation",
  //   title: "IBM Instana Observability",
  //   discription: "Enhance your application performance monitoring to provide the context you need to resolve incidents faster.",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   lable: " IT automation",
  //   title: "IBM AIOps Insights",
  //   discription: "AIOps Insights is an early product experience to validate and solve the problems central IT operations teams face in managing the availability of enterprise IT resources through event and incident management",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // }
]

const ServiceData = [
  {
    // lable: " Consulting services",
    title: "Customer Loyalty",
    discription: "Reward and incentivize customers with redeemable vouchers, scratch cards, parking vouchers etc. ",
  },
  {
    // lable: "Security Service",
    title: "Food ordering",
    discription: "Offer the utmost convenience to customers through the online food ordering feature.",
  },
  {
    // lable: "Expert services",
    title: "Store catalogue and search",
    discription: "Enable shoppers to effortlessly explore and discover all that your mall offers through vertical searches for stores.",
  },
  {
    // lable: "Expert services",
    title: "Retail media network",
    discription: "Deliver targeted ads to enhance customer shopping experiences. Drive a new revenue stream for the mall through brand advertising partnerships.",
  },
  {
    // lable: "Expert services",
    title: "Indoor wayfinder",
    discription: "Provide user-friendly mall navigation, guiding customers from the parking lot to their preferred stores.",
  },
  {
    // lable: "Expert services",
    title: "Event ticketing",
    discription: "Centralize and promote mall events with visually appealing listings and offer direct ticket sales, eliminating the need for third-party platforms.",
  },
  {
    // lable: "Expert services",
    title: "Notifications",
    discription: "Send real-time notifications with customizable landing pages to customers, keeping them informed about ongoing promotions, events, and new arrivals, fostering engagement.",
  },
  {
    // lable: "Expert services",
    title: "Smart parking",
    discription: "Simplify parking with features like QR code bookmarking and seamless navigation, ensuring customers easily locate their parked vehicles.",
  },
  {
    // lable: "Expert services",
    title: "Integrated marketing",
    discription: "Leverage built-in marketing tools to promote the platform effectively, including referral bonuses, employee discounts, and integration with external ad campaigns.",
  },
  {
    // lable: "Expert services",
    title: "Movie ticketing",
    discription: "Versatile movie ticketing feature, offering seamless integration for direct bookings or with third-party services, while boosting customer loyalty through rewards and tailored marketing communications.",
  },

]

const ResourcesData = [
  {
    // lable: "Article",
    title: "Busy mom Maya: Simplifying shopping",
    discription: "Maya uses the Everything App by Emporia to quickly locate stores, find the best deals, and navigate the mall efficiently, making her shopping trips with kids less stressful and more enjoyable.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://www.ibm.com/topics/artificial-intelligence"
  },
  {
    // lable: "Guide",
    title: "Mall manager Mohan: Streamlined content management",
    discription: "With Emporia's Admin Console, Mohan efficiently manages platform content, creates loyalty vouchers and campaigns leading to increased engagement and higher customer satisfaction.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://www.ibm.com/downloads/cas/MV9EXNV8"
  },
  {
    // lable: "Research",
    title: "Entrepreneurial restaurateur Ritu: Boosting business",
    discription: "Ritu leverages Emporia’s Restaurant App to manage her menu, track orders, and create targeted promotions, resulting in higher customer turnout and improved service quality.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://research.ibm.com/artificial-intelligence"
  },
  {
    // lable: "Blog",
    title: "Tech-Savvy teenager Tarun: Engaging shopping experience",
    discription: "Tarun enjoys using Emporia’s interactive features and personalized recommendations, making his mall visits more engaging and fun.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://www.ibm.com/blog/how-ibm-consulting-brings-a-valuable-and-responsible-approach-to-ai/"
  },
  {
    // lable: "White Paper",
    title: "Retail owner Rajesh: Marketing made easy",
    discription: "Rajesh uses Emporia's retail media network to engage his target customers, build customer's brand loyalty and thus enhancing his store’s profitability.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://www.ibm.com/downloads/cas/J2LAYLOZ"
  },
  {
    // lable: "Guide",
    title: " Event planner Esha: Effective promotion",
    discription: "Esha utilizes Emporia to promote mall events, manage ticketing, and analyze attendee data, leading to successful, well-attended events and increased mall footfall.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://www.ibm.com/resources/the-data-differentiator"
  },
  {
    // lable: "News",
    title: "Parking attendant Pranav: Smarter parking solutions",
    discription: "Pranav finds managing the mall’s parking easier with Emporia’s smart parking features, helping customers quickly find parking spots and reducing congestion during peak hours.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://newsroom.ibm.com/2023-08-03-IBM-and-NASA-Open-Source-Largest-Geospatial-AI-Foundation-Model-on-Hugging-Face"
  },
  {
    // lable: "News",
    title: "Movie buff Meera: Rewarding movie experience",
    discription: "Meera swiftly books movie tickets using The Everything App and gets rewarded for it.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />,
    // link: "https://newsroom.ibm.com/2023-08-03-IBM-and-NASA-Open-Source-Largest-Geospatial-AI-Foundation-Model-on-Hugging-Face"
  }
]

const Benefitsdata = [
  {
    heading: "Online-first approach:",
    desc: "Positioned as a primary interaction point prioritizing the online experience in marketing, and customer engagement.",
    // linkText: "Register for the report",
    icon: <IbmCloud size='70' />
  },
  {
    heading: "Omnichannel experience",
    desc: "Ensure seamless blending of online and offline channels, enhancing each other rather than operating independently.",
    // linkText: "See the latest AI projects",
    icon: <IbmWatsonxOrchestrate size='70' />
  },
  {
    heading: "Enhanced customer experience:",
    desc: "Create a customer experience that leverages the strengths of both digital and physical retail.",
    // linkText: "Register for the report",
    icon: <CustomerService size='70' />
  },
  {
    heading: "Data-driven offline improvements:",
    desc: "Utilize online data to enhance offline experiences, such as personalized promotions and optimized layouts.",
    // linkText: "Register for the report",
    icon: <AnalyticsReference size='70' />
  },
  {
    heading: "Retailer Empowerment:",
    desc: "Equip retailers with online-first tools and training to understand and integrate digital presence effectively.",
    // linkText: "Register for the report",
    icon: <ShoppingCartArrowUp size='70' />
  },
  {
    heading: "Physical-Online Synergy:",
    desc: "Leverage mall's physical presence to enrich the online experience with exclusive in-mall benefits.",
    // linkText: "Register for the report",
    icon: <Store size='70' />
  },
]

const Dive = [
  {
    title: "In person event",
    linkTag: "IBM showcases AI for sustainability at COP28, Nov 30 - Dec 12",
    link: "https://www.carbondesignsystem.com/"
  },
  {
    title: "Digital event",
    linkTag: "IBM showcases AI for sustainability at COP28, Nov 30 - Dec 12",
    link: "https://www.carbondesignsystem.com/"
  },
  {
    title: "In person event",
    linkTag: "IBM showcases AI for sustainability at COP28, Nov 30 - Dec 12",
    link: "https://www.carbondesignsystem.com/"
  }
]

const useCases = [
  {
    title1: 'Transformative assistance',
    title2: 'Guiding the digital revolution in retail',
    dis: 'We assist malls in embracing the digital era, starting with a comprehensive assessment of current operations and identifying key areas for digital enhancement. Our focus is on integrating advanced technologies like AI, advanced analytics and omnichannel solutions to modernize and elevate the shopping experience.',
    // link: 'Unlock productivity with AI and automation',
    imgUrl: Image?.use_case_1,
    // accordionTitle: "Digital labor and customer service"
  },
  {
    title1: 'Implementation and integration',
    title2: 'Seamless implementation for a connected experience',
    "dis": "Our approach to implementation is methodical and tailored. We ensures the smooth integration of Emporia with existing systems, maintaining consistency with the mall's brand identity. From tailored features to customized marketing tools, we ensure that every aspect reflects the essence of your brand for a cohesive and connected experience.",
    // link: 'AIOps and network performance solutions',
    imgUrl: Image?.use_case_2,
    // accordionTitle: "IT and network efficiency"
  },
  {
    title1: 'Strategic roadmap and support',
    title2: 'Sustained success with a forward-thinking strategy',
    dis: 'We provide a strategic roadmap for continuous improvement and adaptation to future retail trends. This includes ongoing support and training for mall staff, ensuring they are equipped to utilize the full potential of Emporia. Our strategy encompasses regular updates, feedback loops, and a focus on data-driven decision-making to keep malls at the forefront of retail innovation.',
    // link: 'Manage the threat landscape with AI at the helm',
    imgUrl: Image?.use_case_3,
    // accordionTitle: "Section 3 title"
  }
]
const throttle = (func, limit) => {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

const Emporia = () => {
  const [activeItem, setActiveItem] = useState("list-item-1");

  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };



  // const handleScroll = () => {
  //   const sectionElements = document.querySelectorAll('.scrollspy-section');
  //   let found = false;

  //   sectionElements.forEach((section) => {
  //     console.log("section",section);
  //     const rect = section.getBoundingClientRect();
  //     if (rect.top <= 50 && rect.bottom >= 50 && !found) {
  //       setActiveItem(section.id);
  //       found = true;
  //     }
  //   });
  // };


  // const throttledHandleScroll = useRef(throttle(handleScroll, 200));

  // useEffect(() => {
  //   window.addEventListener('scroll', throttledHandleScroll.current);

  //   return () => {
  //     window.removeEventListener('scroll', throttledHandleScroll.current);
  //   };
  // }, [throttledHandleScroll]);

  const handleScroll = () => {
    // Find the section that is currently in view based on the scroll position
    const scrollY = window.scrollY;

    for (const item of data) {
      const section = document.getElementById(item.link);

      if (section) {
        const rect = section.getBoundingClientRect();
        console.log("rect", rect, item);
        if (rect.top <= 10 && rect.bottom >= 10) {
          // Section is in view, update the dropdown value
          setActiveItem(item.link);
          break;
        }
      }
    }
  };

  const handleDropdownChange = (e) => {
    const selectedItem = e?.selectedItem;
    setActiveItem(selectedItem?.link);

    // Scroll to the selected item's section
    if (selectedItem?.link) {
      scroller.scrollTo(selectedItem.link, {
        duration: 200,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  const handleDownloadClick = () => {
    // Create an anchor element
    const link = document.createElement('a');
    // Set the href attribute to the URL of the PDF file
    link.href = 'path/to/your/pdf/file.pdf';
    // Set the download attribute to specify the filename to be downloaded
    link.download = 'brochure.pdf';
    // Programmatically click the link to trigger the download
    link.click();
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeItem]);
  return (
    <Content className='artificial_main'>
      <div className='artificial_main_banner'>
        <div className='artificial_main_contant'>
          <h1 className='main_title'>Proximety Emporia</h1>
          <div className='artificial_contant_inner'>
            <p className='description'>Transforming malls into future-ready omnichannel destinations.</p>
            <div className='artificial_contant_btn'>
              <Button className='exploer_btn1' onClick={() => { navigate("/download"); handleArrowRightClick(); }} renderIcon={(props) => <ArrowRight size={24} {...props} />} iconDescription="ArrowRight">Download brochure</Button>
              <Button className='exploer_btn2' kind="tertiary" onClick={() => { navigate("/customer-stories/hilite-mall"); handleArrowRightClick(); }} renderIcon={(props) => <ArrowRight size={24} {...props} />} iconDescription="ArrowRight">Case study</Button>
            </div>
          </div>
        </div>
      </div>
      <div className='mobile_img2'>
        <img className='mobile_img2' src={Image?.artificial_intelligence_banner} />
      </div>

      <Grid fullWidth className='new_grid' style={{ marginTop: "30px" }} >
        <Column lg={4} md={4} sm={4} style={{ position: 'sticky', top: '49px', height: '55vh', backgroundColor: 'transparent' }} className='artificial_sidebar'>

          <ScrollSpy data={data} activeItem={activeItem} setActiveItem={setActiveItem} />
        </Column>
        <Column lg={4} md={8} sm={4} style={{ position: 'sticky', top: '48px', backgroundColor: 'transparent' }} className='artificial_sidebar1'>
          <Dropdown
            className='artificial_sidebar1'
            id="default"
            selectedItem={data?.filter((item) => item?.link === activeItem)?.[0]}
            // initialSelectedItem={data?.filter((item) => item?.link === activeItem)?.[0]}
            label={data?.filter((item) => item?.link === activeItem)?.[0]?.name}
            items={data}
            onChange={handleDropdownChange}
            itemToString={(item) => (item ? item.name : '')}
          />
        </Column>

        <Column lg={12} md={8} sm={4}
          data-spy="scroll"
          data-target="#list-example"
          data-offset="0"
          className="scrollspy-example"
          style={{ width: '100%', padding: '16px', height: '100%', marginLeft: "0px" }}
        >
          {/* OverView */}
          <Grid id="list-item-1" className="scrollspy-section">
            <Column lg={8} md={8} sm={4} style={{ paddingBottom: "50px" }} className='overView_con'>
              <p className='blockheading' >Proximety Emporia</p>
              <p style={{ marginTop: "30px" }}>Emporia by Proximety is a groundbreaking omnichannel platform that redefines the shopping mall experience, focusing on integrating digital and physical retail spaces. It's not just a digital extension of physical malls, but a primary interaction point, emphasizing the importance of an online-first approach in marketing, customer engagement, and service offerings. It aims to enhance customer experiences by combining personalized online interactions with tailored in-mall experiences. Emporia provides tools and training for retailers to strengthen their digital presence, supporting malls in adopting omnichannel strategies and aligning all operations with this integrated approach, thereby fostering a sustainable and future ready retail environment.</p>

              {/* <p style={{ marginTop: "24px" }}>As your organization uses different datasets to apply machine learning and automation to workflows, it’s important to have the right guardrails in place to ensure <a href="https://www.ibm.com/products/cloud-pak-for-data/governance">data quality</a>, compliance, and transparency within your AI systems.</p> */}
              {/* <p style={{ marginTop: "24px" }}>IBM can help you put AI into action now by focusing on the areas of your business where AI can deliver real benefits quickly and <a href="https://www.ibm.com/topics/ai-ethics">ethically</a>. Our rich portfolio of business-grade AI products and analytics solutions are designed to reduce the hurdles of AI adoption, establish the right <a href="https://www.ibm.com/data-management">data foundation</a>, while optimizing for outcomes and responsible use.<br />
                <br />
                Our researchers are developing the next generation of advances in AI software and hardware to bring frictionless, cloud-native development and use of <a href="https://www.ibm.com/products/watsonx-ai/foundation-models">foundation models</a> to enterprise AI.</p> */}
              {/* <Link style={{ marginTop: "20px" }} size='lg' href="#" renderIcon={() => <ArrowRight size={'19'} />}>
                Get started with watsonx.ai
              </Link> */}
            </Column>
            {/* <Column lg={4} md={8} sm={4} style={{ margin: "0px" }}>
              <h5 style={{ fontSize: 'bold', margin: '0px 0px 16px 16px' }}>Dive deeper into AI</h5>
              {Dive?.map((d, index) => {
                return (
                  <ClickableTile key={index} id="clickable-tile-1" href={d?.link} className="overview-grid overview-grid-event">
                    <div>
                      <Tag className="some-class" type={index == 1 ? "green" : "cool-gray"} title="Clear Filter" size="md">
                        {d?.title}
                      </Tag>
                    </div>
                    <Link size='lg' href="#" >
                      {d?.linkTag}
                    </Link>
                    <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'19'} />} />
                  </ClickableTile>
                )
              })

              }
              <ClickableTile id="clickable-tile-1" href="https://www.carbondesignsystem.com/" className="overview-grid overview-grid-event">
                <div>
                  <Tag className="some-class" type="green" title="Clear Filter">
                    Digital event
                  </Tag>
                </div>
                <Link size='lg' href="#" >
                  IBM showcases AI for sustainability at COP28, Nov 30 - Dec 12
                </Link>
                <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'19'} />} />
              </ClickableTile>
              <ClickableTile id="clickable-tile-1" href="https://www.carbondesignsystem.com/" className="overview-grid overview-grid-event">
                <div>
                  <Tag className="some-class" type="cool-gray" title="Clear Filter">
                    In person event
                  </Tag>
                </div>
                <Link size='lg' href="#" >
                  IBM showcases AI for sustainability at COP28, Nov 30 - Dec 12
                </Link>
                <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'19'} />} />
              </ClickableTile>
            </Column> */}
          </Grid>

          <Grid>
            <Column lg={12} md={8} sm={4} >
              <p className='line'></p>
              <Grid style={{ alignItems: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                <Column lg={8} md={8} sm={4} >
                  <p className='byjAI' style={{ fontSize: "30px" }}>Embark on your journey with us</p>
                </Column>
                <Column lg={4} md={8} sm={4} className='benifit_column'>
                  <div className='bing_link benifit_link'>
                    {/* <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'19'} />} >
                      Learn how to scale AI
                    </Link>
                  </div>
                  <div className='benifit_link'>
                    <Link size='lg' href="#" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'19'} />} >
                      Explore the AI Academy
                    </Link> */}
                  </div>
                </Column>
              </Grid>
              <p className='line'></p>
            </Column>

          </Grid>
          {/* Benefits */}
          <Grid id="list-item-2" className="scrollspy-section" >
            <Column lg={12} md={8} sm={4}>
              <p className='benifits' style={{ fontSize: "50px", fontWeight: 300 }}>Benefits</p>
            </Column>
            {
              Benefitsdata?.map((item, index) => {
                return (
                  <Column lg={4} md={8} sm={4} style={{ marginTop: "60px", paddingBottom: "60px", paddingLeft: "15px", paddingRight: "15px" }} key={index}>
                    <div>{item?.icon}</div>
                    <p style={{ marginTop: "15px", fontWeight: 600 }}>{item?.heading}</p>
                    <p style={{ marginTop: "5px" }}>{item?.desc}</p>
                    {/* <Link style={{ marginTop: "45px", fontSize: "16px" }} renderIcon={() => <ArrowRight />}>{item?.linkText}</Link> */}
                  </Column>
                )
              })
            }
          </Grid>

          <Grid>
            <Column lg={12} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
              <p className='benefit_line' ></p>
            </Column>
          </Grid>

          {/* Product Section */}

          <Grid id="list-item-3" className="scrollspy-section" style={{ marginTop: "50px" }}>
            <Column lg={7} md={8} sm={4} >
              <p className='benifits' >Emporia Suite</p>
              <p style={{ marginTop: "20px" }}>
                The Proximety Emporia platform encompasses a suite of integrated products: an Everything app for shoppers, available on Android and iOS, featuring a great UI and a wide array of features enhancing their experience; a dedicated Partner Application for streamlined restaurant operations; a comprehensive Admin Console; and The Emporia Ad Platform, which drives the Retail Media Network. Together, these tools synergize to revolutionize the mall experience, offering seamless navigation, personalized interactions, and efficient operational management, all tailored to the modern retail environment.
              </p>
            </Column>
            <Column lg={12} md={8} sm={4} style={{ marginTop: "50px", position: "relative", margin: "8px" }}>
              {/* <img src={Image?.use_case_2} className='product_img' /> */}
              {/* <ClickableTile className='product_img_card'>
                <div>
                  <p style={{ color: "#525252", fontSize: "0.875rem" }}>iOS and Android Mall Applications:</p>
                  <p style={{ marginTop: "8px", color: "#161616", fontSize: "calc(0.04412rem + 1.47059vw)" }}>Deliver consistent and intelligent customer care across all channels in real-time</p>
                </div>
                <div style={{ marginTop: "50px", }} >
                  <Link size='lg' href="#" style={{}} renderIcon={() => <ArrowRight size={'25'} />} >Explore IBM watsonx Assistant</Link>
                </div>
              </ClickableTile> */}
              {/* <ClickableTile className='product_img_card_mobile'>
                <div>
                  <p style={{ color: "#525252" }}>Digital labor</p>
                  <p style={{ marginTop: "15px", color: "#161616" }}>Deliver consistent and intelligent customer care across all channels in real-time</p>
                </div>
                <div style={{ marginTop: "50px", }} >
                  <Link size='lg' href="#" style={{}} renderIcon={() => <ArrowRight size={'25'} />} >Explore IBM watsonx Assistant</Link>
                </div>
              </ClickableTile> */}
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: '0px', marginBottom: "0px" }}>
              {/* <Column lg={12} md={8} sm={4} className='insights_main_con'> */}
              <Grid>
                {ProductconstData?.map((item, index) => {
                  return (
                    <Column lg={5} md={4} sm={4} style={{ marginTop: "60px" }} key={index}>
                      <Tile id="clickable-tile-1" className="featured_card1">
                        <div className='featured_card_img1'> <img src={item?.image} /></div>
                        <div className='featured_card_con1'>

                          <Link style={{ fontSize: "20px", marginTop: "10px", height: "150px", textDecoration: "none" }}>{item?.title}</Link>
                          <p>{item?.discription}</p>
                          {/* <Link size='lg' href="#" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} /> */}
                        </div>
                      </Tile>
                    </Column>
                  )
                })}
              </Grid>
              {/* </Column>               */}
            </Column>
          </Grid>
          <Grid>
            <Column lg={12} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
              <p className='services_line' ></p>
            </Column>
          </Grid>
          {/* Services Section */}
          <Grid id="list-item-4" className="scrollspy-section" style={{ paddingTop: "45px" }}>
            <Column lg={12} md={8} sm={4}>
              <p className='' style={{ fontSize: "55px", fontWeight: 300, margin: "20px 0px" }}>Key features</p>
              <Grid style={{ marginTop: "20px" }} className='card_border'>
                {
                  ServiceData?.map((item, index) => {
                    return (
                      <Column className='card_main' lg={4} md={4} sm={4} style={{ margin: "0px" }} key={index}>
                        <Tile  id="tile-2" className='service_card1'>
                          <div>
                            <p style={{ color: "#161616", fontSize: "16px" }}>{item?.lable}</p>
                            <p style={{ marginTop: "15px", color: "#161616", fontSize: "22px" }}>{item?.title}</p>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "50%" }}>
                            <p style={{ marginTop: "1px" }}>{item?.discription}</p>
                            {/* <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-start", height: "100%" }}>
                              <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'25'} />} />
                            </div> */}
                          </div>
                        </Tile>
                      </Column>
                    )
                  })
                }
              </Grid>
            </Column>
          </Grid>
          {/* <Grid>
            <Column lg={12} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
              <p className='services_line' ></p>
            </Column>
          </Grid> */}

          <Grid id="list-item-5" className="scrollspy-section">
            <Column lg={12} md={8} sm={4} >
              <p className='benifits' style={{ fontSize: "50px", marginTop: "20px" }}>User stories</p>
              <Grid style={{ marginTop: "20px" }} className='card_border'>
                {
                  ResourcesData?.map((item, index) => {
                    return (
                      <Column lg={4} md={4} sm={4} style={{ margin: "0px" }} key={index} className='card_main'>
                        <Tile id="tile-1" className='service_card2' >
                          <div style={{ display: "flex", justifyContent: "", flexDirection: "column", height: "100%" }}>
                            <p style={{ color: "#525252" }}>{item?.lable}</p>
                            <p style={{ fontSize: "22px", color: "#161616" }}>{item?.title}</p>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", }}>
                            <p style={{ marginTop: "35px", color: "#161616", fontSize: "14px" }}>{item?.discription}</p>
                            {/* <Link size='lg' href="#" style={{ marginTop: "10px" }} renderIcon={() => <ArrowRight size={'25'} />} /> */}
                          </div>
                        </Tile>
                      </Column>
                    )
                  })
                }
              </Grid>
            </Column>
          </Grid>

          <Grid>
            <Column lg={12} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
              <p className='services_line' ></p>
            </Column>
          </Grid>
          {/* Use cases Section */}
          <Grid id="list-item-6" className="scrollspy-section">
            <Column lg={6} md={8} sm={4} style={{ marginTop: "30px" }}>
              <p className='benifits' style={{ fontSize: "50px" }}>How we empower</p>
              {/* <p style={{ marginTop: "30px" }}>IBM supports a number of AI applications to help your business to drive operational efficiencies, deliver insights, and create better employee and customer experiences. Some of our most common use cases include customer service, proactive IT, and cybersecurity automation, which have had strong adoption across industries such as supply chain, healthcare and financial services.
              </p> */}
            </Column>
            <Column lg={12} md={8} sm={4} style={{ marginTop: "60px" }} className='use_case_tab'>
              <Tabs >
                <TabList aria-label="List of tabs">
                  <Tab>Transformative assistance</Tab>
                  <Tab>Implementation and integration</Tab>
                  <Tab>Strategic roadmap and support</Tab>
                </TabList>
                <TabPanels>
                  {
                    useCases?.map((u, index) => {
                      return (
                        <TabPanel key={index}>
                          <div >
                            <UseCasesCom
                              // title1={u?.title1}
                              title2={u?.title2}
                              dis={u?.dis}
                              link={u?.link}
                              imgUrl={u?.imgUrl}
                            />
                          </div>
                        </TabPanel>
                      )
                    })

                  }
                </TabPanels>
              </Tabs>
            </Column>
            <Column lg={12} md={8} sm={4} style={{ margin: "0px", paddingTop: "20px" }} className='use_cases_accordion'>
              <Accordion>
                {
                  useCases?.map((u, index) => {
                    return (
                      <AccordionItem title={u?.title1}>
                        <UseCasesCom
                          // title1={u?.title1}
                          title2={u?.title2}
                          dis={u?.dis}
                          link={u?.link}
                          imgUrl={u?.imgUrl}
                        />
                      </AccordionItem>
                    )
                  })
                }
              </Accordion>
            </Column>
          </Grid>
          <Grid>
            <Column lg={12} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
              <p className='services_line' ></p>
            </Column>
          </Grid>
          <Grid id="list-item-7" className="scrollspy-section" style={{ padding: "0px 15px" }}>
            <Column lg={12} md={8} sm={4} style={{ marginTop: "50px" }}>
              <p className='benifits' style={{ fontSize: "50px" }}>Case study</p>
            </Column>
            <Column lg={12} md={8} sm={4} style={{ marginTop: "50px" }}>
              <Grid>
                <Column lg={12} md={8} sm={4} xlg={6}>
                  <div className='case_study_img'>
                    <img src={Image?.study_com} className='study_com' />
                    <img src={Image?.study_com} className='study_mob' />
                  </div>
                </Column>
                <Column lg={12} md={8} sm={4} xlg={6} style={{ margin: "0px" }}>
                  <div className='case_study_contant' style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <div >
                      <p
                        className="text-mono"
                        dir="auto"
                        style={{ fontWeight: 400, color: "white", fontSize: "14px" }}
                      >
                        Case study
                      </p>
                      <p
                        className="text-mono"
                        dir="auto"
                        style={{ fontWeight: 400, color: "white", fontSize: "28px", marginTop: "15px" }}
                      >
                        HiLITE Mall
                      </p>
                      <p
                        className="text-mono"
                        dir="auto"
                        style={{ fontWeight: 400, color: "white", fontSize: "16px", marginTop: "30px" }}
                      >
                        How a shopping mall went on to get 50,000 signups from customers in just 90 days of launhing with Emporia
                      </p>
                    </div>
                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                      <p style={{ cursor: "pointer", color: "#78a9ff" }} onClick={() => { navigate("/customer-stories/hilite-mall"); handleArrowRightClick(); }}>{<ArrowRight style={{ marginLeft: "5px" }} size={'40'} color='#78a9ff' />}</p>
                    </div>
                  </div>
                </Column>

              </Grid>
            </Column>
          </Grid>

          <Grid id="list-item-8" className="scrollspy-section get_start" >
            <Column lg={9} md={8} sm={4} style={{ marginTop: "60px" }}>
              <p className='benifits' style={{ fontSize: "40px", lineHeight: "55px" }}>Get started quickly with a client demo for Emporia</p>
              <p className="text-mono" dir="auto"
                style={{ marginTop: "30px" }}
              >Contact us to learn more about how Emporia can benefit your shopping mall.

              </p>
              <div className='get_start_new_btn'>
                <Button onClick={() => { navigate("/contact-us"); handleArrowRightClick(); }} renderIcon={(props) => <ArrowRight size={40} {...props} />} iconDescription="ArrowRight" >Contact us</Button>
                {/* <Button renderIcon={(props) => <ArrowRight size={40} {...props} />} kind="tertiary" iconDescription="ArrowRight" className='get_reg'>Get regular AI updates</Button> */}
              </div>
            </Column>
          </Grid>

        </Column>
      </Grid>
    </Content>


  )
}

const UseCasesCom = ({ title1, title2, dis, link, imgUrl }) => {
  return (
    <div className='use_cases_tab_main'>
      <div className='use_cases_tab_con'>
        <p
          className="text-mono"
          dir="auto"
          style={{ fontWeight: 300, fontSize: "14px", }}
        >{title1}</p>
        <p
          className="text-mono"
          dir="auto"
          style={{ fontWeight: 400, fontSize: "20px", marginTop: "10px", marginBottom: "25px" }}
        >
          {title2}
        </p>
        <p
          className="text-mono"
          dir="auto"
          style={{ fontWeight: 400, fontSize: "16px", marginTop: "10px" }}
        >
          {dis}
        </p>
        {/* <Link
           className="text-mono"
           dir="auto"
          style={{ fontWeight: 400, fontSize: "16px", marginTop: "30px", cursor: 'pointer' }}
          renderIcon={() => <ArrowRight style={{ marginLeft: "15px" }} />}
        >
          {link}
        </Link> */}
      </div>
      <div className='tab_img'>
        <img src={imgUrl} />
      </div>
    </div>
  )
}

export default Emporia