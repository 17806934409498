import React, { useEffect, useRef, useState } from 'react'
import { Content, Tile, Button, Grid, Column, Link, Tag, ClickableTile, Accordion, AccordionItem, Tab, Tabs, TabList, TabPanel, TabPanels, Dropdown } from 'carbon-components-react';
import { Calendar, ArrowRight, Product } from '@carbon/icons-react';
import ScrollSpy from '../../components/common/ScrollSpy';
import { Image } from '../../Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./style.scss"
import { scroller } from 'react-scroll';
import MyCarousel from '../../components/common/MyCarousel';
import { useNavigate } from 'react-router-dom';

const data = [
  {
    name: "Overview",
    link: "list-item-1"
  },
  {
    name: "Retail transformation",
    link: "list-item-2"
  },
  {
    name: "Capabalities",
    link: "list-item-3"
  },
  {
    name: "Products",
    link: "list-item-4"
  },
  {
    name: "FAQs",
    link: "list-item-5"
  },
  // {
  //   name: "Additionl resources",
  //   link: "list-item-6"
  // },
  {
    name: "Contact us",
    link: "list-item-6"
  },
]

const ourImpactConfig = [
  {
    label: "Strategic implementation & change management",
    discription: "Guiding retail businesses through seamless technological transitions with focused change management strategies and implementation support.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
  {
    label: "Tailored training programs",
    discription: "Empowering your team with customized training sessions designed to maximize the utilization and understanding of new retail technologies.",
    icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
  {
    label: "Ongoing support & optimization",
    discription: "Providing continuous support and optimization services to ensure sustained performance and adaptation to evolving retail challenges.",
    icon: < ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  },
]
const latestNews = [
  {
    "titel": "Emporia",
    "description": "Turnkey platform for shopping malls to go omnichannel",
    "image": Image?.product_emporia,
    "productLink": "/emporia"
  },
  // {
  //   "titel": "Social Impact",
  //   "description": "Deloitte and IBM Collaborate to Help Organizations Accelerate Sustainability Outcomes",
  //   // "image": Image?.product_product2
  // }
]
const additionalResourcesConfig = [
  {
    label: "Connect and learn",
    links: ["Explore careers at IBM", "Training and skills", "IBM Community"],
  },
  {
    label: "Develop",
    links: ["IBM Developer", "Technologies", "IBM Documentation"],
  },
  {
    label: "Creativity at work",
    links: ["Explore client stories", "Design thinking with IBM Garage", "Free trials"],
  },
  {
    label: "IBM India",
    links: ["Annual Return Form No. MGT-7"],
  },
]

const IconicMoments = [
  {
    title1: 'Omnichannel retail solutions',
    disc: 'Integrating digital and physical retail channels to deliver a seamless and unified customer shopping experience.',
    // link: 'Learn about our insightful approach',
    imgUrl: Image?.about_counsulting
  },
  {
    title1: 'Augmented and virtual reality',
    disc: 'Incorporating AR/VR technologies to create immersive and interactive shopping experiences, both online and in-store.',
    // link: 'Learn about our partnerships',
    imgUrl: Image?.about_ecosystem
  },
  {
    title1: 'AI and machine learning',
    disc: 'Leveraging AI and machine learning to provide predictive analytics, personalized shopping experiences, and intelligent inventory management.',
    // link: 'Learn about IBM and Red Hat solutions',
    imgUrl: Image?.about_redhat
  },
  {
    title1: "Personalization",
    disc: "Promoting sustainable and ethical practices in retail through eco-friendly solutions and responsible supply chain management.",
    imgUrl: Image?.iconic_history_1928,
  },
  {
    title1: 'Retail automation and efficiency',
    disc: 'Implementing automation technologies to streamline operations, reduce costs, and increase overall retail efficiency.',
    // link: 'Learn about our intelligent software',
    imgUrl: Image?.about_software
  },
  {
    title1: 'Data-driven customer insights',
    disc: 'Utilizing advanced data analytics to derive actionable insights, enhancing customer engagement and strategic decision-making.',
    // link: 'Learn about modernizing infrastructure',
    imgUrl: Image?.about_infrastructure
  },



]

const WhatDose = [
  {
    title1: 'Omnichannel',
    disc: 'Seamlessly merging online and offline retail experiences to create a cohesive and customer-centric shopping journey.',
    // link: 'Learn about our insightful approach',
    imgUrl: Image?.about_counsulting
  },
  {
    title1: 'Artificial intelligence',
    disc: 'Utilizing artificial intelligence to personalize customer interactions, enhancing engagement and loyalty.',
    // link: 'Learn about IBM and Red Hat solutions',
    imgUrl: Image?.about_redhat
  },
  {
    title1: 'AR/VR',
    disc: 'Revolutionizing the retail space with Augmented and Virtual Reality technologies for immersive shopping experiences',
    // link: 'Learn about modernizing infrastructure',
    imgUrl: Image?.about_infrastructure
  },
  {
    title1: 'Automation',
    disc: 'Streamlining retail operations with advanced automation solutions for efficiency, accuracy, and cost-effectiveness.',
    // link: 'Learn about our partnerships',
    imgUrl: Image?.about_ecosystem
  },
  {
    title1: 'Analytics',
    disc: 'Empowering retailers with actionable insights through cutting-edge data analytics for strategic decision-making.',
    // link: 'Learn about our intelligent software',
    imgUrl: Image?.about_software
  }
]

const throttle = (func, limit) => {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

const WhatWeDo = () => {
  const [activeItem, setActiveItem] = useState("list-item-1");
  const navigate = useNavigate()

  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  const handleDropdownChange = (e) => {
    const selectedItem = e?.selectedItem;
    setActiveItem(selectedItem?.link);

    // Scroll to the selected item's section
    if (selectedItem?.link) {
      scroller.scrollTo(selectedItem.link, {
        duration: 200,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  // const handleScroll = () => {
  //     const sectionElements = document.querySelectorAll('.scrollspy-section');
  //     let found = false;

  //     sectionElements.forEach((section) => {
  //         const rect = section.getBoundingClientRect();
  //         if (rect.top <= 50 && rect.bottom >= 50 && !found) {
  //             setActiveItem(section.id);
  //             found = true;
  //         }
  //     });
  // };

  // const throttledHandleScroll = useRef(throttle(handleScroll, 200));

  // useEffect(() => {
  //     window.addEventListener('scroll', throttledHandleScroll.current);

  //     return () => {
  //         window.removeEventListener('scroll', throttledHandleScroll.current);
  //     };
  // }, [throttledHandleScroll]);


  const handleScroll = () => {
    // Find the section that is currently in view based on the scroll position
    const scrollY = window.scrollY;

    for (const item of data) {
      const section = document.getElementById(item.link);

      if (section) {
        const rect = section.getBoundingClientRect();

        if (rect.top <= 10 && rect.bottom >= 10) {
          // Section is in view, update the dropdown value
          setActiveItem(item.link);
          break;
        }
      }
    }
  };

  const productsRef = useRef(null);

  const handleNavigateToProducts = () => {
    const productsGrid = document.getElementById("list-item-4");
    if (productsGrid) {
      productsGrid.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Content className='about_main'>
      <Grid fullWidth style={{ marginTop: '80px', }}>
        <Column lg={8} md={8} sm={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <h1 style={{ fontSize: '50px' }}>What we do</h1>
          </div>
          <div>
            <p className='what_we_do_banner_des'>Proximety revolutionizes organized retail with transformative technology, seamlessly merging the physical and digital realms to create a future-ready shopping experience.</p>
            <div className='about_contant_btn' style={{ marginTop: 30 }}>
              <Button  onClick={handleNavigateToProducts} renderIcon={(props) => <ArrowRight size={24} {...props} />} iconDescription="right">View our Products</Button>
              {/* <Button className='what_we_do_exploer_btn' kind="tertiary" renderIcon={(props) => <ArrowRight size={24} {...props} />} iconDescription="ArrowRight">Explore financial information</Button> */}
            </div>
          </div>
        </Column>
        <Column lg={8} md={8} sm={4}>
          <img className='about_image' src={Image?.about_header} />
        </Column>
      </Grid>

      <Grid fullWidth className='new_grid' style={{ marginTop: "74px" }} >
        <Column lg={4} md={4} sm={4} style={{ position: 'sticky', top: '49px', height: '40vh', backgroundColor: 'transparent' }} className='artificial_sidebar'>
          <ScrollSpy data={data} activeItem={activeItem} setActiveItem={setActiveItem} />
        </Column>
        <Column lg={4} md={8} sm={4} style={{ position: 'sticky', top: '49px', backgroundColor: 'transparent' }} className='artificial_sidebar1'>
          <Dropdown
            className='artificial_sidebar1'
            id="default"
            selectedItem={data?.filter((item) => item?.link === activeItem)?.[0]}
            label={data?.filter((item) => item?.link === activeItem)?.[0]?.name}
            items={data}
            onChange={handleDropdownChange}
            itemToString={(item) => (item ? item.name : '')}
          />
        </Column>
        <Column lg={12} md={8} sm={4}
          data-spy="scroll"
          data-target="#list-example"
          data-offset="0"
          className="scrollspy-example"
          style={{ padding: '15px', height: '100%', marginLeft: "0px" }}
        >
          <Grid id="list-item-1" className="scrollspy-sectiong">
            <Column lg={14} md={8} sm={4} >
              <p className='what_we_do_our' style={{ fontWeight: 300, }}>“We believe in more than just adapting to change; we drive it. Embrace the future of retail with us, where technology meets consumer experience, transforming challenges into unparalleled opportunities.”</p>
              <div style={{ marginTop: 50 }}>
                <p style={{ fontSize: "15px", fontWeight: 600, }}>Sunderjith K</p>
                <p style={{ fontSize: "16px", fontWeight: 400, }}>CEO</p>
                <p style={{ fontSize: "16px", fontWeight: 400, }}>Proximety</p>
              </div>
              <p className='line' />
            </Column>
            {/* <Column lg={14} md={8} sm={4} >
              <div style={{ marginTop: '-40px' }}>
                <Link  size='lg' href="/our-team" renderIcon={() => <ArrowRight size={'25'} />} >
                  See our team
                </Link>
              </div>
            </Column> */}
            <Column lg={8} md={8} sm={4} style={{ marginTop: 60 }}>
              <h1 style={{ fontSize: '50px' }}>What does Proximety do?</h1>
              <p style={{ fontSize: '22px', marginTop: '25px', fontWeight: '400', lineHeight: '2rem', color: "#161616" }}>Proximety transforms the organized retail landscape through innovative technology solutions.Our focus is on empowering retailers with smart, scalable, and sustainable tools that drive efficiency and growth in a rapidly evolving market.</p>
            </Column>
            <Column lg={8} md={8} sm={4} style={{ marginTop: 35 }}>
              {/* <img className='about_image' src={Image?.about_overview} /> */}
            </Column>
            {/* <Column lg={15} md={8} sm={4} style={{ marginTop: "60px" }} className='use_case_tab'> */}
            {/* <Tabs>
                <TabList aria-label="List of tabs" >
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Omnichannel</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>AI</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>AR/VR</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Automation</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Analytics</div></Tab>
                </TabList>
                <TabPanels>
                  {WhatDose?.map((u, index) => {
                    return (
                      <TabPanel key={index}>
                        <div className='about_overview_tab_common'>
                          <UseCasesCom
                            title1={u?.title1}
                            disc={u?.disc}
                            link={u?.link}
                            imgUrl={u?.imgUrl}
                          />
                        </div>
                      </TabPanel>
                    )
                  })}
                </TabPanels>
              </Tabs> */}

            {/* </Column> */}
            <Column lg={15} md={8} sm={4} style={{ marginTop: "60px", padding: "0px" }} className='use_cases_accordion'>
              <Accordion>
                {WhatDose?.map((u) => {
                  return (
                    < AccordionItem title={u?.title1}>
                      <UseCasesCom
                        title1={u?.title1}
                        disc={u?.disc}
                        link={u?.link}
                        imgUrl={u?.imgUrl}
                      />
                    </AccordionItem>
                  )
                })}
              </Accordion>
            </Column>
          </Grid>
          <Grid id="list-item-2" className="scrollspy-sectiong">
            <Column lg={15} md={8} sm={4} style={{ marginTop: '60px', marginBottom: "60px" }}>
              <h1 style={{ fontSize: '50px' }}>Retail transformation</h1>
              <Grid>
                {ourImpactConfig?.map((item, index) => {
                  return (
                    <Column lg={5} md={4} sm={4} style={{ marginTop: "60px" }}>
                      <ClickableTile style={{ height: "250px" }} className='our_impact_card'>
                        <div>
                          <p style={{ fontSize: '20px', fontWeight: 400 }}>{item?.label}</p>
                        </div>
                        <div >
                          <p style={{ fontSize: "14px" }}>{item?.discription}</p>
                          <Link size='lg' style={{ marginTop: "20px" }} />
                        </div>
                      </ClickableTile>
                    </Column>
                  )
                })}
              </Grid>
            </Column>
          </Grid>
          <Grid id="list-item-3" className="scrollspy-sectiong">
            <Column lg={8} md={8} sm={4} style={{ marginTop: '60px' }}>
              <h1 style={{ fontSize: '52px' }}>Our capabilities</h1>
            </Column>
            <Column lg={16} md={8} sm={4} className='use_case_tab' style={{ marginTop: '30px' }}>
              <Tabs>
                <TabList aria-label="List of tabs" style={{ width: "100%" }}>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>Omnichannel</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>AR/VR</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>AI & ML</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>Personalization</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>Automation</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>Analytics</div></Tab>
                </TabList>
                <TabPanels>
                  {
                    IconicMoments?.map((u, index) => {
                      return (
                        <TabPanel key={index}>
                          <div className='about_overview_tab_common'>
                            <UseCasesCom
                              title1={u?.title1}
                              disc={u?.disc}
                              imgUrl={u?.imgUrl}
                            />
                          </div>
                        </TabPanel>
                      )
                    })
                  }
                </TabPanels>
              </Tabs>
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "60px" }} className='use_cases_accordion'>
              <Accordion>
                {
                  IconicMoments?.map((u) => {
                    return (
                      <div className='what_we_mobile_accordion'>
                        <AccordionItem title={u?.title1}>
                          <UseCasesCom
                            // title1={u?.title1}
                            disc={u?.disc}
                            imgUrl={u?.imgUrl}
                          />
                        </AccordionItem>
                      </div>
                    )
                  })
                }
              </Accordion>
            </Column>
          </Grid>
          <Grid  id="list-item-4" className="scrollspy-sectiong" ref={productsRef} >
            <Column lg={15} md={8} sm={4} style={{ marginTop: '60px', marginBottom: "60px" }}>
              <h1 style={{ fontSize: '50px' }}>Products</h1>
              {/* <Column lg={12} md={8} sm={4} className='insights_main_con'> */}
              <Grid>
                {latestNews?.map((item, index) => {
                  return (
                    <Column lg={6} md={4} sm={4} style={{ marginTop: "60px" }} key={index}>
                      <ClickableTile onClick={() => { navigate(item.productLink); handleArrowRightClick(); }} id="clickable-tile-1" className="overview-grid featured_card">
                        <div className='featured_card_img'> <img src={item?.image} /></div>
                        <div className='featured_card_con'>
                          <p>{item?.titel}</p>
                          <Link style={{ fontSize: "20px", marginTop: "10px", height: "150px", textDecoration: "none" }}>{item?.description}</Link>
                          <Link size='lg' href="#" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
                        </div>
                      </ClickableTile>
                    </Column>
                  )
                })}
              </Grid>
              {/* </Column>               */}
            </Column>
          </Grid>
          <Grid id="list-item-5" className="scrollspy-sectiong">
            <Column lg={7} md={8} sm={4} style={{ marginTop: '60px' }}>
              <h1 style={{ fontSize: '55px' }}>Frequently asked questions</h1>
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "30px" }} className='what_we_do_accordion'>
              <Accordion>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>What unique value does Proximety offer to the retail industry?</div>}>
                  <p style={{ fontSize: '16px' }}>Proximety specializes in integrating cutting-edge technologies like AI, AR/VR, and advanced data analytics into retail operations. Our products are tailored to enhance customer experiences, optimize operational efficiency, and provide actionable insights, setting us apart as a leader in driving retail innovation.</p><br />
                </AccordionItem>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>How does Proximety ensure a seamless integration of its products with existing retail systems?</div>}>
                  <p style={{ fontSize: '16px' }}>Our approach involves a thorough analysis of your current systems followed by a customized integration plan. We prioritize compatibility and ease of adoption, ensuring our solutions complement and enhance your existing infrastructure without disrupting ongoing operations.</p><br /><br />
                </AccordionItem>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>Can Proximety's products scale according to the size and needs of my retail business?</div>}>
                  <p style={{ fontSize: '16px' }}>Absolutely. Our products are designed to be scalable and flexible, catering to the unique demands of both small-scale retailers and large, multi-chain retail businesses. We ensure that our technology grows with your business, adapting to your evolving needs.</p><br />
                </AccordionItem>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>Does Proximety provide support and training for its retail technology products?</div>}>
                  <p style={{ fontSize: '16px' }}>Yes, we offer comprehensive support and training programs. Our team works closely with your staff to ensure they are well-equipped to utilize our products effectively. We provide ongoing support and updates to ensure you continually reap the benefits of our cutting-edge retail technology.</p><br />
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
          {/* <Grid id="list-item-6" className="scrollspy-sectiong">
            <Column lg={8} md={8} sm={4} style={{ marginTop: '45px' }}>
              <h1 style={{ fontSize: '55px' }}>Additional resources</h1>
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "30px" }}>
              <Grid>
                {additionalResourcesConfig?.map((item, index) => {
                  return (
                    <Column lg={5} md={4} sm={4} style={{ marginTop: "60px" }} key={index}>
                      <div>
                        <p style={{ fontSize: '16px', fontWeight: 600 }}>{item?.label}</p>
                      </div>
                      {item?.links?.map((link, i) => {
                        return <div> <Link key={i} size='lg' href="#" style={{ marginTop: "1px", color: "#0062fe" }} >{link}</Link></div>
                      })}
                    </Column>
                  )
                })}
              </Grid>
            </Column>
          </Grid> */}
          <Grid id="list-item-6" className="scrollspy-section next_Steps" style={{ marginTop: "100px" }} >
            <Column lg={9} md={8} sm={4} style={{ marginTop: "60px" }}>
              <p className='benifits' style={{ fontSize: "40px", lineHeight: "55px" }}>Get in touch</p>
              <p className="text-mono" dir="auto"
                style={{ marginTop: "30px" }}
              >Contact us to learn more about how our products can benefit your business.</p>
              <div className='get_start_new_btn'>
                <Button href="/contact-us" renderIcon={(props) => <ArrowRight size={40} {...props} />} iconDescription="ArrowRight" >Contact us</Button>
              </div>
            </Column>
          </Grid>
        </Column>
      </Grid >
    </Content >
  )
}

const UseCasesCom = ({ title1, disc, link, imgUrl }) => {
  return (
    <>
      <div className='tab_about'>
        <img src={imgUrl} />
      </div>
      <div className='tab_about_box'>
        <p className="text-mono" dir="auto" style={{ fontWeight: 400, fontSize: "25px", }}>{title1}</p>
        <p className="text-mono" dir="auto" style={{ fontWeight: 400, fontSize: "16px", marginTop: "20px" }}>
          {disc}
        </p>
        {link && <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'25'} />} style={{ fontWeight: 400, fontSize: "16px", marginTop: "30px" }}>
          {link}
        </Link>}
      </div>
    </>
  )
}

export default WhatWeDo