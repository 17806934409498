import { Content, Grid, Column, Link, Accordion, AccordionItem, Button } from 'carbon-components-react';
import { Image } from '../../Image';
import { LogoLinkedin, LogoTwitter, Add, Subtract } from '@carbon/icons-react';
import "./style.scss"
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


const profileNews = [
  { title: "Forbes: What is Marketing?" },
  { title: " Edge:  “Our innovations are guided by our foundational principles of trust and ethics,” says IBM exec" },
  { title: "The Relevance Report 2024: Welcome to AI" },
]

const mainGrid ={
  name : "Varsha Susan Renju",
  role:"Head of Customer Development",
  nameRole : "Varsha Susan Renju is the Head of Customer development at Proximety",
  description1:"Varsha brings a dynamic blend of experience and expertise to her role as the Head of Business Development at Proximety. With a proven track record of success across various domains, Varsha has established herself as a versatile and accomplished professional.",
  description2:"Prior to her current position, Varsha honed her skills as a Quality Assurance Specialist at Envestnet Inc., where she demonstrated her proficiency in manual testing, API testing, and test case preparation. Her meticulous attention to detail and dedication to ensuring product quality contributed significantly to the success of projects under her purview.",
  description3:"Eager to expand her horizons, Varsha ventured into business development in Saudi Arabia, where she spearheaded the establishment and management of a new vertical - the Safety Division at Al Estagamah. In this role, she showcased her ability to handle end-to-end operations, from conceptualization to execution, effectively driving growth and fostering strategic partnerships.",
  description4:"Known for her collaborative spirit and exceptional communication skills, Varsha is a natural team player who thrives in dynamic environments. Her passion for continuous learning and self-motivation is evident in her proactive approach to professional development and her unwavering commitment to delivering results.",
  description5:"Outside of her professional pursuits, Varsha is an avid socializer who enjoys connecting with others and embracing new experiences. Her enthusiasm for life and dedication to excellence make her a valuable asset to any team or project she undertakes.",
  description6:"With a proven ability to excel in diverse roles and a relentless drive to succeed, Varsha continues to make significant contributions to the success and growth of Emporia with Proximety."

}
const VarshaSusan = () => {

  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  const [isShow, setIsShow] = useState(false)
  return (
    <Content className='profile_main'>
      <Grid>
        <Column lg={4} md={4} sm={4}>
          <div className='bio_img'>
            <img src={Image?.varsha} />
          </div>
        </Column>
        <Column lg={12} md={8} sm={4} >
          <p style={{ fontSize: "40px", color: "#161616", fontWeight: 300 }}>{mainGrid.name}</p>
          <p style={{ padding: "18px 0px", color: "#161616", fontSize: "24px", fontWeight: 300 }}>{mainGrid.role}</p>
          <div style={{ paddingBottom: "15px" }}>
          <LogoLinkedin style={{ cursor: "pointer" }} size={'40'} onClick={() => window.open("https://www.linkedin.com/in/varsha-susan-renju-91923815", "_blanck")} />
            {/* <LogoTwitter size={'40'} style={{ marginLeft: "10px" }} /> */}
          </div>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.nameRole}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description1}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description2}</p>

          {isShow ?
            <div>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description3}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description4}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description5}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description6}</p>
            </div>
            : null}
          <Link onClick={() => setIsShow(!isShow)} style={{ color: "#0f62fe", fontWeight: 700, fontSize: "18px", cursor: "pointer" }} renderIcon={() => isShow ? <Subtract size={'25'} /> : <Add size={'25'} />}>{isShow ? "Read less" : "Read more"}</Link>
          {/* <Accordion style={{ marginTop: "30px" }} size='lg' className='accordion_main'>
            <AccordionItem title="News" open
            >
              <Grid >
                {
                  profileNews?.map((u, index) => {
                    return (
                      <Column key={index} lg={4} md={8} sm={4} className='accordion_column'>
                        <Link className='bio_accordion_con'>
                          {u?.title}
                        </Link>
                      </Column>
                    )
                  })
                }
              </Grid>
            </AccordionItem>
          </Accordion> */}
          <Grid style={{ marginTop: "30px" }}>
            <Column lg={12} md={8} sm={4}>
              <Button onClick={() => { navigate("/our-team"); handleArrowRightClick(); }} style={{ marginBottom: "48px" }}>
                Back to Leadership
              </Button>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

export default VarshaSusan