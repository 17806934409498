import React from 'react'

const ScrollSpy = ({ data, activeItem }) => {
  return (
    <div id="list-example" className="list-group">
      {data?.map((item) => {
        return (
          <a
            className={`list-group-item list-group-item-action ${activeItem === item?.link ? 'active' : ''}`}
            href={`#${item?.link}`}
          >
            {item?.name}
          </a>
        )
      })}
    </div>
  )
}

export default ScrollSpy