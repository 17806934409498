import React from 'react'
import Footer from './Footer'
import MyHeaderComponent from './Header'

const Layout = ({ children }) => {
  return (
    <div>
      <MyHeaderComponent />
      <div>
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default Layout