import React, { useEffect } from 'react';
import { Content, Grid, Column, Accordion, AccordionItem } from 'carbon-components-react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import "./style.scss";
import { Image } from '../Image';

const Footer = () => {

  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  const navigate = useNavigate()
  const scrollToSection = (sectionId) => {
    // Navigate to the specified page
    // Wait for the navigation to complete, then scroll to the specified section
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000); // Adjust the timeout as needed
  };
  return (
    <Content className='footer_main'>
      <Grid style={{ padding: "0px" }}>
        <Column lg={4} md={4} sm={4} className='footer_logo'>
          <img src={Image?.Proximety_Logo_White} alt="Logo" />
        </Column>
        {/* Hide logos 2 and 3 on small screens */}
        {/* <Column lg={4} md={4} sm={4} className='footer_logo hidden-sm'>
          <img src={Image?.Blank_Logo} alt="Logo" />
        </Column>
        <Column lg={4} md={4} sm={4} className='footer_logo hidden-sm'>
          <img src={Image?.Blank_Logo} alt="Logo" />
        </Column>
        <Column lg={4} md={4} sm={4} className='footer_logo'>
          <img src={Image?.Startup_Kerala_Logo} alt="Logo" />
        </Column> */}
      </Grid>
      <Grid className='footer_grid_main'>
        <Column lg={4} md={8} sm={4} ></Column>
        <Column lg={16} md={8} sm={4}>
          <Grid className='footer_con_grid'>
            <Column lg={4} md={2} sm={4} className='footer_main_grid'>
              <p onClick={() => { navigate('/what-we-do '); handleArrowRightClick(); }} className='contact_footer'>What we do</p>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-1')}>Overview</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-2')}>Retail Transformation</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-3')}>Capabilities</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-4')}>Products</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-5')}>FAQ</Link>
            </Column>
            <Column lg={4} md={2} sm={4} className='footer_main_grid'>
              <p onClick={() => { navigate('/who-we-are '); handleArrowRightClick(); }} className='contact_footer'>Who we are </p>
              <Link to="/who-we-are" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-1')}>Overview</Link>
              <Link to="/who-we-are" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-2')}>Our growth</Link>
              <Link to="/who-we-are" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-3')}>More info</Link>
              {/* <Link to="/who-we-are" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>About us</Link> */}
              <Link to="/our-team" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Our team</Link>
            </Column>
            <Column onClick={() => { navigate('/emporia '); handleArrowRightClick(); }} lg={4} md={2} sm={4} className='footer_main_grid'>
              <p className='contact_footer'>Emporia</p>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-1')}>Overview</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-2')}>Benefits</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-3')}>Products</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-4')}>Key features</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-5')}>User stories</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-6')}>How we empower</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('list-item-7')}>Case study</Link>

            </Column>
            <Column lg={4} md={2} sm={4} className='footer_main_grid'>
              <p onClick={() => { navigate('/insights '); handleArrowRightClick(); }} className='contact_footer'>Insights</p>
              <p onClick={() => { navigate('/contact-us '); handleArrowRightClick(); }} style={{marginTop: "10px"}} className='contact_footer'>Contact us</p>
              {/* <Link to="/get-ready" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Global studies</Link> */}
              {/* <Link to="/account" style={{ display: "block", marginTop: "15px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Tpoics</Link> */}
            </Column>
          </Grid>
        </Column>
        <Column lg={12} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
          <Accordion className='footer_accordion' size='lg'>
            <AccordionItem title="What we do" color='#fff'>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "10px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Overview</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Retail Transformation</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Capabilities</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => scrollToSection('what-we-do', 'list-item-4')}>Products</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Capabilities</Link>
              <Link to="/what-we-do" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>FAQ</Link>
            </AccordionItem>
            <AccordionItem title="Who we are">
              <Link to="/who-we-are" style={{ display: "block", marginTop: "10px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Overview</Link>
              <Link to="/who-we-are" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>About us</Link>
              <Link to="/our-team" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Our team</Link>
            </AccordionItem>
            <AccordionItem title="Emporia">
              <Link to="/emporia" style={{ display: "block", marginTop: "10px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Overview</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Benefits</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Products</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Services</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>How we empower</Link>
              <Link to="/customer-stories/hilite-mall" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Case study</Link>
              <Link to="/emporia" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>User stories</Link>
              {/* <Link to="/get-ready" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Global studies</Link> */}
              {/* <Link to="/account" style={{ display: "block", marginTop: "32px", color: "#fff", textDecoration: "none" }} onClick={() => handleArrowRightClick()}>Tpoics</Link> */}
            </AccordionItem>
          </Accordion>
          
        </Column>
      </Grid>
      <Grid className='footer_accordion'>
      <Grid className='footer_insight_grid'>
      <Column lg={4} md={8} sm={4} ></Column>
        <Column lg={12} md={8} sm={4} style={{ marginTop: "20px" }}>
          <span className="footer_contact" onClick={() => {navigate("/insights"); handleArrowRightClick()}}>Insights</span>
        </Column>
        </Grid >
      <Grid className='footer_insight_grid'>
        <Column lg={4} md={8} sm={4} ></Column>
        <Column lg={12} md={8} sm={4} style={{ marginTop: "20px" }}>
          <span className="footer_contact" onClick={() => navigate("contact-us")}>Contact us</span>
        </Column>
        </Grid>
      </Grid>
      <Grid >
        <Column lg={4} md={8} sm={4} ></Column>
        <Column lg={16} md={8} sm={4} style={{ marginTop: "20px" }}>
          <span className="footer_copyright">Copyright © 2024 Proximety. All rights reserved.</span>
        </Column>
      </Grid>
    </Content>
  )
}
export default Footer;
