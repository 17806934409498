import React, { useEffect, useRef, useState } from 'react'
import { Content, Tile, Button, Grid, Column, Link, Tag, ClickableTile, Accordion, AccordionItem, Tab, Tabs, TabList, TabPanel, TabPanels, Dropdown } from 'carbon-components-react';
import { Calendar, ArrowRight, Product } from '@carbon/icons-react';
import ScrollSpy from '../../components/common/ScrollSpy';
import { Image } from '../../Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./style.scss"
import { scroller } from 'react-scroll';
import MyCarousel from '../../components/common/MyCarousel';
import { useNavigate } from 'react-router-dom';


const data = [
  {
    name: "Overview",
    link: "list-item-1"
  },
  {
    name: "Our growth",
    link: "list-item-2"
  },
  {
    name: "More info",
    link: "list-item-3"
  },
  {
    name: "Contact us",
    link: "list-item-4"
  }
  // {
  //   name: "Products",
  //   link: "list-item-5"
  // },
  // {
  //   name: "Next Steps",
  //   link: "list-item-6"
  // },
]

const ourImpactConfig = [
  // {
  //   label: "IBM Trust Center",
  //   discription: "Learn how IBM protects our clients and business with security and privacy practices",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   label: "Enterprise social good",
  //   discription: "Learn how IBM aspires to be the catalyst that makes the world work better.",
  //   icon: <ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
  // {
  //   label: "Tech for social impact",
  //   discription: "IBM brings the power of its technology, resources and people to help with initiatives around the world",
  //   icon: < ArrowRight size={20} style={{ color: "78a9ff", marginTop: "10px" }} />
  // },
]

const additionalResourcesConfig = [
  {
    label: "Our presence",
    links: ["India", "UAE"],
  },
  {
    label: "Recognitions",
    links: ["Startup India", "Kerala Startup Mission"],
    urlLink : ["https://www.startupindia.gov.in", "https://startups.startupmission.in/startups/ZjGqb"]
  },
  // {
  //   label: "Creativity at work",
  //   links: ["Explore client stories", "Design thinking with IBM Garage", "Free trials"],
  // },
  // {
  //   label: "IBM India",
  //   links: ["Annual Return Form No. MGT-7"],
  // },
]
const IconicMoments = [
  // {
  //   title1: "February 2024: Started the development of ",
  //   // disc: "With 50 billion transistors on a fingernail-sized chip—the most dense to date—this innovation holds the potential for greener data centers and safer autonomous vehicles.",
  //   imgUrl: Image?.iconic_history_2021,
  //   title: "2021"
  // },
  {
    title1: "January 2024: Onboarded 50,000 customers on Emporia.",
    // disc: "IBM Deep Blue supercomputer defeats the best chess player in the world. Thinking computers take a giant leap forward toward the kind of AI that we know and use today.",
    imgUrl: Image?.dowloads,
    title: "January 2024"
  },
  {
    title1: "September 2023: Completed the development of Proximety Emporia.",
    // disc: "In an epic feat of engineering, IBM builds the computers and software for the Apollo missions, landing Neil Armstrong and Buzz Aldrin on the moon and guiding them back to Earth.",
    imgUrl: Image?.emopriacomplete,
    title: "September 2023"
  },
  {
    title1: "August 2022: Entered in to a partnership with HiLITE Mall.",
    // disc: "Arthur L. Samuel programs an IBM 704 to play checkers and learn from its experience. It is considered the first demonstration of artificial intelligence.",
    imgUrl: Image?.partnership,
     title: "August 2022"
  },
  {
    title1: "July 2022: Proximety was founded.",
    disc: "",
    imgUrl: Image?.founded,
     title: "July 2022"
  }
]

const WhatDose = [
  {
    title1: 'Dedicated to revolutionizing retail',
    disc: 'We are committed to transforming the retail industry with innovative, technology-driven solutions that enhance both efficiency and customer experience.',
    // link: 'Learn about our insightful approach',
    imgUrl: Image?.commitment_image
  },
  {
    title1: 'Charting the future of retail technology',
    disc: 'Our roadmap is focused on continuous innovation, integrating emerging technologies to keep retailers ahead in a rapidly evolving market.',
    // link: 'Learn about our intelligent software',
    imgUrl: Image?.roadmap_image
  },
  {
    title1: 'Understanding the retail landscape',
    disc: 'We operates at the intersection of retail challenges and technological advancements, offering solutions that are both timely and timeless.',
    // link: 'Learn about IBM and Red Hat solutions',
    imgUrl: Image?.context_image
  },
  {
    title1: 'Driven by values, led by innovation',
    disc: 'We believe in the power of technology to reshape retail, prioritizing sustainability, customer-centricity, and data-driven decision-making',
    // link: 'Learn about modernizing infrastructure',
    imgUrl: Image?.belief_image
  },
  {
    title1: 'Success narratives from our journey',
    disc: 'Our stories highlight successful partnerships, showcasing how our solutions have empowered retailers to achieve new heights.',
    link: 'How a shopping mall went on to get 50,000 signups from customers in just 90 days',
    imgUrl: Image?.stories_image,
    linkHref: "/pages/hilite-mall"
  }
]
const throttle = (func, limit) => {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

const WhatWeDo = () => {
  const [activeItem, setActiveItem] = useState("list-item-1");
  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
};

  const handleDropdownChange = (e) => {
    const selectedItem = e?.selectedItem;
    setActiveItem(selectedItem?.link);

    // Scroll to the selected item's section
    if (selectedItem?.link) {
      scroller.scrollTo(selectedItem.link, {
        duration: 200,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  // const handleScroll = () => {
  //     const sectionElements = document.querySelectorAll('.scrollspy-section');
  //     let found = false;

  //     sectionElements.forEach((section) => {
  //         const rect = section.getBoundingClientRect();
  //         if (rect.top <= 50 && rect.bottom >= 50 && !found) {
  //             setActiveItem(section.id);
  //             found = true;
  //         }
  //     });
  // };

  // const throttledHandleScroll = useRef(throttle(handleScroll, 200));

  // useEffect(() => {
  //     window.addEventListener('scroll', throttledHandleScroll.current);

  //     return () => {
  //         window.removeEventListener('scroll', throttledHandleScroll.current);
  //     };
  // }, [throttledHandleScroll]);


  const handleScroll = () => {
    // Find the section that is currently in view based on the scroll position
    const scrollY = window.scrollY;

    for (const item of data) {
      const section = document.getElementById(item.link);

      if (section) {
        const rect = section.getBoundingClientRect();

        if (rect.top <= 10 && rect.bottom >= 10) {
          // Section is in view, update the dropdown value
          setActiveItem(item.link);
          break;
        }
      }
    }
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Content className='about_main'>
      <Grid fullWidth style={{ marginTop: '80px', }}>
        <Column lg={8} md={8} sm={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <h1 style={{ fontSize: '50px' }}>Who we are</h1>
          </div>
          <div>
            <p className='what_we_do_banner_des'> We are a team of innovative technologists and retail experts dedicated to revolutionizing the retail industry through advanced, customer-centric technology.</p>
            <div className='about_contant_btn' style={{ marginTop: 30 }}>
              <Button  onClick={() => navigate("/our-team")} renderIcon={(props) => <ArrowRight size={24} {...props} />} iconDescription="right">See our team</Button>
              {/* <Button className='what_we_do_exploer_btn' kind="tertiary" renderIcon={(props) => <ArrowRight size={24} {...props} />} iconDescription="ArrowRight">Explore financial information</Button> */}
            </div>
          </div>
        </Column>
        <Column lg={8} md={8} sm={4}>
          <img className='about_image' src={Image?.who_we_are_header} />
        </Column>
      </Grid>

      <Grid fullWidth className='new_grid' style={{ marginTop: "74px" }} >
        <Column lg={4} md={4} sm={4} style={{ position: 'sticky', top: '49px', height: '40vh', backgroundColor: 'transparent' }} className='artificial_sidebar'>
          <ScrollSpy data={data} activeItem={activeItem} setActiveItem={setActiveItem} />
        </Column>
        <Column lg={4} md={8} sm={4} style={{ position: 'sticky', top: '49px', backgroundColor: 'transparent' }} className='artificial_sidebar1'>
          <Dropdown
            className='artificial_sidebar1'
            id="default"
            selectedItem={data?.filter((item) => item?.link === activeItem)?.[0]}
            label={data?.filter((item) => item?.link === activeItem)?.[0]?.name}
            items={data}
            onChange={handleDropdownChange}
            itemToString={(item) => (item ? item.name : '')}
          />
        </Column>
        <Column lg={12} md={8} sm={4}
          data-spy="scroll"
          data-target="#list-example"
          data-offset="0"
          className="scrollspy-example"
          style={{ padding: '15px', height: '100%', marginLeft: "0px" }}
        >
          <Grid id="list-item-1" className="scrollspy-sectiong">
            <Column lg={14} md={8} sm={4} >
              <p className='what_we_do_our' style={{ fontWeight: 300, }}>“We transcend mere technologists; we're problem-solvers, trendsetters, and collaborators. At our core, we empower retailers with cutting-edge solutions reflective of the dynamic and forward-thinking industry we serve.”</p>
              <div style={{ marginTop: 50 }}>
                <p style={{ fontSize: "15px", fontWeight: 600, }}>Gautham Krishna</p>
                <p style={{ fontSize: "16px", fontWeight: 400, }}>COO</p>
                <p style={{ fontSize: "16px", fontWeight: 400, }}>Proximety</p>
              </div>
              <p className='line' />
            </Column>
            <Column lg={14} md={8} sm={4} >
              <div style={{ marginTop: '-40px' }}>
                <p style={{ color: "#0f62fe", fontWeight: 400, cursor: "pointer" }} onClick={() => navigate("/our-team")}> See our team <ArrowRight style={{ marginLeft: "5px" }} size={'25'} /></p>
                {/* <Link size='lg' href="/our-team" renderIcon={() => <ArrowRight size={'25'} />} >
                  See our team
                </Link> */}
              </div>
            </Column>
            <Column lg={8} md={8} sm={4} style={{ marginTop: 85 }}>
              <h1 style={{ fontSize: '50px' }}>Our vision</h1>
              <p style={{ fontSize: '22px', marginTop: '25px', fontWeight: '400', lineHeight: '2rem', color: "#161616" }}>Our vision is to redefine the retail landscape through innovative technology, integrating AI and data analytics to create personalized, efficient, and sustainable shopping experiences. Proximety envisions leading the industry towards a future where technology seamlessly empowers retailers and delights consumers.</p>
            </Column>
            <Column lg={8} md={8} sm={4} style={{ marginTop: 35 }}>
              <img className='about_image' src={Image?.vision_image} />
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "60px" }} className='use_case_tab'>
              <Tabs>
                <TabList aria-label="List of tabs" >
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Our commitment</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Our roadmap</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Our context</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Our beliefs</div></Tab>
                  <Tab ><div style={{ width: '130px', marginBottom: '5px', fontSize: '16px' }}>Our stories</div></Tab>
                </TabList>
                <TabPanels>
                  {WhatDose?.map((u, index) => {
                    return (
                      <TabPanel key={index}>
                        <div className='about_overview_tab_common'>
                          <UseCasesCom
                            title1={u?.title1}
                            disc={u?.disc}
                            link={u?.link}
                            imgUrl={u?.imgUrl}
                          />
                        </div>
                      </TabPanel>
                    )
                  })}
                </TabPanels>
              </Tabs>

            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "60px", padding: "0px" }} className='use_cases_accordion'>
              <Accordion>
                {WhatDose?.map((u) => {
                  return (
                    < AccordionItem title={u?.title1}>
                      <UseCasesCom
                        title1={u?.title1}
                        disc={u?.disc}
                        link={u?.link}
                        imgUrl={u?.imgUrl}
                      />
                    </AccordionItem>
                  )
                })}
              </Accordion>
            </Column>
          </Grid>
          {/* <Grid id="list-item-2" className="scrollspy-sectiong">
            <Column lg={15} md={8} sm={4} style={{ marginTop: '60px', marginBottom: "60px" }}>
              <h1 style={{ fontSize: '50px' }}>Our impact</h1>
              <Grid>
                {ourImpactConfig?.map((item, index) => {
                  return (
                    <Column lg={5} md={4} sm={4} style={{ marginTop: "60px" }}>
                      <ClickableTile style={{ height: "250px" }} className='our_impact_card'>
                        <div>
                          <p style={{ fontSize: '20px', fontWeight: 400 }}>{item?.label}</p>
                        </div>
                        <div >
                          <p style={{ fontSize: "14px" }}>{item?.discription}</p>
                          <Link size='lg' href="#" style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
                        </div>
                      </ClickableTile>
                    </Column>
                  )
                })}
              </Grid>
            </Column>
          </Grid> */}
          <Grid id="list-item-2" className="scrollspy-sectiong">
            <Column lg={8} md={8} sm={4} style={{ marginTop: '60px' }}>
              <h1 style={{ fontSize: '52px' }}>Our growth</h1>
            </Column>
            <Column lg={16} md={8} sm={4} className='use_case_tab' style={{ marginTop: '30px' }}>
              <Tabs>
                <TabList aria-label="List of tabs" style={{ width: "100%" }}>
                  {/* <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>February 2024</div></Tab> */}
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>January 2024</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>September 2023</div></Tab>
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>August 2022</div></Tab>
                  {/* <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>1952</div></Tab> */}
                  <Tab><div style={{ width: '120px', marginBottom: '2px', fontSize: '16px' }}>July 2022</div></Tab>
                </TabList>
                <TabPanels>
                  {
                    IconicMoments?.map((u, index) => {
                      return (
                        <TabPanel key={index}>
                          <div className='about_overview_tab_common'>
                            <UseCasesCom
                              title1={u?.title1}
                              disc={u?.disc}
                              imgUrl={u?.imgUrl}
                            />
                          </div>
                        </TabPanel>
                      )
                    })
                  }
                </TabPanels>
              </Tabs>
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "60px" }} className='use_cases_accordion'>
              <Accordion>
                {
                  IconicMoments?.map((u) => {
                    return (
                      <AccordionItem title={u?.title}>
                        <UseCasesCom
                          title1={u?.title1}
                          disc={u?.disc}
                          imgUrl={u?.imgUrl}
                        />
                      </AccordionItem>
                    )
                  })
                }
              </Accordion>
            </Column>
          </Grid>
          {/* <Grid id="list-item-4" className="scrollspy-sectiong">
            <Column lg={7} md={8} sm={4} style={{ marginTop: '60px' }}>
              <h1 style={{ fontSize: '55px' }}>Frequently asked questions</h1>
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "30px" }} className='what_we_do_accordion'>
              <Accordion>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>What does IBM do today?</div>}>
                  <p style={{ fontSize: '16px' }}>IBM integrates technology and expertise, providing <a href="https://www.ibm.com/in-en/it-infrastructure">infrastructure</a>, <a href="https://www.ibm.com/in-en/software">software</a> (including market-leading <a href="https://www.ibm.com/in-en/cloud/redhat">Red Hat</a>) and <a href="https://www.ibm.com/in-en/consulting/multicloud">consulting services</a> for clients as they pursue the digital transformation of the world’s mission-critical businesses.</p><br />
                </AccordionItem>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>Does IBM have goals for sustainability?</div>}>
                  <p style={{ fontSize: '16px' }}>In 2021, we furthered our tradition of leadership in sustainability, announcing a goal of net-zero greenhouse gas emissions by 2030 across all the countries in which we operate. Our data-driven <a href="https://www.ibm.com/in-en/sustainability">sustainability solutions</a> are helping clients turn ambition into action.</p><br /><br />
                </AccordionItem>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>What is the future for IBM?</div>}>
                  <p style={{ fontSize: '16px' }}>IBM is always on the hunt for what’s next. Learn more about the group of more than 3,000 scientists and researchers around the globe who deeply believe in the power of the scientific method to invent at <a href="https://research.ibm.com">IBM Research</a>. For example, we are leading the charge in <a href="https://research.ibm.com/blog/ibm-quantum-roadmap-2025">quantum computing</a>.</p><br />
                </AccordionItem>
                <AccordionItem title={<div style={{ fontSize: '16px' }}>What is IBM’s view of the future of work?</div>}>
                  <p style={{ fontSize: '16px' }}>One area of interest for IBM is in personal digital employees, or digeys, AI-powered workers who can relieve employees of their most repetitive, mundane tasks. Learn more about <a href="https://www.ibm.com/in-en/products/watsonx-orchestrate" >Watson Orchestrate</a>. We believe intelligent automation solutions will help businesses improve workflows, integrate systems and gain insights into operations. Learn more at <a href="https://www.ibm.com/in-en/automation">IBM Automation</a>.</p><br />
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid> */}
          <Grid id="list-item-3" className="scrollspy-sectiong">
            <Column lg={8} md={8} sm={4} style={{ marginTop: '45px' }}>
              <h1 style={{ fontSize: '55px' }}>More info</h1>
            </Column>
            <Column lg={15} md={8} sm={4} style={{ marginTop: "5px" }}>
              <Grid>
                {additionalResourcesConfig?.map((item, index) => {
                  return (
                    <Column lg={5} md={4} sm={4} style={{ marginTop: "60px" }} key={index}>
                      <div>
                        <p style={{ fontSize: '16px', fontWeight: 600 }}>{item?.label}</p>
                      </div>
                      {item?.links?.map((link, i) => {
                        const urlLink = item?.urlLink?.[i]
                        return <div> <Link key={i} size='lg' href={urlLink} style={{ marginTop: "1px", color: "#0062fe" }} >{link}</Link></div>
                      })}
                    </Column>
                  )
                })}
              </Grid>
            </Column>
          </Grid>
          <Grid id="list-item-4" className="scrollspy-section next_Steps" style={{ marginTop: "100px" }} >
            <Column lg={9} md={8} sm={4} style={{ marginTop: "60px" }}>
              <p className='benifits' style={{ fontSize: "40px", lineHeight: "55px" }}>Get in touch</p>
              <p className="text-mono" dir="auto"
                style={{ marginTop: "30px" }}
              >Contact us to learn more about how our products can benefit your business.</p>
              <div className='get_start_new_btn'>
                <Button href="/contact-us" renderIcon={(props) => <ArrowRight size={40} {...props} />} iconDescription="ArrowRight" >Contact us</Button>
              </div>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

const UseCasesCom = ({ title1, disc, link, imgUrl }) => {
  return (
    <>
      <div className='tab_about'>
        <img src={imgUrl} />
      </div>
      <div className='tab_about_box'>
        <p className="text-mono" dir="auto" style={{ fontWeight: 400, fontSize: "25px", }}>{title1}</p>
        <p className="text-mono" dir="auto" style={{ fontWeight: 400, fontSize: "16px", marginTop: "20px" }}>
          {disc}
        </p>
        {link && <Link size='lg' href="#" renderIcon={() => <ArrowRight size={'25'} />} style={{ fontWeight: 400, fontSize: "16px", marginTop: "30px" }}>
          {link}
        </Link>}
      </div>
    </>
  )
}

export default WhatWeDo