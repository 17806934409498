import { Content, Grid, Column, Link, Accordion, AccordionItem, Button } from 'carbon-components-react';
import { Image } from '../../Image';
import { LogoLinkedin, LogoTwitter, Add, Subtract } from '@carbon/icons-react';
import "./style.scss"
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


// const profileNews = [
//   { title: "Forbes: What is Marketing?" },
//   { title: " Edge:  “Our innovations are guided by our foundational principles of trust and ethics,” says IBM exec" },
//   { title: "The Relevance Report 2024: Welcome to AI" },
// ]

const mainGrid ={
  name : "Mohammed Basith",
  role:"Head of Design",
  nameRole : "Basith is the Head of Design at Proximety",
  description1:"Basith, an imaginative designer and entrepreneur, is one of the co-founders and currently serves as the Head of Design at Proximety. With a relentless passion for innovation and a keen eye for aesthetic detail, Basith has played a pivotal role in shaping Proximety's brand identity and product design philosophy.",
  description2:"Prior to co-founding Proximety, Basith honed his skills and expertise at prominent tech firms, including a tenure as the Senior Product Designer at Pickyourtrail, where he played a crucial role in enhancing user experiences and driving product innovation. His creative contributions and strategic insights significantly contributed to Pickyourtrail's growth and market positioning.",
  description3:"Throughout his career, Basith has demonstrated a relentless commitment to pushing boundaries and challenging the status quo in design and technology. His innate ability to anticipate user needs and his relentless pursuit of excellence have cemented his reputation as a leader and innovator in the field of design.",
  // description4:"He brings more than 25 years of experience across domestic and global teams serving different sectors, including technology, automotive and manufacturing, government, retail, finance, energy, B2B and B2C. He also brings governmental and political experience with him, having worked in the Clinton Administration White House and Treasury Department as well as on multiple U.S. presidential campaigns.",
  // description5:"A graduate of  of TKM College of Engineering- Kollam, Gautham earned a Bachelor of Technology degree in Electrical & Electronics Engineering.",
  // description6:"Jonathan is an avid music and Wisconsin sports fan. He lives in Westchester County, NY and in his personal time, you can find him spending time with his wife, their two kids and dog."

}

const Basith = () => {

  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  const [isShow, setIsShow] = useState(false)

  return (
    <Content className='profile_main'>
      <Grid>
        <Column lg={4} md={4} sm={4}>
          <div className='bio_img'>
            <img src={Image?.basith} />
          </div>
        </Column>
        <Column lg={12} md={8} sm={4} >
          <p style={{ fontSize: "40px", color: "#161616", fontWeight: 300 }}>{mainGrid.name}</p>
          <p style={{ padding: "18px 0px", color: "#161616", fontSize: "24px", fontWeight: 300 }}>{mainGrid.role}</p>
          <div style={{ paddingBottom: "15px" }}>
            <LogoLinkedin size={'40'}/>
            {/* <LogoTwitter size={'40'} style={{ marginLeft: "10px" }} /> */}
          </div>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.nameRole}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description1}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description2}</p>

          {isShow ?
            <div>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description3}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description4}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description5}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description6}</p>
            </div>
            : null}
          <Link onClick={() => setIsShow(!isShow)} style={{ color: "#0f62fe", fontWeight: 700, fontSize: "18px", cursor: "pointer" }} renderIcon={() => isShow ? <Subtract size={'25'} /> : <Add size={'25'} />}>{isShow ? "Read less" : "Read more"}</Link>
          {/* <Accordion style={{ marginTop: "30px" }} size='lg' className='accordion_main'>
            <AccordionItem title="News" open
            >
              <Grid >
                {
                  profileNews?.map((u, index) => {
                    return (
                      <Column key={index} lg={4} md={8} sm={4} className='accordion_column'>
                        <Link className='bio_accordion_con'>
                          {u?.title}
                        </Link>
                      </Column>
                    )
                  })
                }
              </Grid>
            </AccordionItem>
          </Accordion> */}
          <Grid style={{ marginTop: "30px" }}>
            <Column lg={12} md={8} sm={4}>
              <Button onClick={() => { navigate("/our-team"); handleArrowRightClick(); }} style={{ marginBottom: "48px" }}>
                Back to Leadership
              </Button>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

export default Basith