export const Image = {

    home_banner: 'https://assets.proximety.io/website/home_banner.png',
    logo: 'https://assets.proximety.io/website/logo.png',
    logo_white: 'https://assets.proximety.io/website/logo_white.png',
    Proximety_Logo: 'https://assets.proximety.io/website/Proximety-Logo.png',
    Proximety_Logo_Hover:  'https://assets.proximety.io/website/Proximety-Logo-Blue.png',
    Startup_India_Logo:  'https://assets.proximety.io/website/startupindia_proximety_white1.png',
    Startup_Kerala_Logo:  'https://assets.proximety.io/website/ksum_proximety_white1.png',
    Blank_Logo:  'https://assets.proximety.io/website/Blank_Logo_White.png',

    Proximety_Logo_White: 'https://assets.proximety.io/website/Proximety_Logo_White.png',
    artificial_intelligence_banner: 'https://assets.proximety.io/website/emporia_mobile.jpg',
    hilite_mobile: 'https://assets.proximety.io/website/hilite_mobile.jpg',
    humana_banner: 'https://assets.proximety.io/website/humana.jpg',
    about_header: 'https://assets.proximety.io/website/about_header.jpg',
    about_overview: 'https://assets.proximety.io/website/about_overview.png',
    about_counsulting: 'https://assets.proximety.io/website/about_counsulting.png',
    about_software: 'https://assets.proximety.io/website/about_software.png',
    about_infrastructure: 'https://assets.proximety.io/website/about_infrastructure.png',
    about_redhat: 'https://assets.proximety.io/website/about_redhat.png',
    about_ecosystem: 'https://assets.proximety.io/website/about_ecosystem.png',
    iconic_history_1997: 'https://assets.proximety.io/website/iconic_history_1997.jpg',
    iconic_history_1969: 'https://assets.proximety.io/website/iconic_history_1969.jpg',
    iconic_history_1956: 'https://assets.proximety.io/website/iconic_history_1956.jpg',
    iconic_history_1952: 'https://assets.proximety.io/website/iconic_history_1952.jpg',
    iconic_history_1928: 'https://assets.proximety.io/website/iconic_history_1928.jpg',
    iconic_history_2021: 'https://assets.proximety.io/website/iconic_history_2021.jpg',
    study_com: 'https://assets.proximety.io/website/study_lep.png',
    study_mob: 'https://assets.proximety.io/website/study_mob.png',
    use_case_1: 'https://assets.proximety.io/website/use_case_1.jpeg',
    use_case_2: 'https://assets.proximety.io/website/use_case_2.jpeg',
    use_case_3: 'https://assets.proximety.io/website/use_case_3.jpeg',
    leader1: 'https://assets.proximety.io/website/leader1.jpeg',
    leader2: 'https://assets.proximety.io/website/leader2.jpeg',
    leader3: 'https://assets.proximety.io/website/leader3.jpeg',
    leader4: 'https://assets.proximety.io/website/leader4.png',
    leader5: 'https://assets.proximety.io/website/leader5.jpeg',
    leader6: 'https://assets.proximety.io/website/leader6.png',
    leader7: 'https://assets.proximety.io/website/leader7.jpeg',
    leader8: 'https://assets.proximety.io/website/leader8.png',
    leader9: 'https://assets.proximety.io/website/leader9.jpeg',
    leader10: 'https://assets.proximety.io/website/leader10.jpeg',
    leader11: 'https://assets.proximety.io/website/leader11.jpeg',
    leader12: 'https://assets.proximety.io/website/leader12.jpeg',
    leader13: 'https://assets.proximety.io/website/leader13.jpeg',
    leader14: 'https://assets.proximety.io/website/leader14.jpeg',
    leader15: 'https://assets.proximety.io/website/leader15.jpeg',
    leader16: 'https://assets.proximety.io/website/leader16.jpeg',
    leader17: 'https://assets.proximety.io/website/leader17.jpeg',
    leader18: 'https://assets.proximety.io/website/leader18.jpeg',
    leader19: 'https://assets.proximety.io/website/leader19.jpeg',
    leader20: 'https://assets.proximety.io/website/leader20.jpeg',

    featured_banner1: 'https://assets.proximety.io/website/featured_banner1.jpeg',
    featured_banner2: 'https://assets.proximety.io/website/featured_banner2.png',
    featured_banner3: 'https://assets.proximety.io/website/featured_banner3.jpeg',
    featured_banner4: 'https://assets.proximety.io/website/featured_banner4.jpeg',
    featured_banner7: 'https://assets.proximety.io/website/featured_banner7.png',
    featured_banner8: 'https://assets.proximety.io/website/featured_banner8.png',

    get_ready_banner: 'https://assets.proximety.io/website/get_ready_banner.jpeg',
    get_ready_secound: 'https://assets.proximety.io/website/get_ready_secound.png',
    get_ready_thourd: 'https://assets.proximety.io/website/get_ready_thourd.png',
    get_ready_user: 'https://assets.proximety.io/website/get_ready_user.png',
    leader: 'https://assets.proximety.io/website/leader.jpg',



    blog_1_main: 'https://assets.proximety.io/website/blog_1.jpeg',
    blog_2_main: 'https://assets.proximety.io/website/blog_2.jpeg',
    blog_3_main: 'https://assets.proximety.io/website/blog_3.jpeg',
    blog_4_main: 'https://assets.proximety.io/website/blog_4.jpeg',
    blog_5_main: 'https://assets.proximety.io/website/blog_5.jpeg',
    blog_6_main: 'https://assets.proximety.io/website/blog_6.jpeg',
    latest_news_2: 'https://assets.proximety.io/website/latest_news_2.jpeg',
    latest_news_3: 'https://assets.proximety.io/website/latest_news_3.jpeg',
    latest_news_4: 'https://assets.proximety.io/website/latest_news_4.png',
    latest_news_5: 'https://assets.proximety.io/website/latest_news_5.jpeg',
    latest_news_6: 'https://assets.proximety.io/website/latest_news_6.jpeg',

    more_news_1: 'https://assets.proximety.io/website/more_news_1.png',
    more_news_2: 'https://assets.proximety.io/website/more_news_2.png',
    more_news_3: 'https://assets.proximety.io/website/more_news_3.jpeg',
    more_news_4: 'https://assets.proximety.io/website/more_news_4.png',
    more_news_6: 'https://assets.proximety.io/website/more_news_6.png',
    more_news_5: 'https://assets.proximety.io/website/more_news_5.jpeg',

    pre_tools: 'https://assets.proximety.io/website/pre_tools.png',
    bio_img: 'https://assets.proximety.io/website/bio_img.jpeg',
    varsha: 'https://assets.proximety.io/website/varsha.jpeg',
    sunder: 'https://assets.proximety.io/website/sunder.jpeg',
    // bhumika: 'https://assets.proximety.io/website/bhumika.jpeg',
    anasooya: 'https://assets.proximety.io/website/anasooya.jpeg',
    shysav: 'https://assets.proximety.io/website/shysav.jpeg',
    basith: 'https://assets.proximety.io/website/basith.jpg',
  

    maximo_overview: 'https://assets.proximety.io/website/maximo_overview.png',
    main_recomand_card: 'https://assets.proximety.io/website/main_recomand_card.png',
    staff_img: 'https://assets.proximety.io/website/staff_img.jpeg',
    staff_img2: 'https://assets.proximety.io/website/teamv2.png',
    youtube: 'https://assets.proximety.io/website/youtube.svg',
    twitter_icon: 'https://assets.proximety.io/website/twitter-icon.svg',

    emporia_image: 'https://assets.proximety.io/website/emporia_image.png',
    emporia_image2: 'https://assets.proximety.io/website/emporia_image2.png',
    emporia_image2_mobile: 'https://assets.proximety.io/website/emporia_image2_mobile.png',
    everything_app_video:   'https://assets.proximety.io/website/everything_app.mp4',
    
   

    commitment_image: 'https://assets.proximety.io/website/commitment_image.png',
    roadmap_image: 'https://assets.proximety.io/website/roadmap_image.png',
    context_image: 'https://assets.proximety.io/website/context_image.png',
     belief_image: 'https://assets.proximety.io/website/belief_image.png',
    stories_image: 'https://assets.proximety.io/website/stories_image.png',

    product_emporia: 'https://assets.proximety.io/website/product_emporia.png',
    // product_product2: 'https://assets.proximety.io/website/product_product2.png',

    who_we_are_header: 'https://assets.proximety.io/website/who_we_are_header.png',
    vision_image: 'https://assets.proximety.io/website/vision_image.png',

    founded: 'https://assets.proximety.io/website/founded.png',
    partnership: 'https://assets.proximety.io/website/partnership.png',
    emopriacomplete: 'https://assets.proximety.io/website/emopriacomplete.png',
     dowloads: 'https://assets.proximety.io/website/dowloads.png',
   
     everything_app:  require("../asset/everything.png"),
     partner_app:  require("../asset/partnerapp.png"),
     admin_console:  require("../asset/admin_console.png"),
     shop_app:  require("../asset/emporia_shop.png"),
   
    

    
}