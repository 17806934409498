import { Content, Grid, Column, Link, Accordion, AccordionItem, Button } from 'carbon-components-react';
import { Image } from '../../Image';
import { LogoLinkedin, LogoTwitter, Add, Subtract } from '@carbon/icons-react';
import "./style.scss"
import { useState } from 'react';


const profileNews = [
  { title: "Forbes: What is Marketing?" },
  { title: " Edge:  “Our innovations are guided by our foundational principles of trust and ethics,” says IBM exec" },
  { title: "The Relevance Report 2024: Welcome to AI" },
]

const mainGrid ={
  name : "Gautham Krishna B",
  role:"Chief Operating Officer",
  nameRole : "Gautham Krishna is Proximety's co-founder and Chief Operating Officer",
  description1:"As a co-founder of Proximety, Gautham has been instrumental in shaping the company's vision and strategy from its inception. With a keen eye for innovation and a passion for excellence, he has consistently driven Proximety towards achieving its goals and milestones.",
  description2:"Gautham's leadership extends beyond mere vision, as he has led cross-functional teams through the end-to-end design, development, and implementation of Proximety Emporia. By championing Agile methodologies, he has streamlined project management, fostering accelerated timelines, enhanced team collaboration, and consistently on-time, on-budget project deliveries. Moreover, his unwavering commitment to quality assurance has ensured that Proximety maintains the highest standards of product delivery, earning widespread acclaim for customer satisfaction.",
  description3:"Before joining Proximety, Gautham garnered extensive experience as a Software Engineer at Kimball Electronics (India) Pvt. Ltd. where he played a pivotal role in designing, developing, and integrating automated testing solutions for flagship products of a Fortune 5 company. His expertise extended globally as he collaborated with clients from the US, Japan, China, and Korea, showcasing his adaptability and cross-cultural communication skills.",
  // description4:"He brings more than 25 years of experience across domestic and global teams serving different sectors, including technology, automotive and manufacturing, government, retail, finance, energy, B2B and B2C. He also brings governmental and political experience with him, having worked in the Clinton Administration White House and Treasury Department as well as on multiple U.S. presidential campaigns.",
  description5:"A graduate of  of TKM College of Engineering- Kollam, Gautham earned a Bachelor of Technology degree in Electrical & Electronics Engineering.",
  // description6:"Jonathan is an avid music and Wisconsin sports fan. He lives in Westchester County, NY and in his personal time, you can find him spending time with his wife, their two kids and dog."

}

const Profile = () => {

  const [isShow, setIsShow] = useState(false)

  return (
    <Content className='profile_main'>
      <Grid>
        <Column lg={4} md={4} sm={4}>
          <div className='bio_img'>
            <img src={Image?.bio_img} />
          </div>
        </Column>
        <Column lg={12} md={8} sm={4} >
          <p style={{ fontSize: "40px", color: "#161616", fontWeight: 300 }}>{mainGrid.name}</p>
          <p style={{ padding: "18px 0px", color: "#161616", fontSize: "24px", fontWeight: 300 }}>{mainGrid.role}</p>
          <div style={{ paddingBottom: "15px" }}>
            <LogoLinkedin size={'40'}/>
            {/* <LogoTwitter size={'40'} style={{ marginLeft: "10px" }} /> */}
          </div>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.nameRole}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description1}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description2}</p>

          {isShow ?
            <div>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description3}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description4}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description5}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description6}</p>
            </div>
            : null}
          <Link onClick={() => setIsShow(!isShow)} style={{ color: "#0f62fe", fontWeight: 700, fontSize: "18px", cursor: "pointer" }} renderIcon={() => isShow ? <Subtract size={'25'} /> : <Add size={'25'} />}>Read more</Link>
          {/* <Accordion style={{ marginTop: "30px" }} size='lg' className='accordion_main'>
            <AccordionItem title="News" open
            >
              <Grid >
                {
                  profileNews?.map((u, index) => {
                    return (
                      <Column key={index} lg={4} md={8} sm={4} className='accordion_column'>
                        <Link className='bio_accordion_con'>
                          {u?.title}
                        </Link>
                      </Column>
                    )
                  })
                }
              </Grid>
            </AccordionItem>
          </Accordion> */}
          <Grid style={{ marginTop: "30px" }}>
            <Column lg={12} md={8} sm={4}>
              <Button style={{ marginBottom: "48px" }}>
                Back to Leadership
              </Button>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

export default Profile