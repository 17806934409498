import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const HeaderWeb = ({ data, onClickSubHeaderOpen, title }) => {
    const navigate = useNavigate();

    const handleRedirect = (item) => {
        navigate(item);
    };

    const scrollToSection = (page, sectionId) => {
        navigate(page); // Navigate to the specified page
        // Wait for the navigation to complete, then scroll to the specified section
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000); // Adjust the timeout as needed
      };

    return (
        <div className='HeaderSupport' onMouseEnter={() => onClickSubHeaderOpen(title)} onMouseLeave={() => onClickSubHeaderOpen("")}>
            {data?.map((item, index) => {
                return (
                    <h4 key={index}  onClick={() => scrollToSection(item?.link,item?.gridId)}>
                        {item?.label} <span style={{ marginLeft: "4px" }}></span>
                    </h4>
                );
            })}
        </div>
    );
};

export const HeaderMobile = ({ closeMenu,data, title,link, backClick }) => {
    const navigate = useNavigate();

    const scrollToSection = (page, sectionId) => {
        navigate(page); // Navigate to the specified page
        // Wait for the navigation to complete, then scroll to the specified section
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000); // Adjust the timeout as needed
      };

    const handleRedirect = (item) => {
        navigate(item);
    };
    return (
        <div className='HeaderSupportMobile'>
            <h4 className='backButton mobileMenu' style={{ fontWeight: 300 }} onClick={backClick}><span style={{ marginRight: "12px" }}><ArrowLeft size={20} /></span> Back</h4>
            <h4 className='titleHeader mobileMenu' onClick={() => {handleRedirect(link);closeMenu();}}>{title}</h4>
            {data?.map((item) => {
                return (
                    <div className='mobileMenu' onClick={() => {scrollToSection(item?.link,item?.gridId);closeMenu();}}>
                        <h4>{item?.label} </h4><span style={{ marginLeft: "4px" }}></span>
                    </div>
                )
            })}
        </div>
    )
}