import React, { useState } from 'react';
import {
  Content,
  Grid,
  Column,
  Button,
  GridSettings,
  Heading,
  Section
} from 'carbon-components-react';
import { Printer, Email, Wifi, DocumentPdf } from '@carbon/icons-react';
import "./style.scss";
import { Image } from '../../../Image';

const Blog5 = () => {
  const content = {
    section: {
      level: 1,
      heading: "Navigating Retail's New Reality: Reinventing Customer Engagement Post-Pandemic",
      date: "Jan 27, 2023",
      bannerImage: Image?.featured_banner8,
    },
    mainGrid: {
      paragraphs: [
        "In the wake of the COVID-19 crisis, the retail sector has encountered unprecedented challenges, leading to a transformative shift in both consumer habits and the industry's operational models. This evolution calls for a strategic rethinking of retail practices, particularly in customer experience and digital integration. Here's a reimagined overview of the key strategies for retail adaptation:",
        "Digital Transformation as a Priority",
        "The pandemic has accelerated the transition to digital platforms. Retailers must enhance their online presence, focusing on user-friendly interfaces and seamless digital experiences. This includes optimizing website speed, ensuring mobile responsiveness, and leveraging social media for customer engagement.",
        "Revolutionizing Omnichannel Retailing",
        "Adapting to new consumer preferences is crucial. Retailers should explore innovative omnichannel solutions, such as virtual try-ons or augmented reality experiences, to merge the in-store experience with digital convenience.",
        "Redefining Store Operations for Safety and Efficiency",
        "The concept of 'SafeX' – safe shopping experiences – is now vital. Retailers need to implement measures ensuring customer and staff safety, like contactless payments and enhanced sanitation protocols, while optimizing operational efficiency.",
        "Reassessing the Role of Physical Stores",
        "The surge in e-commerce necessitates a reevaluation of the physical store network. Retailers should consider downsizing or repurposing their physical locations, potentially transforming them into experience centers or fulfillment hubs.",
        "Adopting Agile Methodologies",
        "Agility in business operations is more important than ever. Retailers should use real-time data analytics to understand shifting consumer trends and rapidly adapt their strategies accordingly.",
        "Conclusion",
        "In conclusion, the retail industry must embrace these strategic pivots to stay relevant and thrive in the post-pandemic landscape. By focusing on digital innovation, omnichannel integration, operational safety, and agility, retailers can build stronger connections with their customers and navigate the new normal successfully."
      ]
    }
  };

  const [isSideNavExpanded, setSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <>
      <Content className='get_ready_cases'>
        <Section level={1}>
          <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
            {content.section.heading}
          </Heading>
          <p style={{ marginTop: 18 }}>{content.section.date}</p>
          <img src={Image?.featured_banner8} style={{ height: "100%", width: "100%", marginTop: 20 }} />
        </Section>
        <Grid fullWidth className='main_grid' >
          <Column lg={11} md={8} sm={4} className="first_grid">
            <GridSettings subgrid={false}>
              <Grid>
                <Column lg={1} md={1} sm={4} className="first_grid first_a_grid">
                  {/* Social media icons and other buttons */}
                  {/* <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#161616", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com", "_blank")}></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.example.com/print", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.example.com/wifi", "_blank")}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div> */}
                </Column>
                <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                  {/* Content Section */}
                  {content.mainGrid.paragraphs.map((paragraph, index) => (
                    <p key={index} style={{ marginTop: index === 0 ? "20px" : "10px", fontWeight: index % 2 === 1 ? "bold" : "normal" }}>
                      {paragraph}
                    </p>
                  ))}
                </Column>
              </Grid>
            </GridSettings>
          </Column>
          <Column lg={5} md={4} sm={4} className="second_grid_one">
            {/* Subscribe button */}
            {/* <Button kind="tertiary"
              onClick={() => window.open("https://www.example.com/subscribe", "_blank")}
            >
              Subscribe to email
            </Button> */}
          </Column>
        </Grid>
      </Content>
    </>
  );
};

export default Blog5;
