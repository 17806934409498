import { Content, TextInput, Select, SelectItem, RadioButtonGroup, RadioButton, TextArea, Link, FormGroup, Checkbox, Button } from 'carbon-components-react';
import "./style.scss"
import { useEffect, useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { Tile, Grid, Column, Tag, ClickableTile, OverflowMenu, Accordion, OverflowMenuItem, AccordionItem, Tab, Tabs, TabList, TabPanel, TabPanels, Dropdown } from 'carbon-components-react';
import { Card } from 'react-bootstrap';

export const DownloadBrochure = () => {


  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const domain = email.split('@')[1];
    return regex.test(email) && domain !== 'gmail.com' && domain !== 'mail.com' && domain !== 'yahoo.com';
  }
  const [userDetail, setUserDetail] = useState({
    email: "",
    firstName: "",
    lastName: "",
    country: "India",
    state: "Gujarat",
    helpText: "",
    emailCheck: false,
  })

  console.log('userDetail', userDetail)

  const [error, setError] = useState(false)
  const [emailCheck, setEmailCheck] = useState(false)
  const [country, setCountry] = useState([])
  const [state, setState] = useState([])
  const [countryIso2, setCountryIso2] = useState("IN")
  const apiKey = "aEtJd3cwV2tYamRwbnhZeDZXUW8zSEZXYW5QTW0wNnFOMHpuRFdZOQ=="
  const fieldsetCheckboxProps = () => ({
    className: 'some-class',
    legendText: ''
  });

  useEffect(() => {
    var config = {
      method: 'get',
      url: 'https://api.countrystatecity.in/v1/countries',
      headers: {
        'X-CSCAPI-KEY': apiKey
      }
    };

    axios(config).then((response) => setCountry(response?.data)).catch((error) => {
      console.log(error);
    });

  }, [])

  useEffect(() => {
    if (countryIso2) {
      var config = {
        method: 'get',
        url: `https://api.countrystatecity.in/v1/countries/${countryIso2}/states`,
        headers: {
          'X-CSCAPI-KEY': apiKey
        }
      };

      axios(config)
        .then((response) => {
          setState(response?.data)
        }).catch((error) => {
          console.log("error", error);
        });
    }
  }, [countryIso2])

  const onHandleChange = (e) => {
    const { name, value } = e.target
    if (name == "country") {
      setCountryIso2(country?.find((c) => c?.name == value)?.iso2)
    }
    if (name === "emailCheck") {
      setUserDetail({ ...userDetail, [name]: e.target.checked })
    }
    setUserDetail({ ...userDetail, [name]: value })
  }
  const submitForm = () => {
    if ((userDetail?.email && !validateEmail(userDetail?.email) || !userDetail?.email) || !userDetail?.firstName || !userDetail?.country || !userDetail?.helpText || !userDetail?.emailCheck || !userDetail?.phone) {
      setError(true)
    } else {
      sendMessage(userDetail)
    }

  }

  const handelClear = () => {
    setUserDetail({
      email: "",
      firstName: "",
      lastName: "",
      country: "India",
      state: "Gujarat",
      helpText: "",
      phone: null,

    })
    setError(false)
  }
  const sendMessage = async (userDetail) => {
    try {
      const serviceId = 'service_3kqnmsa';
      const templateId = 'template_kyid0ws';
      const publickey = '3T0cei_c7Fr0E4Usx';
      const templateId2 = 'template_13evlvu';

      const messageParams = {
        userFirstName: userDetail?.firstName,
        userLastName: userDetail?.lastName,
        userEmail: userDetail?.email,
        userCountry: userDetail?.country,
        userState: userDetail?.state,
        userEnquiry: userDetail?.helpText,
        userPhone: userDetail?.phone,
        formPuropse: "Emporia Brochure Form"
      };
      await emailjs.send(serviceId, templateId, messageParams, publickey,);
      await emailjs.send(serviceId, templateId2, messageParams, publickey,);
      toast.success("Details submitted successfully. You will receive the Emporia brochure on your Email shortly.")
      handelClear()
    } catch (error) {
      toast.error(`Failed to submit the form:${error}`)
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [])

  return (

    <Content className='signup'>

      <div className='signup_card'>
        {/* <h3 className='signup_card_headin'>Office Address</h3>

      <p style={{ height: "1px", backgroundColor: "#e0e0e0", width: "100%", marginTop: "10px" }}></p>
      <br />
     <p style={{color: "#5c5c5c"}} > Proximety Platforms Pvt. Ltd. <br />
        FM 16/45, East Nallur <br />
        Feroke, Calicut, Kerala <br />
        India - 673631 <br />
        </p> */}
        <h3 className='signup_card_headin' style={{ marginTop: "30px" }} >Download Emporia brochure</h3>
        <p style={{ height: "1px", backgroundColor: "#e0e0e0", width: "100%", marginTop: "10px" }}></p>
        <div style={{ marginTop: "20px" }}>
          <div style={{ marginTop: "30px" }} >
            <TextInput
              labelText="Work Email (You will recevie the brochure on this Email)"
              size='lg'
              name='email'
              value={userDetail?.email}
              invalid={error && !userDetail?.email ? true : (userDetail?.email && !validateEmail(userDetail?.email)) ? true : false}
              invalidText={error && !userDetail?.email ? "Email is required" : (userDetail?.email && !validateEmail(userDetail?.email)) ? "Please enter your work email" : ""}
              onChange={(e) => onHandleChange(e)}
            />
            <div className='name_con'>
              <div className='user_name'>
                <TextInput
                  labelText="First name"
                  size='lg'
                  name='firstName'
                  value={userDetail?.firstName}
                  invalid={error && !userDetail?.firstName}
                  onChange={(e) => onHandleChange(e)}
                  invalidText="First name is required"
                />

              </div>
              <div className='user_name'>
                <TextInput
                  labelText="Last name"
                  size='lg'
                  name='lastName'
                  value={userDetail.lastName}
                  onChange={(e) => onHandleChange(e)}
                  invalid={error && !userDetail?.lastName}
                  invalidText="Last name is required"
                />
              </div>
            </div>
            <div className='name_con'>
              <div className='user_name'>
                <Select
                  className='some-class'
                  labelText='Country or region of residence'
                  id="select-1"
                  defaultValue="India"
                  size='lg'
                  name='country'
                  value={userDetail?.country}
                  onChange={(e) => onHandleChange(e)}
                >
                  {
                    country?.map((e) => {
                      return (
                        <SelectItem key={e.id} value={e?.name} text={e?.name} />
                      )
                    })
                  }
                </Select>

              </div>
              <div className='user_name'>
                <Select
                  className='some-class'
                  labelText='State or province'
                  id="select-1"
                  defaultValue="India"
                  size='lg'
                  name='state'
                  value={userDetail?.state}
                  onChange={(e) => onHandleChange(e)}
                >
                  {
                    state?.map((e) => {
                      return (
                        <SelectItem key={e.id} value={e?.name} text={e?.name} />
                      )
                    })
                  }
                </Select>
              </div>
            </div>
            <div className='user_name'>
              <TextInput
                labelText="Phone"
                size='lg'
                name='phone'
                type='number'
                value={userDetail?.phone}
                invalid={error && !userDetail?.phone}
                onChange={(e) => onHandleChange(e)}
                invalidText="Phone is required"
                pattern="\d*"
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <TextArea
                labelText='Please share which feature you are most interested learning more about'
                invalid={error && !userDetail?.helpText}
                value={userDetail?.helpText}
                name='helpText'
                invalidText="Field is required"
                onChange={(e) => onHandleChange(e)}
              />
            </div>
            <div>

              <p style={{ marginTop: "30px", color: "#171717", fontSize: "14px", fontWeight: 500 }}>
                <FormGroup>
                  <Checkbox name="emailCheck" checked={userDetail?.emailCheck} onChange={(e) => onHandleChange(e)} invalid={error && !userDetail?.emailCheck} invalidText="Please check email option " labelText="I’d like Proximety to use my contact details to keep me informed about products, services, and offers." id="checkbox-0" />
                </FormGroup>  </p>
            </div>
            <div style={{ marginTop: "30px" }}>
            </div>
            <div style={{ marginTop: "30px" }}>
              <Button onClick={() => submitForm()} kind="primary" style={{ paddingRight: "160px" }}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* <div  style={{ marginTop: "30px" }} className='contact_address_cards'>
        <Card className='contact_ind_card' style={{ height: "100%", padding: "15px" }}>
          <div>
            <p style={{ marginTop: "15px", color: "#161616", fontSize: "22px" }}>India</p>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", height: "10%" }}>
            <p style={{ marginTop: "10px" }}>Proximety Platforms Pvt. Ltd. <br />
              FM 16/45, East Nallur <br />
              Calicut, India - 673631 <br />
               </p>
          </div>
        </Card>
        <Card className='contact_uae_card' style={{ height: "100%", padding: "15px" }}>
          <div>
            <p style={{ marginTop: "15px", color: "#161616", fontSize: "22px" }}>UAE</p>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", height: "10%" }}>
            <p style={{ marginTop: "10px" }}>
           
              Office 6, Building 14 <br />
              Street 28A, Al Quoz 1, <br />
              Dubai, UAE <br /></p>
          </div>
        </Card>
      </div> */}
    </Content >
  )
}