import React, { useState } from 'react';
import {
 Content,
 Grid,
 Column,
 Button,
 Section,
 Heading,
 GridSettings,
} from 'carbon-components-react';
import { Printer, Wifi, Email, DocumentPdf } from '@carbon/icons-react';
import "./style.scss";
import { Image } from '../../../Image'; // Ensure image paths are correct


const Blog4 = () => {
 const [isSideNavExpanded, setSideNavExpanded] = useState(false);


 const content = {
   section: {
     level: 1,
     heading: "Navigating the New Retail Landscape: The Rise of Omnichannel Mastery",
     date: "Jan 28, 2024",
     bannerImage: Image?.featured_banner3, // Replace with actual banner image
   },
   mainGrid: {
    //  author: "John Doe",
     contentBlocks: [
       {
         type: "paragraph",
         content: "In the evolving retail sector, the concept of omnichannel retailing is emerging as a game-changer, particularly for traditional retailers who once dominated the market as category leaders. This new era in retail is marked by a seamless integration of online and offline channels, offering vast opportunities for those who adeptly manage this synergy."
       },
       {
         type: "paragraph",
         content: "Historically, the retail landscape was revolutionized by 'category killers' – large-scale retailers specializing in a broad range of products within a specific category. These giants, like Home Depot and Best Buy, reshaped retail by offering unmatched selection, pricing, and convenience. However, today's retail environment is being reshaped yet again, this time by the rise of omnichannel retailing, which poses a significant challenge to traditional retailers from digital-native, online-only stores."
       },
       {
         type: "paragraph",
         content: "Despite these challenges, there's a growing belief that omnichannel retailers in Europe and North America are on the cusp of a renaissance. The key lies in understanding the interplay between online and offline channels and leveraging them to create a competitive edge."
       },
       {
         type: "subheader",
         content: "The Emergence of Omnichannel Category Killers"
       },
       {
         type: "paragraph",
         content: "A decade and a half ago, e-commerce was in its nascent stages, with consumers primarily driven by price while shopping online. However, recent trends indicate a shift in consumer expectations. Today, customers are increasingly seeking expertise in specific categories and a superior customer experience, alongside the traditional drivers of price and convenience. This shift presents a significant opportunity for traditional brick-and-mortar retailers to reinvent themselves as omnichannel category killers, blending the best of both online and offline worlds to differentiate from pure-digital players like Amazon."
       },
       {
         type: "subheader",
         content: "The Reality of Omnichannel Integration"
       },
       {
         type: "paragraph",
         content: "The COVID-19 pandemic accelerated the adoption of online shopping, with e-commerce penetration in the United States growing at a pace previously projected to take a decade. Despite this surge, many retailers have yet to fully embrace an online-led, omnichannel model. The lack of transparency about the benefits of online business to offline channels and vice versa is a significant barrier. The true revenue performance of a retailer's online channel can often be significantly understated if the influence on offline sales is not accounted for."
       },
       {
         type: "subheader",
         content: "Embracing the Store in Omnichannel Integration"
       },
       {
         type: "paragraph",
         content: "True omnichannel integration doesn't mean prioritizing online over offline; rather, it involves placing the online channel at the core of consumer interactions. With a majority of consumers making retail decisions online, the impact of the online channel on offline sales is substantial. Retailers need to adopt a cross-channel perspective, measuring and managing the interplay between online and offline channels."
       },
       {
         type: "subheader",
         content: "The Need for an 'Online-First' Mindset"
       },
       {
         type: "paragraph",
         content: "Successful omnichannel retailing requires rethinking not just online operations but also brick-and-mortar strategies. The overall perception of a retailer's omnichannel proposition is significantly influenced by its brick-and-mortar operations. Retailers must expand beyond direct online-only levers and adopt a truly omnichannel strategy encompassing marketing, pricing, assortment, and in-store experience."
       },
       {
         type: "subheader",
         content: "Becoming a True Omnichannel Winner"
       },
       {
         type: "paragraph",
         content: "Retailers that have successfully transformed into omnichannel enterprises serve as a testament to the potential of this approach. Companies like Best Buy, Nike, and Nordstrom have demonstrated significant growth and resilience by embracing omnichannel strategies. To capture the full potential of omnichannel, retailers must invest boldly in technology and digital capabilities, adopt an online-first go-to-market strategy, embrace the store as a critical element of omnichannel, and fully leverage the unique advantages of brick-and-mortar operations."
       },
       {
         type: "paragraph",
         content: "In conclusion, the future of retail lies in the effective integration of online and offline channels. Retailers who adopt an online-first mindset and strategically manage their omnichannel presence are poised to dominate the industry, potentially emerging as the new breed of omnichannel category killers."
       }
     ]
   },
   // ... Other sections as in the original structure
 };


 const renderContentBlock = (block, index) => {
   if (block.type === "subheader") {
     return <p key={index} style={{ fontWeight: 'bold', marginTop: '20px' }}>{block.content}</p>;
   }
   return <p key={index} style={{ marginTop: index === 0 ? '20px' : '0' }}>{block.content}</p>;
 };


 const onClickSideNavExpand = () => {
   setSideNavExpanded(!isSideNavExpanded);
 };


 return (
   <>
     <Content className='get_ready_cases'>
       <Section level={content.section.level}>
         <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
           {content.section.heading}
         </Heading>
         <p style={{ marginTop: 18 }}>{content.section.date}</p>
         <img src={content.section.bannerImage} alt="Banner" style={{ height: "100%", width: "100%", marginTop: 20 }} />
       </Section>
       <Grid fullWidth className='main_grid'>
         <Column lg={11} md={8} sm={4} className="first_grid">
           <GridSettings subgrid={false}>
             <Grid>
               <Column lg={1} md={1} sm={4} className="first_grid first_a_grid">
                
                    {/* <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#161616", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com", "_blank")}></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.printerlink.com", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.wifi.com", "_blank")}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div> */}
                
                 {/* ... */}
               </Column>
               <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                 <span style={{ fontSize: "16px", fontWeight: "600", fontStyle: "italic" }}>{content.mainGrid.author}</span>
                 {content.mainGrid.contentBlocks.map(renderContentBlock)}
                 {/* Additional content elements as in original component */}
               </Column>
             </Grid>
           </GridSettings>
         </Column>
         {/* Additional columns and sections as per original layout */}
       </Grid>
     </Content>
   </>
 )
}


export default Blog4;



