import { Content, Grid, Column, Link, Accordion, AccordionItem, Button } from 'carbon-components-react';
import { Image } from '../../Image';
import { LogoLinkedin, LogoTwitter, Add, Subtract } from '@carbon/icons-react';
import "./style.scss"
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


const profileNews = [
  { title: "Forbes: What is Marketing?" },
  { title: " Edge:  “Our innovations are guided by our foundational principles of trust and ethics,” says IBM exec" },
  { title: "The Relevance Report 2024: Welcome to AI" },
]

const mainGrid = {
  name: "Sunderjith",
  role: "Chief Executive Officer, Proximety",
  nameRole: "Sunderjith is the co-founder and the CEO of Proximety",
  description1: "Sunderjith, a dynamic entrepreneur and innovator, embarked on his journey in the world of business while pursuing his MBA at the age of 27. With a keen eye for innovation and a drive for excellence, he co-founded Proximety, a groundbreaking force in organized retail.",
  description2: "Drawing from his academic prowess, Sunderjith holds an MBA from IIM Amritsar, where he cultivated a strategic mindset and business acumen. His foundational experience at Envestnet Inc. equipped him with the necessary skills to develop world-class digital platforms.",
  description3: "At Proximety, Sunderjith spearheaded the end-to-end product development lifecycle of Proximety Emporia, a pioneering phygital platform tailored for shopping malls. Demonstrating his proficiency and dedication, he successfully shipped the beta version in less than 5 months, showcasing his ability to deliver results with agility and precision.",
  description4: "Prior to his entrepreneurial venture, Sunderjith served as a Senior Product Development Engineer at Envestnet, Inc. Here, he led a team of four individuals in the re-architecture initiative of Envestnet’s Proposal Generation module, resulting in a remarkable 30% improvement in performance. Additionally, Sunderjith played a pivotal role in ideating and implementing insurance exchange functionalities for the UMP Platform, fostering collaboration with partner firms across diverse geographic locations. His adept management of four teams led to the creation of significant business value within a mere three months.",
  description5: "Sunderjith's entrepreneurial spirit, coupled with his invaluable expertise in product development and strategic leadership, continues to drive Proximety towards new heights of success in the realm of organized retail. With a relentless commitment to innovation and excellence, he remains at the forefront of revolutionizing the retail landscape, shaping the future of commerce with Proximety.",
  // description6: "Jonathan is an avid music and Wisconsin sports fan. He lives in Westchester County, NY and in his personal time, you can find him spending time with his wife, their two kids and dog."

}
const Sunderjith = () => {
  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  const [isShow, setIsShow] = useState(false)
  return (
    <Content className='profile_main'>
      <Grid>
        <Column lg={4} md={4} sm={4}>
          <div className='bio_img'>
            <img src={Image?.sunder} />
          </div>
        </Column>
        <Column lg={12} md={8} sm={4} >
          <p style={{ fontSize: "40px", color: "#161616", fontWeight: 300 }}>{mainGrid.name}</p>
          <p style={{ padding: "18px 0px", color: "#161616", fontSize: "24px", fontWeight: 300 }}>{mainGrid.role}</p>
          <div style={{ paddingBottom: "15px" }}>
            <LogoLinkedin style={{ cursor: "pointer" }} size={'40'} onClick={() => window.open("https://www.linkedin.com/in/sunderjith/", "_blanck")} />
            {/* <LogoTwitter size={'40'} style={{ marginLeft: "10px" }} /> */}
          </div>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.nameRole}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description1}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description2}</p>

          {isShow ?
            <div>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description3}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description4}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description5}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description6}</p>
            </div>
            : null}
          <Link onClick={() => setIsShow(!isShow)} style={{ color: "#0f62fe", fontWeight: 700, fontSize: "18px", cursor: "pointer" }} renderIcon={() => isShow ? <Subtract size={'25'} /> : <Add size={'25'} />}>{isShow ? "Read less" : "Read more"}</Link>
          {/* <Accordion style={{ marginTop: "30px" }} size='lg' className='accordion_main'>
            <AccordionItem title="News" open
            >
              <Grid >
                {
                  profileNews?.map((u, index) => {
                    return (
                      <Column key={index} lg={4} md={8} sm={4} className='accordion_column'>
                        <Link className='bio_accordion_con'>
                          {u?.title}
                        </Link>
                      </Column>
                    )
                  })
                }
              </Grid>
            </AccordionItem>
          </Accordion> */}
          <Grid style={{ marginTop: "30px" }}>
            <Column lg={12} md={8} sm={4}>
              <Button onClick={() => { navigate("/our-team"); handleArrowRightClick(); }} style={{ marginBottom: "48px" }}>
                Back to Leadership
              </Button>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

export default Sunderjith