import React, { useState } from 'react';
import {
  Content,
  Grid,
  Column,
  Button,
  GridSettings,
  Heading,
  Section
} from 'carbon-components-react';
import { Printer, Email, Wifi, DocumentPdf } from '@carbon/icons-react';
import "./style.scss";
import { Image } from '../../../Image';

const Blog6 = () => {
  const content = {
    section: {
      level: 1,
      heading: "Revolutionizing Retail with Data Analytics: A New Era of Customer Insight and Excellence",
      date: "Jan 27, 2023",
      bannerImage: Image?.featured_banner4,
    },
    mainGrid: {
      paragraphs: [
        "In today's retail landscape, the abundance of customer data presents a unique opportunity for retailers to significantly enhance their operations and customer engagement strategies. By leveraging advanced analytics, retailers can transform vast data into actionable insights, leading to unparalleled retail excellence.",
        { subheader: "Empowering Retail Decisions with Data-Driven Insights" },
        "Retailers are inundated with data from direct customer interactions. This data, if harnessed correctly, can be a powerful tool for creating distinct frontline advantages. Expertise in handling diverse data sources and extracting pertinent insights is crucial in forming a solid foundation for informed business decisions across all retail processes.",
        { subheader: "Key Areas of Focus" },
        "Our approach to retail analytics encompasses several critical areas:",
        "Merchandising with Insight: Utilizing transactional and loyalty data, we assist retailers in making data-driven decisions about pricing, promotions, and product assortment.",
        "Marketing Powered by Analytics: We guide clients in optimizing their marketing strategies through advanced analytics, focusing on customer lifecycle management and marketing mix modeling.",
        "Operational Excellence through Data: Retailers are supported in identifying and capitalizing on improvement opportunities throughout their supply chain, from sourcing to in-store product availability.",
        // { subheader: "Global Expertise Network" },
        // "Our network of experts, part of the Consumer and Marketing Analytics Center (CMAC), spans globally. This network, with regional hubs worldwide, has successfully implemented CMAC methodologies in various retail sectors, including DIY, apparel, and grocery. These implementations cover a range of topics from category management to pricing strategies.",
        // { subheader: "Innovative Analytical Tools" },
        // "Our toolkit, such as the Insights Factory, is designed to generate new insights from existing and third-party data. This toolkit supports decision-making and business performance monitoring. It includes tools like a Localization Scorecard for assortment tailoring and a Pricing Module for optimal pricing strategies based on comprehensive data analysis.",
        // "Additionally, we utilize specific tools like the CMAC Heatmap and a five-step improvement program. These tools enable retailers to assess their performance across various data intersections and guide them from product-centric to shopper-centric approaches.",
        { subheader: "Customized Solutions for Retail Challenges" },
        "We continuously develop and refine our tools in collaboration with clients, ensuring tailor-made solutions that address new business capabilities, data sources, and challenges. Our modular analytical approach to assortment optimization equips retailers to enhance their margins, reduce costs, increase sales, and improve customer satisfaction.",
        "In summary, the integration of big data and advanced analytics in retail is not just a trend but a necessity for staying competitive. By embracing these technologies, retailers can unlock the full potential of their data, leading to smarter business decisions, improved customer experiences, and overall retail excellence."
      ]
    }
  };

  const [isSideNavExpanded, setSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <>
      <Content className='get_ready_cases'>
        <Section level={1}>
          <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
            {content.section.heading}
          </Heading>
          <p style={{ marginTop: 18 }}>{content.section.date}</p>
          <img src={Image?.featured_banner4} style={{ height: "100%", width: "100%", marginTop: 20 }} />
        </Section>
        <Grid fullWidth className='main_grid' >
          <Column lg={11} md={8} sm={4} className="first_grid">
            <GridSettings subgrid={false}>
              <Grid>
                <Column lg={1} md={1} sm={4} className="first_grid first_a_grid">
                  {/* Social media icons and other buttons */}
                  {/* <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#161616", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com", "_blank")}></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.example.com/print", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.example.com/wifi", "_blank")}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div> */}
                </Column>
                <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                  {/* Content Section */}
                  {content.mainGrid.paragraphs.map((item, index) => (
                    typeof item === 'string' ? 
                      <p key={index} style={{ marginTop: index === 0 ? "20px" : "10px" }}>
                        {item}
                      </p> :
                      <h4 key={index} style={{ marginTop: "20px", fontWeight: "bold" }}>
                        {item.subheader}
                      </h4>
                  ))}
                </Column>
              </Grid>
            </GridSettings>
          </Column>
          <Column lg={5} md={4} sm={4} className="second_grid_one">
            {/* Subscribe button */}
            {/* <Button kind="tertiary"
              onClick={() => window.open("https://www.example.com/subscribe", "_blank")}
            >
              Subscribe to email
            </Button> */}
          </Column>
        </Grid>
      </Content>
    </>
  );
};

export default Blog6;
