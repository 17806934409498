import React, { useEffect, useRef, useState } from 'react'
import { Content, Tile, Button, Grid, Column, Link, Tag, ClickableTile, Accordion, AccordionItem, Tab, Tabs, TabList, TabPanel, TabPanels } from 'carbon-components-react';
import { Calendar, ArrowRight, Product } from '@carbon/icons-react';
import { Theme } from '@carbon/react';
import "./style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Image } from '../../Image';
import ScrollSpy from '../../components/common/ScrollSpy';
import { useNavigate } from 'react-router-dom';

const data = [
    {
        name: "Overview",
        link: "list-item-1"
    },
    {
        name: "About HiLITE Mall",
        link: "list-item-2"
    },
    {
        name: "Onboarding tenants",
        link: "list-item-3"
    },
    {
        name: "Launch and download campaign",
        link: "list-item-4"
    },
    {
        name: "Take the next step",
        link: "list-item-5"
    },
]

const throttle = (func, limit) => {
    let inThrottle;
    return function (...args) {
        if (!inThrottle) {
            func(...args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;
            }, limit);
        }
    };
};

const HiliteMall = () => {

    const navigate = useNavigate()

    const handleArrowRightClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    };
    const [activeItem, setActiveItem] = useState("list-item-1");

    const handleScroll = () => {
        const sectionElements = document.querySelectorAll('.scrollspy-section');
        let found = false;

        sectionElements.forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.top <= 50 && rect.bottom >= 50 && !found) {
                setActiveItem(section.id);
                found = true;
            }
        });
    };

    const throttledHandleScroll = useRef(throttle(handleScroll, 200));

    useEffect(() => {
        window.addEventListener('scroll', throttledHandleScroll.current);

        return () => {
            window.removeEventListener('scroll', throttledHandleScroll.current);
        };
    }, [throttledHandleScroll]);

    return (
        <>
            <Content className='humana_main'>
                <div className='humana_main_banner'>
                    <div className='humana_main_contant'>
                        <h1 className='main_title'>HiLITE Mall x Emporia</h1>
                        <div className='humana_contant_inner'>
                            <p>How a shopping mall app got 50,000 sign ups in 3 months. </p>
                        </div>
                    </div>
                </div>
                <div className='mobile_img'>
                    <img className='mobile_img' src={Image?.hilite_mobile} />
                </div>

                <Grid fullWidth className='hilite_mall_con_grid' style={{ marginTop: "30px" }} >
                    <Column lg={4} md={4} sm={4} style={{ position: 'sticky', top: '49px', height: '50vh', backgroundColor: 'transparent' }} className='humana_sidebar'>
                        <ScrollSpy data={data} activeItem={activeItem} setActiveItem={setActiveItem} />
                    </Column>
                    <Column lg={12} md={8} sm={4}
                        data-spy="scroll"
                        data-target="#list-example"
                        data-offset="0"
                        className="scrollspy-example"
                        style={{ width: '100%', padding: '16px', height: '100%', marginLeft: "0px" }}
                    >

                        {/* Overview Section */}

                        <Grid id="list-item-1" className="scrollspy-section">
                            <Column lg={7} md={8} sm={4} style={{ margin: "0px", padding: "16px" }}>
                                {/* <p className='overview' style={{ fontSize: "60px", fontWeight: 300 }}>Overview</p> */}
                                <h4 style={{ marginTop: "20px", fontSize: "22px", fontWeight: 300 }}>HiLITE Mall was the very first mall in India to launch with Emporia and wanted to set an example for other malls that were yet to launch. Three months after the launch it is safe to say that they did just that.
                                </h4>
                                <p className='results' style={{ marginTop: "30px", fontSize: "30px", fontWeight: 300 }}>Results at a glance – in just 90 days</p>
                                <p style={{ marginTop: "10px" }}>
                                    It only took the mall 90 days to create outstanding results. This is what was achieved just three month after launching: <br /><br />
                                    <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>50,000+</span> customers signed up. <br />
                                    <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>10,000+</span> search hits by the customers to discover the offering in the mall. <br />
                                    <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>4000+</span> scratch card vouchers redeemed by customers. <br />
                                    <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>2000+</span> customers used the smart parking feature.<br />
                                    <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>10000+</span> invoices were uploaded by customers to earn reward points.
                                </p>
                            </Column>
                            <Column lg={15} md={8} sm={4} >
                                <p className='line'></p>
                            </Column>
                            <Column lg={7} md={8} sm={4} style={{ margin: "0px", padding: "16px" }}>
                                <p className='results' style={{ marginTop: "0px", fontSize: "30px", fontWeight: 300 }}>The everything app for HiLITE Mall powered by Emporia</p>

                                <video controls style={{ width: '100%', marginTop: '50px' }}>
                                    <source src={Image?.everything_app_video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </Column>
                            <Column lg={15} md={8} sm={4} >
                                <p className='line'></p>

                                <p className='what_we_do_our1' style={{ fontWeight: 300, }}>“Emporia has helped us set up a first-of-its-kind online platform for shopping malls, and the results have been amazing.”</p>
                                <div style={{ marginTop: 50 }}>
                                    <p style={{ fontSize: "15px", fontWeight: 600, }}>Thanveer UM</p>
                                    <p style={{ fontSize: "16px", fontWeight: 400, }}>Head of Marketing</p>
                                    <p style={{ fontSize: "16px", fontWeight: 400, }}>HiLITE Mall</p>
                                </div>
                                <p className='line'></p>
                            </Column>
                        </Grid>
                        {/* Challenges with legacy systems */}
                        <Grid id="list-item-2" className="scrollspy-section">
                            <Column lg={7} md={8} sm={4} style={{ marginTop: "0px" }}>
                                <p className='overview' style={{ fontSize: "55px", fontWeight: 300, lineHeight: '4rem' }}>About HiLITE Mall</p>
                                <p style={{ marginTop: "40px" }}>HiLITE Mall is located in the Kozhikode district of Kerala, India. The mall covers a total built-up area of 1,260,000 sq ft (117,000 m2) with 810,000 sq ft (75,000 m2) of retail space and houses 200+ brand outlets. The mall was launched in 2015. Despite being one of the largest shopping malls in the country, they had little presence in the digital domain.Their previous mobile app only served as a static store directory with roughly 300 installed audience left by the start 2023.
                                </p>
                                <p style={{ marginTop: "30px" }}>This is when HiLITE Mall decided to undergo digital transformation with Proximety Emporia. Emporia is designed to elevate the omnichannel experience at shopping malls by transforming them into dynamic, technologically advanced spaces seamlessly blending physical and digital retail experiences.

                                </p>
                            </Column>
                            {/* <Column lg={15} md={8} sm={4} >
                                <p className='line'></p>
                                <p className='overview' style={{ fontSize: "40px", fontWeight: 300, lineHeight: '4rem', paddingBottom: "15px" }}>“This is not the type of technology that you set up and walk away. It needs monitoring, training and supervising to harness the true insights that can be brought forth.”</p>
                            </Column> */}
                        </Grid>
                        <p className='line'></p>
                        {/* Getting healthcare professionals the information they need, when they need it */}
                        <Grid id="list-item-3" className="scrollspy-section">
                            <Column lg={7} md={8} sm={4} >
                                <h1 className='overview' style={{ fontSize: '50px', paddingTop: "0px" }}>Onboarding tenants</h1>
                                <p style={{ marginTop: "30px" }}>Following the implementation of a dynamic omnichannel solution with Emporia, the mall initiated preparations for its launch:
                                </p>
                                <p style={{ marginTop: "30px" }}>A crucial step prior to launching the customer-facing mall app involved integrating all shops and restaurants within the mall onto the platform. These tenants play a vital role in driving customer engagement and interaction with the platform. The first step was to onboard tenants through the Emporia Admin Console, facilitating the integration of all mall stores onto the platform. Subsequently, the onboarded restaurants gained access to the Emporia Restaurant App to digitize their menus and manage orders placed by customers through the mall app. Thanks to collaborative efforts between HiLITE Mall and Proximety, all shops and restaurants were successfully integrated within a two-week period, ensuring that the app was primed for customer launch.

                                </p>
                                <p style={{ marginTop: "30px" }}>Within 30 days post-launch, nearly 80% of tenants were actively contributing content through the platform to enhance customer engagement.
                                </p>
                            </Column>
                            <Column lg={2} md={8} sm={4} >
                            </Column>
                            {/* <Column lg={3} md={8} sm={4} style={{ marginTop: '180px' }}>
                                <ClickableTile style={{ height: "auto" }}>
                                    <div style={{ display: 'flex', textAlign: 'left', flexDirection: 'column', gap: 10 }}>
                                    <p>Discover how Watson Assistant’s new features are empowering businesses to provide best-in-class customer service.</p>
                                        <Link size='lg' href="#" style={{ marginTop: "25px" }} renderIcon={() => <ArrowRight size={'25'} />} >
                                        Learn more
                                        </Link>
                                    </div>
                                </ClickableTile>
                            </Column> */}
                            <Column lg={16} md={8} sm={4} >
                                <p className='line'></p>
                                <p className='what_we_do_our1' style={{ fontWeight: 300, }}>At Proximety, we recommend mall-run internal competitions to boost desired KPIs. Whether it's increasing downloads, monthly active users, or mall visits, we offer tools and well documented strategies in our knowledge base.
                                </p>
                            </Column>
                        </Grid>
                        <p className='line'></p>
                        <Grid id="list-item-4" className="scrollspy-section">
                            <Column lg={7} md={8} sm={4} >
                                <h1 className='overview' style={{ fontSize: '50px', paddingTop: "0px" }}>Launch and download  campaign</h1>
                                <p style={{ marginTop: "30px" }}>Emporia was launched in HiLITE Mall in September 2023 with a wide array of features for the mall's customers. Now instead of a static mall directory a live breathing version of the mall was available to the customers online.

                                </p>
                                <p style={{ marginTop: "30px" }}>Upon launch, the mall had planned a download campaign to convert as many customers to app users as quickly as possible. This campaign was powered by Emporia’s built in marketing features. During the campaign the mall used Emporia’s referral code based program to incentivise new users with scratch cards, parking vouchers, and other rewards. App KPI available on the mall’s admin console helped them measure the effectiveness of the marketing efforts and app performance and thereby optimize the campaigns. Various promotions were created linking physical and online interactions.
                                    The app was also promoted across social platforms, leveraging Emporia's built-in support for integration with Meta and Google ad campaigns.
                                </p>
                                <h1 className='overview' style={{ fontSize: '50px', paddingTop: "64px" }}>Results</h1>

                                <p style={{ marginTop: "30px" }}>As a result of their download-campaign, HiLITE Mall reached amazing results in just 90 days. Here’s what they accomplished by the end of the 90 days:

                                </p>

                                <p style={{ marginTop: "30px" }}>50,000+ users signed up through the customer app. <br />
                                    4000+ scratch card vouchers redeemed by customers. <br />
                                    10000+ invoices were uploaded by customers to earn reward points. <br />
                                    500+ prizes were redeemed from the loyalty program. Prizes are redeemed with points earned by visiting the mall, so more redemptions are expected as more customers will earn more points from more visits. <br />
                                    2000+ customers used the smart parking feature to locate their parked vehicle using the app’s indoor navigation. <br />
                                    352 tickets were sold directly through the platform to the Events at the mall.
                                </p>
                                <p style={{ marginTop: "30px" }}>The mall was not the only ones benefiting from their new mall app. The stores also saw some results on their own:
                                </p>
                                <p style={{ marginTop: "30px" }}>10000+ users searched for various stores and offering in the mall through the app.<br />
                                    7000+ search hits in the shopping module.<br />
                                    2500+ search hits in the offers module.<br />
                                    500+ search hits in the food court module.
                                </p>

                            </Column>
                            <Column lg={2} md={8} sm={4} >
                            </Column>
                            {/* <Column lg={3} md={8} sm={4} style={{ marginTop: '180px' }}>
                                <ClickableTile style={{ height: "auto" }}>
                                    <div style={{ display: 'flex', textAlign: 'left', flexDirection: 'column', gap: 10 }}>
                                        <p>See why Watson Assistant has been named the leader in Forrester’s New Wave report on Conversational Computing.</p>
                                        <Link size='lg' href="#" style={{ marginTop: "25px" }} renderIcon={() => <ArrowRight size={'25'} />} >
                                            Watson AI for Customer Service
                                        </Link>
                                    </div>
                                </ClickableTile>
                            </Column> */}

                        </Grid>
                        <p className='line'></p>
                        <Grid id="list-item-8" className="scrollspy-section get_start" >
                            <Column lg={9} md={8} sm={4} style={{ marginTop: "60px" }}>
                                <p className='benifits' style={{ fontSize: "40px", lineHeight: "55px" }}>Get started quickly with a client demo for Emporia</p>
                                <p className="text-mono" dir="auto"
                                    style={{ marginTop: "30px" }}
                                >Contact us to learn more about how Emporia can benefit your shopping mall.

                                </p>
                                <div className='get_start_new_btn'>
                                    <Button onClick={() => { navigate("/contact-us"); handleArrowRightClick(); }} renderIcon={(props) => <ArrowRight size={40} {...props} />} iconDescription="ArrowRight" >Contact us</Button>
                                    {/* <Button renderIcon={(props) => <ArrowRight size={40} {...props} />} kind="tertiary" iconDescription="ArrowRight" className='get_reg'>Get regular AI updates</Button> */}
                                </div>
                            </Column>
                        </Grid>


                    </Column>
                </Grid >

            </Content >

        </>
    )
}

const UseCasesCom = ({ title1, title2, dis, link, imgUrl }) => {
    return (
        <>
            <div>
                <p
                    className="text-mono"
                    dir="auto"
                    style={{ fontWeight: 300, fontSize: "14px", }}
                >{title1}</p>
                <p
                    className="text-mono"
                    dir="auto"
                    style={{ fontWeight: 500, fontSize: "20px", marginTop: "10px" }}
                >
                    {title2}
                </p>
                <p
                    className="text-mono"
                    dir="auto"
                    style={{ fontWeight: 400, fontSize: "16px", marginTop: "10px" }}
                >
                    {dis}
                </p>
                <Link
                    className="text-mono"
                    dir="auto"
                    style={{ fontWeight: 400, fontSize: "16px", marginTop: "10px", cursor: 'pointer' }}
                >
                    {link}
                    <ArrowRight style={{ marginLeft: "15px" }} />
                </Link>
            </div>
            <div className='tab_img'>
                <img src={imgUrl} />
            </div>
        </>
    )
}

export default HiliteMall