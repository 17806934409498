/* global dataLayer */

import { useEffect } from 'react';

function GoogleAnalytics() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZRRB64RHRT';
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-ZRRB64RHRT');
  }, []);

  return null; // This component doesn't render anything
}

export default GoogleAnalytics;
