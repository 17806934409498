import { Grid, Column, Link, ClickableTile, Button } from 'carbon-components-react';
import { ArrowRight, BeeBat, CloudRegistry, IbmProcessMining } from '@carbon/icons-react';
import "./style.scss"
import { Image } from '../../Image';
import youtube from "../../asset/youtube.svg"
import twitter_icon from "../../asset/twitter-icon.svg"
import MyCarousel from '../../components/common/MyCarousel';
import { useNavigate } from 'react-router-dom';

const latestNews = [
  {
    "titel": "Blog",
    "description": " Revolutionizing Retail: Embracing a New Era of Technology Integration",
    "image": Image?.blog_1_main,
    "blogLink": "/blog/revolutionizing-retail-embracing-a-new-era-of-technology-integration"
  },
  {
    "titel": "Blog",
    "description": "The Transforming Landscape of Malls: Navigating Retail Trends in 2024",
    "image": Image?.blog_2_main,
    "blogLink": "/blog/the-transforming-landscape-of-malls-navigating-retail-trends-in-2024"
  },
  {
    "titel": "Blog",
    "description": "Crafting Unique Shopping Journeys: The Power of Retail Personalization",
    "image": Image?.blog_3_main,
    "blogLink": "/blog/crafting-unique-shopping-journeys-the-power-of-retail-personalization"
  },
  {
    "titel": "Blog",
    "description": "Navigating the New Retail Landscape: The Rise of Omnichannel Mastery ",
    "image": Image?.blog_4_main,
    "blogLink": "/blog/navigating-the-new-retail-landscape-the-rise-of-mmnichannel-mastery"
  },
  {
    "titel": "Blog",
    "description": "Navigating Retail's New Reality: Reinventing Customer Engagement Post-Pandemic",
    "image": Image?.blog_5_main,
    "blogLink": "/blog/navigating-retails-new-reality-reinventing-customer-engagement-post-pandemic"
  },
  {
    "titel": "Blog",
    "description": "Revolutionizing Retail with Data Analytics: A New Era of Customer Insight and Excellence",
    "image": Image?.blog_6_main,
    "blogLink": "/blog/revolutionizing-retail-with-data-analytics-a-new-era-of-customer-insight-and-excellence"
  }
]

const moreNews = [
  // {
  //   "lable": "IBM Awards",
  //   "image": Image?.more_news_1
  // },
  // {
  //   "lable": "IBM Technology",
  //   "image": Image?.more_news_2
  // },
  // {
  //   "lable": "Smart Talks with IBM",
  //   "image": Image?.more_news_3
  // },
  // {
  //   "lable": "What is data fabric",
  //   "image": Image?.more_news_4
  // },
  // {
  //   "lable": "The Mayflower Autonomous Ship Project",
  //   "image": Image?.more_news_5
  // },
  // {
  //   "lable": "IBM Unveils New Roadmap to Practical Quantum Computing Era; Plans to Deliver 4,000+ Qubit System",
  //   "image": Image?.more_news_6
  // },
]

const Insights = () => {
  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  return (
    <div className='case_study_main'>
      <Grid>
        <Column lg={8} md={4} sm={4} style={{ margin: "24px 16px" }}>
          <p style={{ fontSize: "40px", fontWeight: "300" }}>Insights</p>
        </Column>
        <Column lg={8} md={4} sm={4} style={{ margin: "24px 16px" }}>
          {/* <div className='icon_img'>
            <img src={twitter_icon} />
            <img src={youtube} />
          </div> */}
        </Column>
        <Column lg={16} md={8} sm={4}>
          <MyCarousel />
        </Column>
        <Column lg={4} md={8} sm={4} style={{ marginTop: "100px" }}>
          <p style={{ fontSize: "32px" }}>Read the blogs</p>
        </Column>
        <Column lg={12} md={8} sm={4} className='insights_main_con'>
          <Grid>
            {latestNews?.map((item, index) => {
              return (
                <Column onClick={() => { navigate(item.blogLink); handleArrowRightClick(); }}lg={4} md={4} sm={4} style={{ marginTop: "20px" }} key={index}>
                  <ClickableTile id="clickable-tile-1" className="overview-grid featured_card">
                    <div className='featured_card_img'> <img src={item?.image} /></div>
                    <div className='featured_card_con'>
                      <p>{item?.titel}</p>
                      <Link style={{ fontSize: "20px", marginTop: "10px", height: "150px", textDecoration: "none" }}>{item?.description}</Link>
                      <Link size='lg' style={{ marginTop: "20px" }} renderIcon={() => <ArrowRight size={'25'} />} />
                    </div>
                  </ClickableTile>
                </Column>
              )
            })}
          </Grid>
        </Column>
      </Grid>
      <Grid style={{ marginTop: "30px", textAlign: "right" }}>
        {/* <Column lg={16} md={8} sm={4}>
          <Button kind="tertiary" renderIcon={ArrowRight}>View All </Button>
        </Column> */}
      </Grid>
      <Grid style={{ marginTop: "50px", }}>
        <Column lg={16} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
          <p style={{ height: "1px", backgroundColor: "#e8e8e8", width: "100%" }}></p>
        </Column>
      </Grid>

      {/* <Grid style={{ marginTop: "30px" }}>
        <Column lg={4} md={8} sm={4}>
          <p style={{ fontSize: "30px" }}>Press Tools</p>
        </Column>
        <Column lg={9} md={8} sm={4} className='pre_tools_section' >
          <div style={{ textAlign: "center" }}>
            <BeeBat size={'80'} />
            <Link style={{ marginTop: "20px", fontSize: "15px", display: "block", fontWeight: 600, color: "#0062fe" }}>About</Link>
          </div>
          <div style={{ textAlign: "center" }}>
            <IbmProcessMining size={'80'} />
            <Link style={{ marginTop: "20px", fontSize: "15px", display: "block", fontWeight: 600, color: "#0062fe" }}>Leadership</Link>
          </div>
          <div style={{ textAlign: "center" }}>
            <CloudRegistry size={'80'} />
            <Link style={{ marginTop: "20px", fontSize: "15px", display: "block", fontWeight: 600, color: "#0062fe" }}>Media Contacts</Link>
          </div>
        </Column>
      </Grid> */}

      {/* <Grid style={{ marginTop: "60px" }}>
        <Column lg={16} md={8} sm={4} style={{ margin: "0px", padding: "0px" }}>
          <p style={{ height: "1px", backgroundColor: "#e8e8e8", width: "100%" }}></p>
        </Column>
      </Grid>
      <Grid style={{ marginTop: "60px", marginBottom: "120px" }}>
        <Column lg={4} md={8} sm={4}>
          <p style={{ fontSize: "30px" }}>More News</p>
        </Column>
        <Column lg={12} md={8} sm={4}>
          <Grid>
            {
              moreNews?.map((item) => {
                return (
                  <Column lg={4} md={4} sm={4} style={{ marginTop: "20px" }}>
                    <ClickableTile id="clickable-tile-1" className="overview-grid featured_card">
                      <div className='featured_card_img'> <img src={item?.image} /></div>
                      <div className='featured_card_con'>
                        <Link style={{ fontSize: "20px", marginTop: "0px", height: "130px", color: "#161616" }}>{item?.lable}</Link>
                        <div>
                          <Link size='lg' href="#" style={{ marginTop: "20px", textDecoration: "none" }} renderIcon={() => <ArrowRight size={'30'} />} />
                        </div>
                      </div>
                    </ClickableTile>
                  </Column>
                )
              })
            }
          </Grid>
        </Column>
      </Grid > */}
    </div>
  )
}
export default Insights