import React from "react";
import {
  Content,
  Button,
  Grid,
  Column,
  ClickableTile,
} from "carbon-components-react";
import { ArrowRight } from "@carbon/icons-react";
import "./style.scss";

const content = {
  heding : "See what's possible with an IBM account",
  log_in_to_My_IBM:"Log in to My IBM",
  manage_and_support :"Manage and Support"
}
const data = [{
  "label": "Manage profile",
  "description": "Maintain profile information such as phone number, address, contact email, password, or payment method.",
},
{
  "label": "Manage products",
  "description": "Manage your trials, subscriptions, software and hardware purchases from My IBM Dashboard",
},
{
  "label": "Get support",
  "description": "Find technical documentation, downloads or open a problem ticket",
}]

const Account = () => {
  return (
    <Content className="possible_con">
      <Content className="possible_banner">
        <div className="inner_con">
          <h1 className="text-sans" dir="auto" style={{ fontWeight: 300 }}>
            {content.heding}
          </h1>
          <Button className="possible_login_ibm_btn" renderIcon={(props) => (<ArrowRight {...props} style={{ width: "25px", height: "22px" }} />)} iconDescription="Add">
            {content.log_in_to_My_IBM}
          </Button>
        </div>
      </Content>
      <Content className="possible_card">
        <div className="possible_card_main">
          <Grid fullWidth className="acoount_componet">
            <Column lg={4} md={8} sm={4} className="produc-grid">
              <p className="text-sans" dir="auto" style={{ fontWeight: 600, marginBottom: "25px" }}>{content.manage_and_support}</p>
            </Column>
            {data.map((ele) => {
              return (
                <Column lg={4} md={4} sm={4} className="produc-grid" style={{ margin: 0 }}>
                  <ClickableTile className="possibal_card">
                    <p>{ele.label}</p>
                    <span dir="auto">{ele.description}</span>
                    <ArrowRight size={20} style={{ color: "0e62fe", marginTop: "10px" }} />
                  </ClickableTile>
                </Column>
              )
            })}
          </Grid>
        </div>
      </Content>
    </Content>
  );
};

export default Account;
