import { Content, Grid, Column, Link, Accordion, AccordionItem, Button } from 'carbon-components-react';
import { Image } from '../../Image';
import { LogoLinkedin, LogoTwitter, Add, Subtract } from '@carbon/icons-react';
import "./style.scss"
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


// const profileNews = [
//   { title: "Forbes: What is Marketing?" },
//   { title: " Edge:  “Our innovations are guided by our foundational principles of trust and ethics,” says IBM exec" },
//   { title: "The Relevance Report 2024: Welcome to AI" },
// ]

const mainGrid ={
  name : "Anasooya T",
  role:"Strategic Communication Specialist",
  nameRole : "Anasooya is a Strategic Communication Specialist at Proximety",
  description1:"Meet Anasooya, the Strategic Communication Specialist of Proximety, whose unparalleled talent for content writing and communication fuels the company's growth while embracing her passion for innovation. As a writer she crafts engaging narratives that communicates her ideas to her readers. As she honed through this skill more, Anasooya realized the immense power of effective communication in driving success.",
  description2:"At Proximety, her exceptional content writing skills take center stage. With an innate ability to transform complex concepts into compelling pieces, she effortlessly communicates the company's vision, products, and services to both clients and stakeholders. Anasooya’s words are woven intricately, leaving a lasting impact that deepens Proximety's presence in the industry.",
  description3:"What truly defines Anasooya is her driving passion to elevate Proximety through innovative strategies. Recognizing the endless possibilities technology offers, she fearlessly explores new frontiers, seamlessly infusing cutting-edge ideas into the company's communication frameworks. Her dedication to staying ahead of the curve ensures Proximety remains a trailblazer in the digital landscape.",
  description4:"Her impact is undeniable, as she provides Proximety with the voice it needs to thrive. Her dedication to her craft, coupled with her unwavering passion for innovation, ensures that Proximety will continue to set the standard for strategic communication in the industry.",
  // description5:"A graduate of  of TKM College of Engineering- Kollam, Gautham earned a Bachelor of Technology degree in Electrical & Electronics Engineering.",
  // description6:"Jonathan is an avid music and Wisconsin sports fan. He lives in Westchester County, NY and in his personal time, you can find him spending time with his wife, their two kids and dog."

}

const Anasooya = () => {

  const navigate = useNavigate()
  const handleArrowRightClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };
  const [isShow, setIsShow] = useState(false)

  return (
    <Content className='profile_main'>
      <Grid>
        <Column lg={4} md={4} sm={4}>
          <div className='bio_img'>
            <img src={Image?.anasooya} />
          </div>
        </Column>
        <Column lg={12} md={8} sm={4} >
          <p style={{ fontSize: "40px", color: "#161616", fontWeight: 300 }}>{mainGrid.name}</p>
          <p style={{ padding: "18px 0px", color: "#161616", fontSize: "24px", fontWeight: 300 }}>{mainGrid.role}</p>
          <div style={{ paddingBottom: "15px" }}>
          <LogoLinkedin style={{ cursor: "pointer" }} size={'40'} onClick={() => window.open("https://www.linkedin.com/in/anasooya-thorakkattu-49812a209", "_blanck")} />           
            {/* <LogoTwitter size={'40'} style={{ marginLeft: "10px" }} /> */}
          </div>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.nameRole}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description1}</p>
          <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description2}</p>

          {isShow ?
            <div>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description3}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description4}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description5}</p>
              <p style={{ fontSize: "16px", color: "#161616", paddingBottom: "15px" }}>{mainGrid.description6}</p>
            </div>
            : null}
          <Link onClick={() => setIsShow(!isShow)} style={{ color: "#0f62fe", fontWeight: 700, fontSize: "18px", cursor: "pointer" }} renderIcon={() => isShow ? <Subtract size={'25'} /> : <Add size={'25'} />}>{isShow ? "Read less" : "Read more"}</Link>
          {/* <Accordion style={{ marginTop: "30px" }} size='lg' className='accordion_main'>
            <AccordionItem title="News" open
            >
              <Grid >
                {
                  profileNews?.map((u, index) => {
                    return (
                      <Column key={index} lg={4} md={8} sm={4} className='accordion_column'>
                        <Link className='bio_accordion_con'>
                          {u?.title}
                        </Link>
                      </Column>
                    )
                  })
                }
              </Grid>
            </AccordionItem>
          </Accordion> */}
          <Grid style={{ marginTop: "30px" }}>
            <Column lg={12} md={8} sm={4}>
              <Button onClick={() => { navigate("/our-team"); handleArrowRightClick(); }} style={{ marginBottom: "48px" }}>
                Back to Leadership
              </Button>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Content>
  )
}

export default Anasooya