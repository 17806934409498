import React, { useState } from 'react';
import { Content, Grid, Column, Button, GridSettings, Heading, Section } from '@carbon/react';
import { Image } from '../../../Image';
import { Printer, Email, Wifi, DocumentPdf } from '@carbon/icons-react';
import "./style.scss";

const Blog2 = () => {
  const content = {
    section: {
      level: 1,
      heading: "The Transforming Landscape of Malls: Navigating Retail Trends in 2024",
      // date: "Current Date",
      bannerImage: Image?.featured_banner2,
    },
    mainGrid: {
      // author: "Author Name",
      paragraphs1: "In the 1980s, malls were the epicenter of US retail, commanding over half of all sales. These colossal complexes embodied consumerism, serving as primary shopping destinations for urban and suburban communities. However, a paradigm shift is underway, as malls grapple with challenges stemming from the pandemic and the surge in online shopping.",
      paragraphs2: "To thrive in the coming decade, malls must undergo a substantial metamorphosis. Retailers are acknowledging the necessity of meeting customers on multiple fronts, delivering a seamless omnichannel experience that seamlessly integrates online and in-store inventory. The future of malls is looming, and it promises a departure from the conventional model.",
    },
    currentState: {
      heading: "Current State of Shopping Malls",
      paragraphs: [
        "Contrary to the belief that malls are fading into obsolescence, recent research, such as Glossy and Modern Retail's 'The Great Mall Overhaul,' paints a different picture. Malls are reimagining themselves to cater to post-pandemic consumers, evolving into lifestyle centers that seamlessly blend shopping and entertainment. Outlet malls are undergoing a transformation into destination centers, attracting digitally native brands and elevating the overall customer experience.",
        "Mall kiosks are becoming gateways for international expansion, while pop-ups emerge as powerful tools for young retail brands to gain visibility and excitement. Moreover, malls are embracing the digital age, with some shifting away from traditional shopping to explore avenues like retail media, livestreaming, and other digital shopping experiences.",
      ],
    },
    futureOutlook: {
      heading: "What Could the Future of Shopping Malls Look Like?",
      paragraphs: [
        "Mall owners and retailers are actively seeking ways to capitalize on this resurgence and reimagine the future of shopping malls. Key elements shaping this future include:",
        "Prioritization of Convenience: Appointment-based shopping is gaining traction, offering personalized experiences that align with evolving customer expectations. Examples like Neiman Marcus showcase how in-mall department stores can provide one-on-one appointments, curbside pickup, and virtual consultations.",
        "Local Pickup: Curbside pickup has proven successful for boosting online sales within malls. Offering convenience and safety, this approach has led to increased order completion rates and higher spending by customers.",
        "Contactless Payments: With 80% of US consumers already using contactless payments, this trend is set to grow. The speed and reduced risk associated with contactless payments have made them a preferred choice for shoppers.",
        "Redefined Store Formats: To optimize retail space, stores within malls are exploring smaller, experience-driven formats, including pop-up shops and micro-retailing. Brands like LIVELY have successfully integrated brick-and-mortar stores into their growth strategy.",
        "Greater Use of Technology: Virtual shopping, endless aisles, and augmented reality (AR) and virtual reality (VR) experiences are enhancing customer engagement both online and in-store. The concept of an 'endless aisle' helps retailers merge online offerings with their physical locations to create the best shopping experience.",
        "Unification of In-Store and Online Merchandise: The future of retail is omnichannel, emphasizing a seamless and channel-agnostic shopping experience. Mall stores must adopt unified systems for inventory management, enabling personalized shopping experiences, integrated loyalty programs, and frictionless returns.",
        "Personalized Shopping Experiences: Customers now seek a personalized approach that caters to their preferences. Mall sales associates are evolving into personal shopping assistants, collecting valuable data through free WiFi, CRM systems, geofencing, and apps to deliver a more personalized experience.",
      ],
    },
    futureOfMalls: {
      heading: "The Future of Malls: What's Next?",
      paragraph: "As the retail landscape continues to evolve, malls will undoubtedly maintain their place in the ecosystem. However, the future mall experience will focus on maximizing the value of each square foot by delivering exceptional experiences, with an emphasis on the concept of an 'endless aisle.' Retailers within malls must adopt an omnichannel approach to meet the rising expectations of customers who desire a seamless shopping journey from online to in-person interactions.",
    },
  };

  const [isSideNavExpanded, setSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <>
      <Content className='get_ready_cases'>
        <Section level={1}>
          <Heading style={{ fontWeight: 300, fontSize: "36px" }}>
            {content.section.heading}
          </Heading>
          <p style={{ marginTop: 18 }}>{content.section.date}</p>
          <img src={content.section.bannerImage} style={{ height: "100%", width: "100%", marginTop: 20 }} />
        </Section>
        <Grid fullWidth className='main_grid' >
          <Column lg={11} md={8} sm={4} className="first_grid">
            <GridSettings subgrid={false}>
              <Grid>
                <Column lg={1} md={1} sm={4} className="first_grid  first_a_grid">
                  {/* <div style={{ width: "30px", height: "30px", background: "#4267B2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fin.newsroom.ibm.com%252Findex.php%253Fs%253D34137%2526item%253D101&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#0077B5", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fin.newsroom.ibm.com%2Findex.php%3Fs%3D34137%26item%3D101", "_blank")}
                  ></div>
                  <div style={{ width: "30px", height: "30px", background: "#1DA1F2", borderRadius: "3px", marginBottom: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://twitter.com/intent/tweet?url=https%3A%2F%2Fin.newsroom.ibm.com%2Findex.php%3Fs%3D34137%26item%3D1011", "_blank")}></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => window.open("https://in.newsroom.ibm.com/getting-ready-for-new-era-of-data-protection?adoper=219449_2_LS2&printable", "_blank")}
                  ><Printer size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><Email size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}
                    onClick={() => (window.open("https://in.newsroom.ibm.com/index.php?s=20324&rsspage=34137", "_blank"))}
                  ><Wifi size={22} /></div>
                  <div style={{ width: "30px", height: "30px", marginBottom: "8px", padding: "2px", cursor: "pointer" }}><DocumentPdf size={22} /></div> */}
                </Column>
                <Column lg={10} md={7} sm={4} className="first_grid first_b_grid">
                  <span style={{ fontSize: "16px", fontWeight: "600", fontStyle: "italic" }}>{content.mainGrid.author}</span>
                  <p style={{ marginTop: "20px" }}>
                    {content.mainGrid.paragraphs1}
                  </p>
                  <p>
                    {content.mainGrid.paragraphs2}
                  </p>
                  {/* Current State of Shopping Malls */}
                  <h2>{content.currentState.heading}</h2>
                  {content.currentState.paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}

                  {/* What Could the Future of Shopping Malls Look Like? */}
                  <h2>{content.futureOutlook.heading}</h2>
                  {content.futureOutlook.paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}

                  {/* The Future of Malls: What's Next? */}
                  <h2>{content.futureOfMalls.heading}</h2>
                  <p>{content.futureOfMalls.paragraph}</p>
                </Column>
              </Grid>
            </GridSettings>
          </Column>
          <Column lg={5} md={4} sm={4} className="second_grid_one">
            {/* <Button kind="tertiary"
              onClick={() => window.open("https://in.newsroom.ibm.com/subscribe", "_blank")}
            >
              Subscribe to email
            </Button> */}
          </Column>
        </Grid>
      </Content>
    </>
  );
}

export default Blog2;
